import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const ptLang: LangPack = {
    lang: Lang.PT,
    langName: "Portuguese",
    langShort: "pt",
    pack: {
        common: {
            noBTN: "Não",
            yesBTN: "Sim",
            errorCodeTitle: "Código de erro",
            pageSizeSwitcherLabel: "Linhas por página",
            ageConfirmationMessage: "Confirma que tem 18 anos?",
            iConfirmBTN: "Confirmo",
            welcome: "Bem-vindo",
            userProfile: "Perfil do usuário",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Tem certeza de que deseja sair?",
            logOut: "Sair",
            depositMoney: "Depositar dinheiro",
            gamesHistory: "Histórico de jogos",
            transactions: "Transações",
            paymentAccounts: "Contas de pagamento",
            userInfo: "Informações do usuário",
            withDrawMoney: "Retirar dinheiro",
        },
        mobileMenu: {
            profile: "Perfil",
            topUpBalance: "Saldo de recarga",
            logout: "Sair",
        },
        dashboard: {
            title: "Painel de Controle",
            youMustBeLoggedMessage: "Você precisa estar logado para ver esta seção!",
        },
        internalErrorMessages: {
            networkError: "Ocorreu um erro de rede ao executar a solicitação.",
            error502: "Ocorreu um erro de servidor 502 ao executar a solicitação.",
        },
        errorMessages: {
            2: "Requisição vazia",
            3: "Endereço de e-mail já existente",
            4: "Número de telefone já existente",
            5: "Usuário já está logado",
            6: "Senha incorreta",
            7: "Resultado de jogos vazio",
            8: "ID do resultado não corresponde à ID da solicitação",
            9: "Erro na solicitação de resultado",
            10: "ID de sessão ausente na resposta",
            11: "Usuário já existente",
            12: "Seu token não é válido. Faça login novamente.",
            13: "Você não tem direitos suficientes para realizar esta ação",
            14: "Falta a ID da criança para esta ação",
            15: "Falta a quantia de dinheiro",
            16: "Ação não existe",
            17: "Falta o token",
            18: "Dinheiro insuficiente",
            19: "Você deve ser o pai deste usuário para realizar esta ação",
            20: "Usuário não existe",
            21: "Tipo de valor errado para este campo",
            22: "Falta a senha",
            23: "Falta o nome de usuário",
            24: "Falta a ID do jogo",
            25: "Falta a nova senha",
            26: "Não foi possível configurar o token",
            27: "Não foi possível excluir o token",
            28: "Falta a ID do usuário",
            29: "Formato de data e hora errado",
            30: "Não foi possível atualizar a proibição do usuário",
            31: "Este usuário deve estar na sua árvore para realizar esta ação",
            32: "Este usuário está proibido e não pode realizar nenhuma ação",
            33: "Moeda ausente",
            34: "Valor máximo ausente",
            35: "O tipo de limite deve ser único, diário, semanal ou mensal",
            36: "Tipo de limite ausente",
            37: "O provedor não existe",
            38: "Quantidade de condição de nível ausente",
            39: "Tipo de condição de nível ausente",
            40: "Título de nível ausente",
            41: "Cashback para este nível já existe",
            42: "Não existe cashback para este nível",
            43: "O bônus para este código promocional já existe",
            44: "O bônus não existe",
            45: "Código promocional ausente",
            46: "Hora de início ausente",
            47: "Quantidade de condição de depósito ausente",
            48: "Aposta ausente",
            49: "Dias de validade ausentes",
            50: "ID ausente",
            51: "Tipo de cashback ausente, deve ser 'aposta' ou 'depósito'",
            52: "Taxa de cashback ausente",
            53: "Esta moeda não está disponível",
            54: "Parâmetro de proibição ausente - verdadeiro ou falso",
            55: "O grupo bancário para esta moeda não é válido",
            56: "Nome do método de pagamento ausente",
            57: "Parâmetros obrigatórios para este método de pagamento ausentes",
            58: "Este método de pagamento não existe",
            59: "Este método de pagamento não suporta a moeda deste usuário",
            60: "Este usuário não possui uma conta existente para este sistema de pagamento",
            61: "O pai deste usuário não possui uma conta existente para este sistema de pagamento",
            62: "ID de transferência ausente",
            63: "Nenhuma transferência com esta ID",
            64: "Esta transferência é solicitada por um pai diferente",
            65: "Você ainda tem um bônus que não expirou",
        },
        loginForm: {
            usernameFieldLabel: "Nome de usuário",
            passwordFieldLabel: "Senha",
            loginBTN: "Entrar",
            authorization: "Autorização",
        },
        searchGameForm: {
            searchGame: "Pesquisar jogos",
            notFound:
                "Não encontramos nenhum resultado correspondente à sua solicitação, tente algo diferente.",
            results: "Resultados:",
            seeAll: "Ver tudo",
            searchResults: "Resultados da pesquisa",
        },
        userInfoForm: {
            emailIsRequiredMess: "E-mail obrigatório",
            notValidEmail: "Digite um endereço de e-mail válido",
            emailFieldLabel: "E-mail",
            phoneFieldLabel: "Telefone",
            firstnameFieldLabel: "Nome",
            lastnameFieldLabel: "Sobrenome",
            saveInfoBTN: "Salvar informações",
            currentPassFieldLabel: "Senha atual",
            newPassFieldLabel: "Nova senha",
            confirmPassFieldLabel: "Confirmar senha",
            updatePassBTN: "Atualizar senha",
            emptyFieldWarningMessage: "Este campo não deve estar vazio",
            passNotMatchWarningMessage: "Nova senha e senha de confirmação não correspondem",
            usernameLabel: "Usuario",
            password: "Senha",
            balance: "Saldo",
        },
        transactionsTable: {
            amountTableHeader: "Quantidade",
            methodTableHeader: "Método",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Depósito/Retirada",
            dateTimeTableHeader: "Data/Hora",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Fornecedor",
            gameTableHeader: "Jogo",
            betAmountTableHeader: "Valor da aposta",
            winAmountTableHeader: "Valor ganho",
            dateTimeTableHeader: "Data/Hora",
            userTableHeader: "Usuário",
            multiplierTableHeader: "Multiplicador",
            payoutTableHeader: "Pagamento",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Não há mais métodos de pagamento disponíveis",
            paymentMethodsDropdownLabel: "Métodos de pagamento",
            addBTN: "Adicionar",
            yourPaymentAccountsTitle: "Suas contas de pagamento",
            addPaymentMethodBTN: "Adicionar método de pagamento",
        },
        moneyInput: {
            maxAmountMessage: "Valor máximo",
            exceedAmountError: "Valor máximo excedido.",
            moreThanOneDotError: "Você inseriu um ponto a mais.",
            moreThanTwoDecimals: "Você inseriu mais de dois decimais.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirar do usuário",
            moneySuccessfulRequestedMessage: "Você solicitou %money% para pagamento.",
            requestBtn: "Solicitar dinheiro",
        },
        topWinModal: {
            aboutBet: "Sobre a aposta",
            dateLabel: "Data",
            betIdLable: "ID da aposta",
            betAmount: "Valor da aposta",
            multiplier: "Multiplicador",
            payout: "Pagamento",
            play: "Jogar",
        },
        argentina: {
            common: {
                linkToAllTitle: "Todos",
                topGames: "Melhores jogos",
                topLiveCasino: "Melhor Live Casino",
                topProviders: "Melhores provedores",
                topCrashGames: "Melhores jogos de crash",
                topTableGames: "Melhores jogos de mesa",
                liveFeed: "Transmissão ao vivo",
                seeAllBTN: "Mostrar tudo",
                returnBackBTN: "Voltar",
                playGame: "Jogar",
            },
            termsAndConditions: {
                content: [
                    "Termos e Condições",
                    "Bem-vindo ao Salta La Blanca, o seu destino confiável de cassino online. Ao acessar o nosso site ou utilizar qualquer um dos nossos serviços, você reconhece e concorda em estar vinculado a estes Termos e Condições. Por favor, reserve um momento para lê-los atentamente, pois eles descrevem os direitos e obrigações de ambas as partes para garantir uma experiência de jogo justa e transparente.",
                    "Aceitação dos Termos",
                    "Ao registrar uma conta ou utilizar qualquer funcionalidade da nossa plataforma, você confirma que leu, compreendeu e concordou com estes Termos e Condições. Se você não concordar com qualquer uma das disposições aqui descritas, pedimos que se abstenha de utilizar os nossos serviços. O uso contínuo da plataforma constitui a confirmação da sua aceitação.",
                    "Elegibilidade para Utilização",
                    "O acesso ao Salta La Blanca é estritamente limitado a indivíduos que atendam aos requisitos de idade legal para apostas na sua jurisdição, geralmente 18 anos ou mais. É de sua exclusiva responsabilidade determinar se a participação em jogos de azar online é permitida pelas leis do seu país ou região. Reservamo-nos o direito de solicitar a verificação de idade a qualquer momento e podemos suspender contas caso os requisitos de elegibilidade não sejam atendidos.",
                    "Conta do Usuário",
                    "Para acessar os nossos jogos e serviços, você deve criar uma conta fornecendo informações precisas e atualizadas, incluindo o seu nome, endereço de e-mail e outros detalhes relevantes. Você é responsável por manter a confidencialidade das credenciais da sua conta e garantir que pessoas não autorizadas não tenham acesso. Caso suspeite de qualquer atividade não autorizada, você deve nos notificar imediatamente para evitar uso indevido.",
                    "Depósitos e Saques",
                    "Os depósitos na sua conta de jogo devem ser feitos utilizando métodos de pagamento oficialmente aprovados pelo Salta La Blanca. Todas as transações são processadas de forma segura, e tomamos as precauções necessárias para proteger as suas informações financeiras. Os saques podem estar sujeitos a procedimentos de verificação de identidade para cumprir com os requisitos legais e regulatórios. Observe que os prazos de processamento de saques podem variar dependendo do método de pagamento escolhido.",
                    "Bônus e Promoções",
                    "Oferecemos uma variedade de bônus e promoções emocionantes para aprimorar a sua experiência de jogo. Cada bônus está sujeito a termos e condições específicos, como requisitos de apostas e períodos de validade, que serão claramente detalhados nas informações da promoção. O uso indevido ou abuso de ofertas promocionais, incluindo tentativas de burlar as regras, pode resultar na perda dos bônus e dos ganhos associados.",
                    "Jogo Responsável",
                    "No Salta La Blanca, estamos comprometidos em promover o jogo responsável e garantir que a nossa plataforma seja um ambiente seguro para todos os usuários. Fornecemos ferramentas para ajudá-lo a gerenciar a sua atividade de jogo, como definir limites de depósito, limites de apostas e opções de autoexclusão. Se você achar que os seus hábitos de jogo estão se tornando problemáticos, recomendamos que busque ajuda por meio de recursos disponíveis, como [Suporte ao Jogo Responsável].",
                    "Atividades Proibidas",
                    "Para manter um ambiente de jogo justo e seguro, quaisquer práticas fraudulentas, manipuladoras ou desleais são estritamente proibidas. Isso inclui, mas não se limita a, o uso de bots automatizados, conluio com outros jogadores ou qualquer tentativa de explorar vulnerabilidades do sistema. Envolver-se em tais atividades resultará na suspensão ou encerramento imediato da sua conta e na perda de quaisquer ganhos.",
                    "Propriedade Intelectual",
                    "Todo o conteúdo na plataforma do Salta La Blanca, incluindo, mas não se limitando a, logotipos, designs, textos, softwares e multimídia, é de propriedade exclusiva da nossa empresa. Esses materiais são protegidos por leis internacionais de direitos autorais e propriedade intelectual. Qualquer uso, reprodução ou distribuição não autorizada do nosso conteúdo é estritamente proibida e pode resultar em ações legais.",
                    "Limitação de Responsabilidade",
                    "Embora nos esforcemos para proporcionar uma experiência de jogo contínua e agradável, o Salta La Blanca não garante que a nossa plataforma estará livre de erros, interrupções ou problemas técnicos. Não seremos responsáveis por quaisquer perdas diretas, indiretas ou incidentais decorrentes do uso da plataforma, incluindo perdas resultantes de transações mal sucedidas, falhas técnicas ou acesso não autorizado à sua conta.",
                    "Alterações",
                    "O Salta La Blanca reserva-se o direito de alterar estes Termos e Condições a qualquer momento para refletir mudanças nos requisitos legais, práticas comerciais ou feedback dos usuários. As atualizações serão publicadas no nosso site, e incentivamos você a revisar estes Termos periodicamente. O uso contínuo da plataforma após as alterações constitui a sua aceitação dos Termos revisados.",
                    "Lei Aplicável e Disputas",
                    "Estes Termos e Condições são regidos pelas leis de [indicar jurisdição]. Qualquer disputa decorrente do uso dos nossos serviços será resolvida de acordo com essas leis. Em caso de desacordo, recomendamos que os usuários entrem em contato com a nossa equipe de suporte para buscar uma resolução amigável antes de recorrer a ações legais.",
                    "Contate-Nos",
                ],
                email: `Para quaisquer dúvidas, preocupações ou feedback sobre estes Termos e Condições, não hesite em entrar em contato com a nossa equipe de suporte dedicada em <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a>. Estamos aqui para ajudá-lo e garantir que a sua experiência na nossa plataforma seja agradável e segura.`,
            },
            aboutUs: {
                content: [
                    "Sobre Nós",
                    "Bem-vindo ao Salta La Blanca, seu principal destino online para uma experiência de jogo emocionante e diversificada. Seja você um jogador experiente ou novo no mundo dos jogos online, nossa plataforma foi projetada para oferecer a combinação perfeita de emoção, variedade e entretenimento de qualidade.",
                    "O Que Oferecemos",
                    "No Salta La Blanca, nos orgulhamos de oferecer uma ampla gama de opções de jogos que atendem às preferências de cada jogador. Aqui está o que você pode desfrutar em nossa plataforma:",
                    "Clássicos do Cassino: Reviva o charme atemporal dos jogos tradicionais de cassino, como blackjack, roleta e bacará. Com melhorias modernas e designs elegantes, esses clássicos continuam tão emocionantes quanto sempre, oferecendo estratégia e sorte para uma experiência envolvente.",
                    "Jogos de Crash: Procurando por uma dose rápida de adrenalina? Nossos jogos de crash são perfeitos para jogadores que adoram uma jogabilidade cheia de ação, onde cada segundo conta. Teste seus reflexos e intuição para ganhar grande nesta categoria única e emocionante de jogos.",
                    "Cassino ao Vivo: Mergulhe na atmosfera autêntica do cassino sem sair de casa. Com dealers reais transmitindo em alta definição, nosso cassino ao vivo traz a emoção dos cassinos físicos diretamente para sua tela. Interaja com os dealers e outros jogadores para uma experiência verdadeiramente social e envolvente.",
                    "Apostas Esportivas: Para os entusiastas de esportes, oferecemos uma seção completa de apostas esportivas, onde você pode apostar em seus times, partidas e eventos favoritos. Com odds competitivas e uma variedade de esportes para escolher, nunca foi tão fácil transformar sua paixão por esportes em apostas emocionantes.",
                    "Jogos de Mesa: Adora jogos de estratégia e habilidade? Explore uma ampla seleção de jogos de mesa que desafiam sua mente enquanto oferecem entretenimento infinito. Do pôquer ao craps, temos algo para todos os entusiastas de jogos de mesa.",
                    "Uma Ampla Gama de Provedores: Para garantir a mais alta qualidade, nos associamos aos melhores provedores de jogos de cassino da indústria. Isso significa que você terá acesso a uma biblioteca impressionante de jogos com visuais deslumbrantes, recursos inovadores e desempenho confiável.",
                    "Por Que Nos Escolher?",
                    "No Salta La Blanca, vamos além de apenas oferecer jogos — buscamos criar um ambiente de jogo que seja divertido, seguro e focado no jogador. Aqui está o que nos destaca:",
                    "Inovação: Atualizamos constantemente nossa plataforma com as últimas tendências e tecnologias para oferecer uma experiência de jogo de ponta. Desde gráficos de alta qualidade até mecânicas de jogo únicas, estamos comprometidos em manter as coisas frescas e emocionantes para nossos jogadores.",
                    "Variedade: Entendemos que cada jogador é único, por isso oferecemos uma ampla gama de jogos e opções de apostas. Não importa suas preferências, você encontrará algo que se adapte ao seu estilo e o mantenha engajado.",
                    "Jogo Justo: A integridade está no centro do que fazemos. Nossos jogos são baseados em tecnologia confiável de Gerador de Números Aleatórios (RNG), garantindo que todos os resultados sejam justos e imparciais. Nossas parcerias com provedores renomados garantem ainda mais transparência e confiabilidade.",
                    "Abordagem Centrada no Jogador: Sua satisfação é nossa prioridade. Projetamos nossa plataforma para ser fácil de usar e intuitiva, com navegação suave e recursos que tornam o jogo agradável e sem complicações. Além disso, nossa equipe de suporte ao cliente está sempre pronta para ajudá-lo com qualquer dúvida ou preocupação.",
                    "Junte-se ao Salta La Blanca, onde cada giro, rolagem e aposta cria oportunidades para momentos inesquecíveis. Pronto para entrar na ação? Vamos tornar cada jogo uma experiência vencedora!",
                    "Suporte ao Vivo",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Torne-se um Agente",
                    "Você é apaixonado por jogos online e está procurando uma oportunidade para transformar essa paixão em uma parceria lucrativa? Junte-se ao Programa de Agentes da Salta La Blanca e torne-se uma parte vital da nossa comunidade em crescimento. Esta é a sua chance de ganhar, conectar e prosperar no dinâmico mundo do entretenimento online enquanto promove uma plataforma que você pode realmente apoiar.",
                    "Por que se associar à Salta La Blanca?",
                    "Quando você se junta ao nosso Programa de Agentes, você não está apenas se inscrevendo — está se unindo a uma rede que valoriza suas contribuições e recompensa seus esforços. Aqui está o que nos diferencia:",
                    "Alto Potencial de Ganhos: Oferecemos uma das estruturas de comissão mais competitivas do setor, dando a você a oportunidade de maximizar sua renda com base na atividade e lealdade dos jogadores que você traz para nossa plataforma.",
                    "Ferramentas de Marketing Avançadas: Obtenha acesso a um conjunto de ferramentas profissionais, incluindo links de rastreamento personalizados, análises detalhadas de desempenho e uma variedade de materiais promocionais de alta qualidade projetados para ajudá-lo a ter sucesso.",
                    "Suporte Completo: Nossa equipe de afiliados dedicada está aqui para apoiá-lo em cada etapa. Seja para dúvidas técnicas ou conselhos de marketing, estamos a apenas uma mensagem de distância.",
                    "Portfólio Diversificado de Jogos: Promova uma plataforma que oferece algo para todos, desde clássicos de cassino até jogos com dealers ao vivo, jogos de crash, apostas esportivas e muito mais — tudo fornecido pelos principais provedores de jogos.",
                    "O que um Agente faz?",
                    "Como agente da Salta La Blanca, seu papel é espalhar a palavra sobre nossa plataforma e atrair novos jogadores. Você usará sua rede, criatividade e habilidades de marketing para expandir seu público, tudo enquanto ganha recompensas substanciais. É o ajuste perfeito, seja você um profissional de marketing experiente, um criador de conteúdo ou simplesmente alguém com uma forte paixão por jogos.",
                    "Como o Programa Funciona?",
                    "Inscreva-se: Conclua um processo de registro rápido e fácil para se tornar um agente oficial.",
                    "Obtenha suas Ferramentas: Acesse seu painel personalizado com links de rastreamento, banners promocionais e outros recursos de marketing.",
                    "Promova Nossa Plataforma: Use suas ferramentas para compartilhar a Salta La Blanca com jogadores potenciais por meio de sites, redes sociais, fóruns ou qualquer plataforma que funcione para você.",
                    "Ganhe Comissões: Para cada jogador que você indicar, você ganhará comissões com base na atividade deles, garantindo um fluxo constante de renda.",
                    "Quem pode se juntar?",
                    "Nosso programa está aberto a todos que desejam ter sucesso e têm uma conexão com o mundo dos jogos. Seja você um influenciador com um grande público, um entusiasta das redes sociais ou alguém com uma rede de amigos que adoram jogos, fornecemos tudo o que você precisa para transformar seus esforços em resultados tangíveis.",
                    "Seu Sucesso é o Nosso Sucesso",
                    "Na Salta La Blanca, acreditamos em construir relacionamentos fortes e mutuamente benéficos com nossos agentes. É por isso que investimos no seu sucesso, oferecendo sistemas de pagamento transparentes, suporte contínuo e uma plataforma que os jogadores adoram. Quando você tem sucesso, nós também temos.",
                    "Pronto para se juntar?",
                    "Tornar-se um agente da Salta La Blanca é simples e gratificante. Dê o primeiro passo em direção a um futuro mais emocionante inscrevendo-se hoje. Clique em [Torne-se um Agente] e comece a ganhar enquanto faz parte de um dos nomes mais confiáveis em jogos online.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Início",
                    lobby: "Recepção",
                    casino: "Cassino",
                    sport: "Esportes",
                    liveBetting: "Apostas ao Vivo",
                    liveCasino: "Cassino ao Vivo",
                    crashGames: "Jogos de Crash",
                    tableGames: "Jogos de Mesa",
                    legends: "Lendas",
                    becomeAnAgent: "Torne-se um Agente",
                    privacyPolicy: "Política de Privacidade",
                    responsibleGaming: "Jogo Responsável",
                    termsAndConditions: "Termos e Condições",
                    aboutUs: "Sobre Nós",
                    userInfo: "Informações do usuário",
                    gameHistory: "Histórico de jogos",
                    transactions: "Transações",
                },
                loggedInUserMenu: {
                    userInfo: "Informações do usuário",
                    userTransactions: "Transações",
                    userGameHistory: "Histórico de jogos",
                    userLogOut: "Sair",
                },
                userInfo: {
                    userInfoSection: "Informações do usuário",
                    changePasswordSection: "Alterar senha",
                },
            },
            carousel: {
                slide1: {
                    header: "Domine o Totem<br/> e Conquiste a Vitória",
                    tagline: "Role e Vença com Galaxsys!",
                },
                slide2: {
                    header: "Descubra a Emoção<br/>apenas em Salta la Banca",
                    tagline: "Junte-se ao jogo hoje!",
                },
                slide3: {
                    header: "Imagine Mais, Ganhe Mais<br/>no Imagine Live",
                    tagline: "As apostas são reais",
                },
                slide4: {
                    header: "Sinta a emoção do jogo!<br/>Viva o momento da vitória!",
                    tagline: "Junte-se agora!",
                },
                slide5: {
                    header: "Ezugi está chegando",
                    tagline: "No final desta semana!",
                },
            },
            menuLinkTitles: {
                home: "Início",
                casino: "Cassino",
                liveCasino: "Cassino ao vivo",
                virtualGames: "Jogos virtuais",
            },
            sideBarSections: {
                games: "Jogos",
                topCrashGames: "Jogos Top Crash",
                other: "Outro",
                sportsRules: "Regras de esportes",
            },
            headerMenu: {
                home: "Início",
                casino: "Cassino",
                slots: "Caça-níqueis",
                shooters: "Jogos de Tiro",
                tableGames: "Jogos de Mesa",
                liveCasino: "Cassino ao Vivo",
                crash: "Jogos de Crash",
                sport: "Esportes",
                menu: "Menu",
                lobby: "Recepção",
            },
            categoriesDescription: {
                casino: "Entre na Emoção do Cassino",
                slots: "Caça-níqueis!",
                shooters: "Aponte e Ateire!",
                tableGames: "Jogos de Mesa Clássicos, Emoções Modernas – Jogue e Ganhe!",
                liveCasino: "Junte-se à Emoção do Cassino ao Vivo",
                crash: "Persiga o Multiplicador e Quebre os Limites!",
                sport: "Faça Suas Apostas nos Maiores Jogos",
            },
            footer: {
                content: [
                    "Bem-vindo à Salta la Banca",
                    `Seu destino definitivo para a emoção dos jogos online! Mergulhe em um mundo expansivo de apostas esportivas, explore nossa vasta coleção de jogos de cassino e aproveite a ação ao vivo com dealers a qualquer hora e em qualquer lugar.\n\nCom transações seguras e uma experiência sem interrupções, estamos aqui para elevar sua jornada de jogo.\n\nJunte-se a nós e que a emoção comece!\n\nTodos os direitos reservados © Salta la Banca 2024-2025`,
                    `Você deve ter pelo menos 18 anos para se registrar e abrir uma conta conosco. A empresa reserva-se o direito de solicitar prova de idade de qualquer cliente, e as contas podem ser suspensas até que uma prova de idade satisfatória seja fornecida. A administração do site não se responsabiliza por ações ou decisões tomadas pelos usuários. Este site oferece uma oportunidade de entretenimento e jogos, e todas as atividades são destinadas apenas para fins recreativos. Todos os direitos reservados. O uso ou reprodução não autorizada do conteúdo do site é estritamente proibido e protegido por lei.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Início",
                    "Esportes",
                    "Cassino",
                    "Cassino ao Vivo",
                    "Jogos de Crash",
                    "Jogos de Mesa",
                ],
                columnAboutUs: [
                    "Sobre Nós",
                    "Afiliados",
                    "Termos de Bônus",
                    "Verificação de Identidade",
                    "Política de Privacidade",
                    "Jogo Responsável",
                    "Opções de Pagamento",
                ],
                contactUsBTN: "Contate-nos",
            },
        },
    },
};
