import { FC, useRef } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import { CarouselSlide } from "./CarouselSlide";
import { Box } from "@mui/material";
import GetReadyGirlPV from "../../../assets/salta-la-banca/carousel-slides-redesign/Get_ready_phone_size@2x.webp";
import GetReadyGirl2x from "../../../assets/salta-la-banca/carousel-slides-redesign/Get_ready_img@2x.webp";
import Button from "../../../assets/carousel_button.png";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { PATHS } from "../../features/navigation/paths";

export const Carousel: FC = () => {
  const { smallScreen, mediumScreen } = useCommonMediaQueries();
  const carouselRef = useRef(null);

  return (
    <Box
      p={mediumScreen ? '0 0 40px' : '0 40px'}
      sx={{
        '.carousel .slider-wrapper': {
          width: smallScreen ? '100%' : '92%',
          zIndex: 1,
          position: 'relative',
        },
        '.carousel .control-dots': {
          zIndex: 2,
          marginBottom: smallScreen ? '-34px' : '',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'fit-content',
          backgroundColor: '#292f4963',
          backdropFilter: 'blur(8px)',
          padding: '10px',
          borderRadius: '8px',
          display: 'flex'
        },
        '.dot.selected': {
          background: '#B431AD'
        },
        '.dot': {
          background: '#505a80',
        },
        '.carousel.carousel-slider': {
          overflow: 'visible'
        }
      }}
    >
      <ResponsiveCarousel
        showThumbs={false}
        ref={carouselRef}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        interval={6000}
        showArrows={!smallScreen}
        renderArrowPrev={(prev) => {
          if (smallScreen) return null;
          return (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transform: 'translateY(-50%)',
                  height: '80%'
                }}
              >
                <img src={Button} height='100%' alt="Prev" />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '0',
                  transform: 'translateY(-50%)',
                  height: '80%',
                  zIndex: 2,
                  width: '120px',
                  cursor: 'pointer',
                }}
                onClick={prev}
              />
            </>
          )
        }}
        renderArrowNext={(next) => {
          if (smallScreen) return null;
          return (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '0',
                  transform: 'translateY(-50%)',
                  height: '80%'
                }}
              >
                <img src={Button} height='100%' style={{ transform: 'rotate(180deg)' }} alt="Next" />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '0',
                  transform: 'translateY(-50%)',
                  height: '80%',
                  zIndex: 2,
                  width: '120px',
                  cursor: 'pointer',
                }}
                onClick={next}
              >
              </Box>
            </>
          )
        }}
      >
        <Link to={PATHS.lobby}>
          <CarouselSlide bgImage={smallScreen ? GetReadyGirlPV : GetReadyGirl2x} />
        </Link>

        <Link to={PATHS.lobby}>
          <CarouselSlide bgImage={smallScreen ? GetReadyGirlPV : GetReadyGirl2x} />
        </Link>

        <Link to={PATHS.lobby}>
          <CarouselSlide bgImage={smallScreen ? GetReadyGirlPV : GetReadyGirl2x} />
        </Link>

        <Link to={PATHS.lobby}>
          <CarouselSlide bgImage={smallScreen ? GetReadyGirlPV : GetReadyGirl2x} />
        </Link>
      </ResponsiveCarousel>
    </Box >
  );
};
