import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

const SearchIconRD: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        id="Vector"
        d="M15.78 14.39L20 18.6L18.6 20L14.39 15.78C12.82 17.04 10.87 17.72 8.86 17.72C3.96 17.72 0 13.75 0 8.86C0 3.96 3.96 0 8.86 0C13.75 0 17.72 3.96 17.72 8.86C17.72 10.87 17.04 12.82 15.78 14.39ZM13.8 13.66C15.05 12.37 15.75 10.65 15.75 8.86C15.75 5.05 12.66 1.96 8.86 1.96C5.05 1.96 1.96 5.05 1.96 8.86C1.96 12.66 5.05 15.75 8.86 15.75C10.65 15.75 12.37 15.05 13.66 13.8L13.8 13.66Z"
        fill="#ADC2FF"
        fillOpacity="0.3"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default SearchIconRD;