import { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";
import { reFilterGamesByType } from "../../features/play_games/playGamesSlice";
import { useAppDispatch } from "../../store";
import { GamesSection } from "../../components/games/gamesSection/GamesSection";
import { TopGamesSection } from "../../components/games/topGamesSection/TopGamesSection";
import { GameCategories } from "../../components/gameCategories/GameCategories";
import CasinoIcon from "../../../assets/svg/CasinoIcon";
import LiveCasinoIcon from "../../../assets/svg/LiveCasinoIcon";
import TopProvidersIcon from "../../../assets/svg/TopProvidersIcon";
import CrashGamesIcon from "../../../assets/svg/CrashGamesIcon";
import TableGamesIcon from "../../../assets/svg/TableGamesIcon";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { TopWins } from "../../components/topWins/TopWins";
import { PATHS } from "../../features/navigation/paths";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { ProvidersListSection } from "../../components/providersList/ProvidersListSection";

export const HomePage: FC = () => {
  const dispatch = useAppDispatch();
  const { xxSmallScreen } = useCommonMediaQueries();
  const {
    languagePack: {
      pack: {
        argentina: { common: lang },
      },
    },
  } = useLanguage();

  useEffect(() => {
    dispatch(reFilterGamesByType(null));
  }, [dispatch]);

  return (
    <PageLayout pageName={"Home"}>
      <Stack gap={xxSmallScreen ? 3 : 4}>
        <GameCategories />

        <TopGamesSection
          icon={<CasinoIcon fontSize="large" fill="#B431AD" />}
          title={lang.topGames}
        />
        <GamesSection
          icon={<LiveCasinoIcon fontSize="large" fill="#B431AD" />}
          title={lang.topLiveCasino}
          link={PATHS.liveCasino}
          gameType={"live_casino"}
        />
        <ProvidersListSection
          icon={<TopProvidersIcon fontSize="large" fill="#B431AD" />}
          title={lang.topProviders}
        />
        <GamesSection
          icon={<CrashGamesIcon fontSize="large" fill="#B431AD" />}
          title={lang.topCrashGames}
          link={PATHS.crash}
          gameType={"crash"}
        />

        <GamesSection
          icon={<TableGamesIcon fontSize="large" fill="#B431AD" />}
          title={lang.topTableGames}
          link={PATHS.tableGames}
          gameType={"table_games"}
        />

        <TopWins />
      </Stack>
    </PageLayout>
  );
};
