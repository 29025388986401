import { FC } from "react";
import { Drawer } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { SideMenu } from "../sideMenu/SideMenu";

interface MobileDrawerProps {
  opened: boolean;
  onClose: (opened: boolean) => void;
}

export const MobileDrawer: FC<MobileDrawerProps> = ({ opened, onClose }) => {
  const { xxSmallScreen, smallScreen } = useCommonMediaQueries();

  const bottomMenu = (smallScreen || xxSmallScreen)
    ? "130px"
    : "100px";

  return (
    <Drawer
      anchor="right"
      onClose={() => onClose(false)}
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 270,
          boxSizing: "border-box",
          height: `calc(100vh - 74px - ${bottomMenu})`,
          top: "74px",
          bottom: bottomMenu,
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
          outline: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        },
      }}
      open={opened}
    >
      <SideMenu />
    </Drawer>
  );
};