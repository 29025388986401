import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const frLang: LangPack = {
    lang: Lang.FR,
    langName: "French",
    langShort: "fr",
    pack: {
        common: {
            noBTN: "Non",
            yesBTN: "Oui",
            errorCodeTitle: "Code d'erreur",
            pageSizeSwitcherLabel: "Lignes par page",
            ageConfirmationMessage: "Confirmez-vous que vous avez 18 ans ?",
            iConfirmBTN: "Je confirme",
            welcome: "Bienvenue",
            userProfile: "Profil utilisateur",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Êtes-vous sûr de vouloir vous déconnecter ?",
            logOut: "Déconnexion",
            depositMoney: "Déposer de l'argent",
            gamesHistory: "Historique des jeux",
            transactions: "Transactions",
            paymentAccounts: "Comptes de paiement",
            userInfo: "Informations utilisateur",
            withDrawMoney: "Retirer de l'argent",
        },
        mobileMenu: {
            profile: "Profil",
            topUpBalance: "Recharger le solde",
            logout: "Se déconnecter",
        },
        dashboard: {
            title: "Tableau de bord",
            youMustBeLoggedMessage: "Vous devez être connecté pour voir cette section !",
        },
        internalErrorMessages: {
            networkError: "Une erreur réseau s'est produite lors de l'exécution de la requête.",
            error502: "Une erreur de serveur 502 s'est produite lors de l'exécution de la requête.",
        },
        errorMessages: {
            2: "Aucune entrée",
            3: "Adresse e-mail utilisateur déjà existante",
            4: "Numéro de téléphone utilisateur déjà existant",
            5: "Utilisateur déjà connecté",
            6: "Mot de passe incorrect",
            7: "Résultat de jeux vide",
            8: "ID de résultat ne correspond pas à l'ID de la demande",
            9: "Erreur de demande de résultat",
            10: "Aucune ID de session dans la réponse",
            11: "Utilisateur déjà existant",
            12: "Votre jeton n'est pas valide. Connectez-vous à nouveau.",
            13: "Vous n'avez pas assez de droits pour effectuer cette action",
            14: "ID utilisateur manquante pour cette action",
            15: "Montant d'argent manquant",
            16: "L'action n'existe pas",
            17: "Jetons manquants",
            18: "Pas assez d'argent",
            19: "Vous devez être le parent de cet utilisateur pour effectuer cette action",
            20: "L'utilisateur n'existe pas",
            21: "Le type de valeur pour ce champ est incorrect",
            22: "Mot de passe manquant",
            23: "Nom d'utilisateur manquant",
            24: "ID de jeu manquante",
            25: "Nouveau mot de passe manquant",
            26: "Le jeton n'a pas pu être configuré",
            27: "Le jeton n'a pas pu être supprimé",
            28: "ID utilisateur manquante",
            29: "Format de date et d'heure incorrect",
            30: "Impossible de mettre à jour le bannissement de l'utilisateur",
            31: "Cet utilisateur doit être dans votre arbre pour effectuer cette action",
            32: "Cet utilisateur est banni et ne peut pas effectuer d'actions",
            33: "Devise manquante",
            34: "Valeur maximale manquante",
            35: "Le type de limite doit être une fois, quotidien, hebdomadaire ou mensuel",
            36: "Type de limite manquant",
            37: "Le fournisseur n'existe pas",
            38: "Montant de condition de palier manquant",
            39: "Type de condition de palier manquant",
            40: "Titre de palier manquant",
            41: "Le cashback pour ce palier existe déjà",
            42: "Pas de cashback pour ce palier",
            43: "Le bonus pour ce code promo existe déjà",
            44: "Le bonus n'existe pas",
            45: "Code promo manquant",
            46: "Heure de début manquante",
            47: "Montant de condition de dépôt manquant",
            48: "Mise manquante",
            49: "Jours de validité manquants",
            50: "ID manquant",
            51: "Type de cashback manquant, doit être 'mise' ou 'dépôt'",
            52: "Taux de cashback manquant",
            53: "Cette devise n'est pas disponible",
            54: "Paramètre d'interdiction manquant - vrai ou faux",
            55: "Le groupe bancaire pour cette devise n'est pas valide",
            56: "Nom de méthode de paiement manquant",
            57: "Paramètres obligatoires pour cette méthode de paiement manquants",
            58: "Cette méthode de paiement n'existe pas",
            59: "Cette méthode de paiement ne prend pas en charge la devise de cet utilisateur",
            60: "Cet utilisateur n'a pas de compte existant pour ce système de paiement",
            61: "Le parent de cet utilisateur n'a pas de compte existant pour ce système de paiement",
            62: "ID de transfert manquante",
            63: "Aucun transfert avec cette ID",
            64: "Ce transfert est demandé par un parent différent",
            65: "Vous avez toujours un bonus qui n'a pas expiré",
        },
        loginForm: {
            usernameFieldLabel: "Prénom",
            passwordFieldLabel: "Mot de passe",
            loginBTN: "Connexion",
            authorization: "Autorisation",
        },
        searchGameForm: {
            searchGame: "Rechercher des jeux",
            notFound:
                "Nous n'avons trouvé aucun résultat correspondant à votre demande, essayez autre chose.",
            results: "Résultats:",
            seeAll: "Voir tout",
            searchResults: "Résultats de la recherche pour",
        },
        userInfoForm: {
            emailIsRequiredMess: "L'e-mail est requis",
            notValidEmail: "Entrez une adresse e-mail valide",
            currentPassFieldLabel: "Mot de passe actuel",
            emailFieldLabel: "E-mail",
            emptyFieldWarningMessage: "Ce champ ne doit pas être vide",
            firstnameFieldLabel: "Prénom",
            lastnameFieldLabel: "Nom de famille",
            newPassFieldLabel: "Nouveau mot de passe",
            passNotMatchWarningMessage:
                "Le nouveau mot de passe et le mot de passe de re-saisie ne correspondent pas",
            phoneFieldLabel: "Téléphone",
            confirmPassFieldLabel: "Confirmer le mot de passe",
            saveInfoBTN: "Sauvegarder les informations",
            updatePassBTN: "Mettre à jour le mot de passe",
            usernameLabel: "Surnom",
            password: "Mot de passe",
            balance: "Solde",
        },
        transactionsTable: {
            amountTableHeader: "Montant",
            methodTableHeader: "Méthode",
            statusTableHeader: "Statut",
            depositWithdrawTableHeader: "Dépôt/Retrait",
            dateTimeTableHeader: "Date/Heure",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Fournisseur",
            gameTableHeader: "Jeu",
            betAmountTableHeader: "Montant de la mise",
            winAmountTableHeader: "Montant du gain",
            dateTimeTableHeader: "Date/Heure",
            userTableHeader: "Utilisateur",
            multiplierTableHeader: "Multiplicateur",
            payoutTableHeader: "Paiement",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Il n'y a plus de méthodes de paiement disponibles",
            paymentMethodsDropdownLabel: "Méthodes de paiement",
            addBTN: "Ajouter",
            yourPaymentAccountsTitle: "Vos comptes de paiement",
            addPaymentMethodBTN: "Ajouter une méthode de paiement",
        },
        moneyInput: {
            maxAmountMessage: "Valeur maximale",
            exceedAmountError: "Valeur maximale dépassée.",
            moreThanOneDotError: "Vous avez ajouté un point en trop.",
            moreThanTwoDecimals: "Vous avez saisi plus de deux décimales.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirer de l'utilisateur",
            moneySuccessfulRequestedMessage: "Vous avez demandé %money% pour le paiement.",
            requestBtn: "Demander de l'argent",
        },
        topWinModal: {
            aboutBet: "À propos de la mise",
            dateLabel: "Date",
            betIdLable: "ID de la mise",
            betAmount: "Montant de la mise",
            multiplier: "Multiplicateur",
            payout: "Paiement",
            play: "Jouer",
        },
        argentina: {
            common: {
                linkToAllTitle: "Tous",
                topGames: "Meilleurs jeux",
                topLiveCasino: "Meilleur Live Casino",
                topProviders: "Meilleurs fournisseurs",
                topCrashGames: "Meilleurs jeux de crash",
                topTableGames: "Meilleurs jeux de table",
                liveFeed: "Flux en direct",
                seeAllBTN: "Afficher tout",
                returnBackBTN: "Retour",
                playGame: "Jouer",
            },
            termsAndConditions: {
                content: [
                    "Termes et Conditions",
                    "Bienvenue à Salta La Blanca, votre destination de confiance pour les casinos en ligne. En accédant à notre site web ou en utilisant l'un de nos services, vous reconnaissez et acceptez d'être lié par ces Termes et Conditions. Veuillez prendre un moment pour les lire attentivement, car ils décrivent les droits et obligations des deux parties pour garantir une expérience de jeu équitable et transparente.",
                    "Acceptation des Termes",
                    "En créant un compte ou en utilisant l'une des fonctionnalités de notre plateforme, vous confirmez avoir lu, compris et accepté ces Termes et Conditions. Si vous n'êtes pas d'accord avec l'une des dispositions énoncées ici, nous vous demandons de ne pas utiliser nos services. Votre utilisation continue de la plateforme constitue une confirmation de votre acceptation.",
                    "Éligibilité pour Utiliser",
                    "L'accès à Salta La Blanca est strictement limité aux personnes qui remplissent les conditions d'âge légal pour jouer dans leur juridiction, généralement 18 ans ou plus. Il est de votre responsabilité de déterminer si la participation aux jeux d'argent en ligne est autorisée par les lois de votre pays ou région. Nous nous réservons le droit de demander une vérification de l'âge à tout moment et pouvons suspendre les comptes si les conditions d'éligibilité ne sont pas remplies.",
                    "Compte Utilisateur",
                    "Pour accéder à nos jeux et services, vous devez créer un compte en fournissant des informations exactes et à jour, y compris votre nom, adresse e-mail et autres détails pertinents. Vous êtes responsable de la confidentialité des identifiants de votre compte et de vous assurer qu'aucune personne non autorisée n'y a accès. Si vous soupçonnez une activité non autorisée, vous devez nous en informer immédiatement pour éviter toute utilisation abusive.",
                    "Dépôts et Retraits",
                    "Les dépôts sur votre compte de jeu doivent être effectués en utilisant les méthodes de paiement officiellement approuvées par Salta La Blanca. Toutes les transactions sont traitées de manière sécurisée, et nous prenons les précautions nécessaires pour protéger vos informations financières. Les retraits peuvent être soumis à des procédures de vérification d'identité pour se conformer aux exigences légales et réglementaires. Veuillez noter que les délais de traitement des retraits peuvent varier selon la méthode de paiement choisie.",
                    "Bonus et Promotions",
                    "Nous offrons une variété de bonus et promotions passionnants pour enrichir votre expérience de jeu. Chaque bonus est soumis à des termes et conditions spécifiques, tels que des exigences de mise et des périodes d'expiration, qui seront clairement détaillés dans les informations de la promotion. L'utilisation abusive ou la fraude des offres promotionnelles, y compris les tentatives de contournement des règles, peut entraîner la perte des bonus et des gains associés.",
                    "Jeu Responsable",
                    "Chez Salta La Blanca, nous nous engageons à promouvoir le jeu responsable et à garantir que notre plateforme soit un environnement sûr pour tous les utilisateurs. Nous fournissons des outils pour vous aider à gérer votre activité de jeu, tels que la fixation de limites de dépôt, de limites de mise et des options d'auto-exclusion. Si vous estimez que vos habitudes de jeu deviennent problématiques, nous vous encourageons vivement à demander de l'aide via des ressources disponibles comme [Support pour le Jeu Responsable].",
                    "Activités Interdites",
                    "Pour maintenir un environnement de jeu équitable et sécurisé, toute pratique frauduleuse, manipulatrice ou déloyale est strictement interdite. Cela inclut, entre autres, l'utilisation de bots automatisés, la collusion avec d'autres joueurs ou toute tentative d'exploitation des vulnérabilités du système. Participer à de telles activités entraînera la suspension ou la fermeture immédiate de votre compte et la perte de tout gain.",
                    "Propriété Intellectuelle",
                    "Tout le contenu de la plateforme Salta La Blanca, y compris mais sans s'y limiter, les logos, designs, textes, logiciels et multimédias, est la propriété exclusive de notre entreprise. Ces matériaux sont protégés par les lois internationales sur le droit d'auteur et la propriété intellectuelle. Toute utilisation, reproduction ou distribution non autorisée de notre contenu est strictement interdite et peut entraîner des poursuites judiciaires.",
                    "Limitation de Responsabilité",
                    "Bien que nous nous efforcions de fournir une expérience de jeu fluide et agréable, Salta La Blanca ne garantit pas que notre plateforme sera exempte d'erreurs, d'interruptions ou de problèmes techniques. Nous ne serons pas responsables des pertes directes, indirectes ou incidentales résultant de votre utilisation de la plateforme, y compris les pertes résultant de transactions échouées, de dysfonctionnements techniques ou d'accès non autorisé à votre compte.",
                    "Modifications",
                    "Salta La Blanca se réserve le droit de modifier ces Termes et Conditions à tout moment pour refléter les changements dans les exigences légales, les pratiques commerciales ou les commentaires des utilisateurs. Les mises à jour seront publiées sur notre site web, et nous vous encourageons à revoir ces Termes périodiquement. Votre utilisation continue de la plateforme après que des modifications ont été apportées constitue votre acceptation des Termes révisés.",
                    "Loi Applicable et Litiges",
                    "Ces Termes et Conditions sont régis par les lois de [indiquer la juridiction]. Tout litige découlant de l'utilisation de nos services sera résolu conformément à ces lois. En cas de désaccord, nous encourageons les utilisateurs à contacter notre équipe de support pour rechercher une résolution amiable avant d'engager des poursuites judiciaires.",
                    "Contactez-Nous",
                ],
                email: `Pour toute question, préoccupation ou commentaire concernant ces Termes et Conditions, n'hésitez pas à contacter notre équipe de support dédiée à <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a>. Nous sommes là pour vous aider et garantir que votre expérience sur notre plateforme soit agréable et sécurisée.`,
            },
            aboutUs: {
                content: [
                    "À propos de nous",
                    "Bienvenue sur Salta La Blanca, votre destination en ligne de premier choix pour une expérience de jeu palpitante et variée. Que vous soyez un joueur chevronné ou nouveau dans le monde du jeu en ligne, notre plateforme est conçue pour vous offrir un mélange parfait d'excitation, de variété et de divertissement de qualité.",
                    "Ce que nous proposons",
                    "Chez Salta La Blanca, nous sommes fiers de proposer une large gamme d'options de jeu pour répondre aux préférences de chaque joueur. Voici ce que vous pouvez apprécier sur notre plateforme :",
                    "Classiques du casino : Revivez le charme intemporel des jeux de casino traditionnels comme le blackjack, la roulette et le baccara. Avec des améliorations modernes et des designs élégants, ces classiques restent aussi passionnants que jamais, offrant à la fois stratégie et chance pour une expérience captivante.",
                    "Jeux de crash : Vous cherchez une montée d'adrénaline rapide ? Nos jeux de crash sont parfaits pour les joueurs qui aiment les parties riches en action où chaque seconde compte. Testez vos réflexes et votre intuition pour gagner gros dans cette catégorie de jeu unique et palpitante.",
                    "Casino en direct : Plongez dans l'atmosphère authentique d'un casino sans quitter votre domicile. Avec de vrais croupiers diffusés en haute définition, notre casino en direct apporte l'excitation des casinos terrestres directement sur votre écran. Interagissez avec les croupiers et les autres joueurs pour une expérience véritablement sociale et engageante.",
                    "Paris sportifs : Pour les amateurs de sport, nous proposons une section complète de paris sportifs où vous pouvez parier sur vos équipes, matchs et événements préférés. Avec des cotes compétitives et une variété de sports à choisir, il n'a jamais été aussi simple de transformer votre passion pour le sport en paris passionnants.",
                    "Jeux de table : Vous aimez les jeux de stratégie et d'adresse ? Découvrez une large sélection de jeux de table qui défient votre esprit tout en offrant un divertissement sans fin. Du poker au craps, nous avons quelque chose pour chaque amateur de jeux de table.",
                    "Une large gamme de fournisseurs : Pour garantir la plus haute qualité, nous collaborons avec les meilleurs fournisseurs de jeux de casino de l'industrie. Cela signifie que vous aurez accès à une bibliothèque impressionnante de jeux aux visuels époustouflants, aux fonctionnalités innovantes et aux performances fiables.",
                    "Pourquoi nous choisir ?",
                    "Chez Salta La Blanca, nous allons au-delà de la simple offre de jeux — nous nous efforçons de créer un environnement de jeu divertissant, sécurisé et centré sur le joueur. Voici pourquoi nous nous distinguons :",
                    "Innovation : Nous mettons constamment à jour notre plateforme avec les dernières tendances et technologies pour offrir une expérience de jeu à la pointe. Des graphismes de haute qualité aux mécaniques de jeu uniques, nous nous engageons à maintenir une expérience fraîche et excitante pour nos joueurs.",
                    "Variété : Nous comprenons que chaque joueur est unique, c'est pourquoi nous proposons une gamme diversifiée de jeux et d'options de paris. Quelles que soient vos préférences, vous trouverez quelque chose qui correspond à votre style et vous gardera engagé.",
                    "Jeu équitable : L'intégrité est au cœur de ce que nous faisons. Nos jeux sont basés sur une technologie de générateur de nombres aléatoires (RNG) fiable, garantissant que tous les résultats sont équitables et impartiaux. Notre collaboration avec des fournisseurs réputés garantit en outre la transparence et la fiabilité.",
                    "Approche centrée sur le joueur : Votre satisfaction est notre priorité. Nous avons conçu notre plateforme pour qu'elle soit conviviale et intuitive, avec une navigation fluide et des fonctionnalités qui rendent le jeu agréable et sans tracas. De plus, notre équipe de support client dédiée est toujours prête à vous aider pour toute question ou préoccupation.",
                    "Rejoignez-nous sur Salta La Blanca, où chaque tour, lancer et pari crée des opportunités pour des moments inoubliables. Prêt à plonger dans l'action ? Faisons de chaque partie une expérience gagnante !",
                    "Support en Direct",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Devenez un Agent",
                    "Êtes-vous passionné par les jeux en ligne et cherchez-vous une opportunité de transformer cette passion en un partenariat enrichissant ? Rejoignez le Programme d'Agents de Salta La Blanca et devenez une partie essentielle de notre communauté en pleine croissance. C'est votre chance de gagner, de vous connecter et de prospérer dans le monde dynamique du divertissement en ligne tout en promouvant une plateforme que vous pouvez vraiment soutenir.",
                    "Pourquoi devenir partenaire de Salta La Blanca ?",
                    "Lorsque vous rejoignez notre Programme d'Agents, vous ne vous inscrivez pas simplement — vous rejoignez un réseau qui valorise vos contributions et récompense vos efforts. Voici ce qui nous distingue :",
                    "Potentiel de Gains Élevé : Nous offrons l'une des structures de commission les plus compétitives de l'industrie, vous donnant l'opportunité de maximiser vos revenus en fonction de l'activité et de la fidélité des joueurs que vous attirez sur notre plateforme.",
                    "Outils de Marketing Avancés : Accédez à une suite d'outils professionnels, y compris des liens de suivi personnalisés, des analyses de performance détaillées et une variété de matériels promotionnels de haute qualité conçus pour vous aider à réussir.",
                    "Support Complet : Notre équipe d'affiliation dédiée est là pour vous soutenir à chaque étape. Que vous ayez des questions techniques ou besoin de conseils marketing, nous sommes à un message de distance.",
                    "Portefeuille de Jeux Diversifié : Promouvez une plateforme qui offre quelque chose pour tout le monde, des classiques du casino aux jeux de croupier en direct, jeux de crash, paris sportifs et plus encore — tous fournis par les meilleurs fournisseurs de jeux.",
                    "Que fait un Agent ?",
                    "En tant qu'agent de Salta La Blanca, votre rôle est de faire connaître notre plateforme et d'attirer de nouveaux joueurs. Vous utiliserez votre réseau, votre créativité et vos compétences en marketing pour développer votre audience, tout en gagnant des récompenses substantielles. C'est un ajustement parfait, que vous soyez un marketeur expérimenté, un créateur de contenu ou simplement quelqu'un avec une forte passion pour les jeux.",
                    "Comment fonctionne le programme ?",
                    "Inscrivez-vous : Complétez un processus d'inscription rapide et facile pour devenir un agent officiel.",
                    "Obtenez vos Outils : Accédez à votre tableau de bord personnalisé avec des liens de suivi, des bannières promotionnelles et d'autres ressources marketing.",
                    "Promouvez Notre Plateforme : Utilisez vos outils pour partager Salta La Blanca avec des joueurs potentiels via des sites web, les réseaux sociaux, les forums ou toute autre plateforme qui vous convient.",
                    "Gagnez des Commissions : Pour chaque joueur que vous parrainez, vous gagnerez des commissions basées sur leur activité, assurant un flux de revenus régulier.",
                    "Qui peut rejoindre ?",
                    "Notre programme est ouvert à tous ceux qui ont le désir de réussir et une connexion avec le monde des jeux. Que vous soyez un influenceur avec une grande audience, un passionné des réseaux sociaux ou quelqu'un avec un réseau d'amis qui adorent les jeux, nous vous fournissons tout ce dont vous avez besoin pour transformer vos efforts en résultats tangibles.",
                    "Votre Succès est Notre Succès",
                    "Chez Salta La Blanca, nous croyons en la construction de relations solides et mutuellement bénéfiques avec nos agents. C'est pourquoi nous investissons dans votre succès en offrant des systèmes de paiement transparents, un soutien continu et une plateforme que les joueurs adorent. Lorsque vous réussissez, nous réussissons.",
                    "Prêt à rejoindre ?",
                    "Devenir un agent de Salta La Blanca est simple et gratifiant. Faites le premier pas vers un avenir plus excitant en vous inscrivant aujourd'hui. Cliquez sur [Devenez un Agent] et commencez à gagner tout en faisant partie de l'un des noms les plus fiables dans les jeux en ligne.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Accueil",
                    lobby: "Hall",
                    casino: "Casino",
                    sport: "Sport",
                    liveBetting: "Paris en Direct",
                    liveCasino: "Casino en Direct",
                    crashGames: "Jeux de Crash",
                    tableGames: "Jeux de Table",
                    legends: "Légendes",
                    becomeAnAgent: "Devenir un Agent",
                    privacyPolicy: "Politique de Confidentialité",
                    responsibleGaming: "Jeu Responsable",
                    termsAndConditions: "Conditions Générales",
                    aboutUs: "À Propos de Nous",
                    userInfo: "Informations de l'utilisateur",
                    gameHistory: "Historique des jeux",
                    transactions: "Transactions",
                },
                loggedInUserMenu: {
                    userInfo: "Informations de l'utilisateur",
                    userTransactions: "Transactions",
                    userGameHistory: "Historique des jeux",
                    userLogOut: "Se déconnecter",
                },
                userInfo: {
                    userInfoSection: "Informations utilisateur",
                    changePasswordSection: "Changer le mot de passe",
                },
            },
            carousel: {
                slide1: {
                    header: "Maîtrise le Totem<br/> et Remporte la Victoire",
                    tagline: "Lance et Gagne avec Galaxsys!",
                },
                slide2: {
                    header: "Découvrez le frisson<br/>seulement chez Salta la Banca",
                    tagline: "Rejoignez le jeu aujourd'hui !",
                },
                slide3: {
                    header: "Imaginez Plus, Gagnez Plus<br/>chez Imagine Live",
                    tagline: "Les enjeux sont réels",
                },
                slide4: {
                    header: "Ressens l'excitation du jeu !<br/>Vis le moment de la victoire !",
                    tagline: "Rejoins-nous maintenant !",
                },
                slide5: {
                    header: "Ezugi arrive",
                    tagline: "À la fin de cette semaine !",
                },
            },
            menuLinkTitles: {
                home: "Accueil",
                casino: "Casino",
                liveCasino: "Live Casino",
                virtualGames: "Jeu virtuel",
            },
            sideBarSections: {
                games: "Jeu",
                topCrashGames: "Jeu Top Crash",
                other: "Autre",
                sportsRules: "Regle du sport",
            },
            headerMenu: {
                home: "Accueil",
                casino: "Casino",
                slots: "Machines à Sous",
                shooters: "Jeux de Tir",
                tableGames: "Jeux de Table",
                liveCasino: "Casino en Direct",
                crash: "Jeux de Crash",
                sport: "Sport",
                menu: "Menu",
                lobby: "Hall",
            },
            categoriesDescription: {
                casino: "Plongez dans l'Excitation du Casino",
                slots: "Machines à Sous !",
                shooters: "Visez et Tirez !",
                tableGames: "Jeux de Table Classiques, Sensations Modernes – Jouez et Gagnez !",
                liveCasino: "Rejoignez l'Action en Direct du Casino",
                crash: "Pourchassez le Multiplicateur et Défoncez les Limites !",
                sport: "Placez Vos Paris sur les Plus Grands Jeux",
            },
            footer: {
                content: [
                    "Bienvenue à Salta la Banca",
                    `Votre destination ultime pour des frissons de jeux en ligne ! Plongez dans un vaste monde de paris sportifs, explorez notre vaste collection de jeux de casino et profitez de l'action en direct avec des croupiers à tout moment et en tout lieu.\n\nAvec des transactions sécurisées et une expérience fluide, nous sommes là pour élever votre expérience de jeu.\n\nRejoignez-nous et que l'excitation commence !\n\nTous droits réservés © Salta la Banca 2024-2025`,
                    `Vous devez avoir au moins 18 ans pour vous inscrire et ouvrir un compte chez nous. L'entreprise se réserve le droit de demander une preuve d'âge à tout client, et les comptes peuvent être suspendus jusqu'à ce qu'une preuve d'âge satisfaisante soit fournie. L'administration du site décline toute responsabilité pour les actions ou décisions prises par les utilisateurs. Ce site offre une opportunité de divertissement et de jeux, et toutes les activités sont destinées uniquement à des fins récréatives. Tous droits réservés. L'utilisation ou la reproduction non autorisée du contenu du site est strictement interdite et protégée par la loi.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Accueil",
                    "Sport",
                    "Casino",
                    "Casino en Direct",
                    "Jeux de Crash",
                    "Jeux de Table",
                ],
                columnAboutUs: [
                    "À Propos de Nous",
                    "Affiliés",
                    "Conditions des Bonus",
                    "Vérification d'Identité",
                    "Politique de Confidentialité",
                    "Jeu Responsable",
                    "Options de Paiement",
                ],
                contactUsBTN: "Contactez-nous",
            },
        },
    },
};
