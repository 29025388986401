import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface HeadPhonesIconProps extends SvgIconProps {
  fill?: string;
}

const HeadPhonesIcon: FC<HeadPhonesIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 17">
      <path
        fill={fill}
        fillOpacity="1.000000"
        fillRule="evenodd"
        d="M9 0C6.57 0 4.27 1.08 2.59 2.97C0.92 4.85 0 7.38 0 10L0 14C0 15.65 1.34 17 3 17C4.65 17 6 15.65 6 14L6 11C6 9.34 4.65 8 3 8C2.72 8 2.45 8.03 2.19 8.1C2.5 6.66 3.15 5.34 4.09 4.3C5.41 2.81 7.18 2 9 2C10.81 2 12.58 2.81 13.9 4.3C14.84 5.34 15.49 6.66 15.8 8.1C15.54 8.03 15.27 8 15 8C13.34 8 12 9.34 12 11L12 14C12 15.65 13.34 17 15 17C16.65 17 18 15.65 18 14L18 10C18 7.38 17.07 4.85 15.4 2.97C13.72 1.08 11.42 0 9 0Z"
      />
    </SvgIcon>
  );
};

export default HeadPhonesIcon;
