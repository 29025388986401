import { FC, useEffect } from "react";
import { TopWinsTable } from "./TopWinsTable";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useTopWins } from "../../features/top_wins/useTopWins";
import { useUser } from "../../features/user/useUser";
import { TopWinsCards } from "./TopWinsCards";
import { Stack } from "@mui/material";
import LiveFeedIcon from "../../../assets/svg/LiveFeedIcon";
import { useLanguage } from "../../features/localisation/useLanguage";
import { SectionDivider } from "../sectionDivider/SectionDivider";

interface TopWinsProps {
    legends?: boolean
    showDivider?: boolean
}

export const TopWins: FC<TopWinsProps> = ({ legends = false, showDivider = true }) => {
    const { xxSmallScreen } = useCommonMediaQueries()
    const { getTopWins, isTopWinsLoading } = useTopWins();
    const { user } = useUser();
    const {
        languagePack: {
            pack: {
                argentina: { common: lang },
            },
        },
    } = useLanguage();

    useEffect(() => {
        if (!isTopWinsLoading) {
            getTopWins()
        }
    }, [isTopWinsLoading, getTopWins])

    if (!user) return null;

    if (legends && xxSmallScreen) return <TopWinsCards />

    return (
        <Stack width="100%">
            {showDivider &&
                <SectionDivider
                    icon={<LiveFeedIcon fontSize="large" fill="#B431AD" />}
                    title={lang.liveFeed}
                    showLeftRightButtons={false}
                />
            }
            <TopWinsTable legends={legends} />
        </Stack>
    )
}