import { useLocation } from "react-router-dom";
import { useGamesHistory } from "./useGamesHistory";
import { useEffect } from "react";
import { getNumberParam } from "../common_funcs";

export const useGamesHistoryWatcher = (disabled: boolean = false) => {
    const location = useLocation();
    const { pageSize, currentPage, getGameHistory, gameHistory, isGameHistoryLoading } =
        useGamesHistory();

    useEffect(() => {
        if (!isGameHistoryLoading && !disabled) {
            const searchParams = new URLSearchParams(location.search);
            const qPage = getNumberParam(searchParams.get("page"));
            const qPageSize = getNumberParam(searchParams.get("pageSize"));

            const pageHasChanges = qPage !== null && qPage !== currentPage;
            const pageSizeHasChanges = qPageSize !== null && qPageSize !== pageSize;
            const gamesNotLoadedYet = gameHistory === null;

            if (pageHasChanges || pageSizeHasChanges || gamesNotLoadedYet) {
                const pageNew = qPage !== null ? qPage : 1;
                const newPageSize = qPageSize !== null ? qPageSize : pageSize;
                getGameHistory(pageNew, newPageSize);
            }
        }
    }, [
        currentPage,
        disabled,
        gameHistory,
        getGameHistory,
        isGameHistoryLoading,
        location.search,
        pageSize,
    ]);
};
