import { FC, useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { useAppDispatch } from "../../store";
import { reFilterGamesByType } from "../../features/play_games/playGamesSlice";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Games } from "../../components/games/games/Games";
import TopProvidersIcon from "../../../assets/svg/TopProvidersIcon";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { TopWins } from "../../components/topWins/TopWins";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { ProvidersListSection } from "../../components/providersList/ProvidersListSection";

interface GamesPageProps {
  gameType?: string;
  title: string;
}

export const GamesPage: FC<GamesPageProps> = ({ gameType, title }) => {
  const {
    languagePack: {
      pack: {
        argentina: { common: lang },
      },
    },
  } = useLanguage();
  const dispatch = useAppDispatch();
  const [providerName, setProviderName] = useState<string | null>(null);
  const gameTypePrev = useRef<string | null>(null);
  const { xxSmallScreen } = useCommonMediaQueries();

  useEffect(() => {
    if (gameType && gameTypePrev.current !== gameType) {
      dispatch(reFilterGamesByType(gameType));
      gameTypePrev.current = gameType;
    }
  }, [dispatch, gameType]);

  return (
    <PageLayout pageName={providerName ?? title} carouselDisabled>
      <Stack gap={xxSmallScreen ? 3 : 4}>
        <Games onProviderNameChanged={setProviderName} />

        <ProvidersListSection
          icon={<TopProvidersIcon fontSize="large" fill="#B431AD" />}
          title={lang.topProviders}
        />

        <TopWins />

      </Stack>
    </PageLayout>
  );
};
