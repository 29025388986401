import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface TableGamesIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const TableGamesIcon: FC<TableGamesIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19.2002 17.6821">
      <g opacity="0.989000">
        <path
          // fill="#ADC2FF"
          fill={fill} // Use the fill prop
          fillOpacity="1.000000"
          fillRule="evenodd"
          d="M19.2 14.11C19.2 14.33 19.2 14.54 19.2 14.75C19.04 15.39 18.65 15.82 18.03 16.04C16.28 16.57 14.53 17.1 12.78 17.62C11.88 17.82 11.21 17.51 10.78 16.7C10.23 14.93 9.69 13.15 9.16 11.38C9.02 12.78 8.85 14.18 8.68 15.58C8.4 16.3 7.86 16.69 7.08 16.74C5.11 16.54 3.13 16.32 1.16 16.08C0.55 15.86 0.16 15.44 0 14.83C0 14.56 0 14.3 0 14.04C0.19 12.28 0.39 10.52 0.58 8.75C0.68 8.19 0.99 7.79 1.5 7.53C1.69 7.45 1.9 7.39 2.11 7.36C3.36 7.49 4.6 7.61 5.84 7.74C5.72 5.82 5.62 3.9 5.54 1.98C5.53 1.29 5.82 0.76 6.41 0.41C6.62 0.31 6.85 0.24 7.08 0.22C9.06 0.1 11.03 0.03 13.01 0C13.79 0.18 14.24 0.67 14.34 1.48C14.45 3.46 14.53 5.45 14.56 7.44C14.53 7.6 14.48 7.75 14.4 7.89C14.41 7.9 14.42 7.91 14.43 7.93C14.83 7.79 15.23 7.67 15.63 7.57C16.68 7.47 17.35 7.93 17.64 8.94C18.16 10.67 18.67 12.39 19.2 14.11ZM9.52 1.81C9.81 1.76 10.05 1.84 10.25 2.04C10.44 2.42 10.35 2.7 9.97 2.88C9.48 2.92 9 2.95 8.51 2.98C8.51 3.35 8.52 3.71 8.54 4.08C8.62 4.52 8.44 4.79 8.02 4.89C7.72 4.85 7.54 4.69 7.48 4.4C7.45 3.85 7.42 3.3 7.38 2.75C7.4 2.28 7.63 2 8.09 1.91C8.58 1.88 9.05 1.85 9.52 1.81ZM11.17 8.83C11.23 8.82 11.3 8.83 11.36 8.85C10.88 8.98 10.4 9.13 9.93 9.29C9.69 9.45 9.48 9.64 9.31 9.88C9.35 9.56 9.31 9.25 9.18 8.96C9.85 8.91 10.51 8.87 11.17 8.83ZM2.84 9.28C3.5 9.31 4.15 9.36 4.79 9.45C5.07 9.51 5.23 9.68 5.28 9.97C5.24 10.23 5.11 10.4 4.87 10.5C4.36 10.48 3.84 10.45 3.33 10.4C3.28 10.88 3.22 11.35 3.16 11.83C2.98 12.17 2.71 12.26 2.36 12.11C2.2 11.99 2.11 11.84 2.09 11.64C2.16 11.04 2.23 10.44 2.3 9.84C2.39 9.56 2.57 9.37 2.84 9.28ZM13.38 10.14C13.86 10.18 14.05 10.43 13.96 10.89C13.91 11.01 13.83 11.1 13.72 11.17C13.26 11.31 12.81 11.46 12.35 11.6C12.47 12.08 12.6 12.55 12.75 13.03C12.67 13.46 12.42 13.63 12 13.53C11.75 13.37 11.61 13.14 11.56 12.84C11.44 12.41 11.31 11.99 11.19 11.56C11.14 11.21 11.26 10.93 11.54 10.72C12.16 10.52 12.77 10.33 13.38 10.14Z"
        />
      </g>
    </SvgIcon>
  );
};

export default TableGamesIcon;
