import { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";

interface ConfirmationDialogProps {
    close: () => void
    message: string
    onConfirm: () => void
    onCancel?: () => void
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ close, message, onConfirm, onCancel = () => { } }) => {
    const { languagePack } = useLanguage()
    const [opened, setOpened] = useState(true)

    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close(), 500)
    }

    return <Dialog
        maxWidth={'xs'}
        fullWidth
        open={opened}
        // transitionDuration={ Settings.FADE_DURATION }
        onClose={pendingClose}
    >
        <DialogContent>
            {message}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                onCancel()
                pendingClose()
            }
            } color={'warning'}>
                {languagePack.pack.common.noBTN}
            </Button>
            <Button color={'primary'} onClick={() => {
                onConfirm()
                pendingClose()
            }}>
                {languagePack.pack.common.yesBTN}
            </Button>
        </DialogActions>
    </Dialog>
}