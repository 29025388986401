import { FC } from "react";
import { useTransactions } from "../../features/transactions/useTransactions";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { Balance } from "../userInfo/personalInfo/Balance";
import { getColorForTransactionApproveStatus } from "../../features/transactions/funcs";

export const TransactionsCards: FC = () => {
    const { languagePack: { pack: { transactionsTable } } } = useLanguage()
    const { transactions } = useTransactions()

    return <Stack gap='10px' width='100%'>
        {
            transactions?.map(transaction => (
                <Card
                    key={transaction.uuid}
                    sx={{
                        p: '20px',
                        width: '100%',
                        background: "linear-gradient(270deg, #1C223F 0%, #141931 100%)"
                    }}
                >
                    <Stack gap='10px'>
                        <Typography fontWeight='bold' variant="caption">{transaction.respond_time ?? transaction.request_time}</Typography>
                        <Stack gap='10px' direction='row' justifyContent='space-between'>
                            <Box width='100%'>
                                <Stack gap='10px'>
                                    <Stack>
                                        <Typography color=' #AEC2FF4D' variant="caption">{transactionsTable.operationTableHeader}:</Typography>
                                        <Typography fontWeight='bold' textTransform='capitalize'>
                                            {transaction.transfer_type}
                                        </Typography>

                                    </Stack>
                                    <Stack gap='4px'>
                                        <Typography color=' #AEC2FF4D' variant="caption">{transactionsTable.methodTableHeader}:</Typography>
                                        <Typography fontWeight='bold' textTransform='capitalize'>
                                            {transaction.money_transfer_method_name || transaction.method_name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                            <Divider orientation="vertical" sx={{ height: 'initial' }} />
                            <Box width='100%'>
                                <Stack gap='10px'>
                                    <Stack>
                                        <Typography color=' #AEC2FF4D' variant="caption">{transactionsTable.amountTableHeader}:</Typography>
                                        <Balance
                                            balance={transaction.transfer_amount}
                                            currency={transaction.transfer_currency!}
                                            hideBackground
                                            border='none'
                                            hideIcon
                                        />
                                    </Stack>
                                    <Stack gap='4px'>
                                        <Typography color=' #AEC2FF4D' variant="caption">{transactionsTable.statusTableHeader}:</Typography>
                                        <Typography fontWeight='bold' color={getColorForTransactionApproveStatus(transaction.transfer_status)} textTransform='capitalize'>
                                            {transaction.transfer_status}
                                        </Typography>
                                    </Stack>

                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                </Card>
            ))
        }

    </Stack >
}

