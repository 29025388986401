import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

const MessageIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        // fill="#ADC2FF"
        fill="rgba(174, 194, 255, 0.3)"
        fillOpacity="1.000000"
        fillRule="evenodd"
        d="M11.08 19.38L11.62 18.47C12.04 17.76 12.25 17.4 12.59 17.2C12.93 17.01 13.36 17 14.21 16.99C15.46 16.97 16.25 16.89 16.91 16.61C17.51 16.36 18.07 15.99 18.53 15.53C18.99 15.07 19.36 14.51 19.61 13.91C20 12.99 20 11.83 20 9.5L20 8.5C20 5.22 20 3.58 19.26 2.38C18.85 1.71 18.28 1.14 17.61 0.73C16.41 0 14.77 0 11.5 0L8.5 0C5.22 0 3.58 0 2.38 0.73C1.71 1.14 1.14 1.71 0.73 2.38C0 3.58 0 5.22 0 8.5L0 9.5C0 11.83 0 12.99 0.37 13.91C0.63 14.51 1 15.07 1.46 15.53C1.92 15.99 2.48 16.36 3.08 16.61C3.74 16.89 4.53 16.96 5.79 16.99C6.63 17 7.06 17.01 7.4 17.2C7.74 17.4 7.95 17.76 8.37 18.47L8.91 19.38C9.39 20.2 10.6 20.2 11.08 19.38ZM14 10C14.26 10 14.51 9.89 14.7 9.7C14.89 9.51 15 9.26 15 9C15 8.73 14.89 8.48 14.7 8.29C14.51 8.1 14.26 8 14 8C13.73 8 13.48 8.1 13.29 8.29C13.1 8.48 13 8.73 13 9C13 9.26 13.1 9.51 13.29 9.7C13.48 9.89 13.73 10 14 10ZM11 9C11 9.26 10.89 9.51 10.7 9.7C10.51 9.89 10.26 10 10 10C9.73 10 9.48 9.89 9.29 9.7C9.1 9.51 9 9.26 9 9C9 8.73 9.1 8.48 9.29 8.29C9.48 8.1 9.73 8 10 8C10.26 8 10.51 8.1 10.7 8.29C10.89 8.48 11 8.73 11 9ZM6 10C6.26 10 6.51 9.89 6.7 9.7C6.89 9.51 7 9.26 7 9C7 8.73 6.89 8.48 6.7 8.29C6.51 8.1 6.26 8 6 8C5.73 8 5.48 8.1 5.29 8.29C5.1 8.48 5 8.73 5 9C5 9.26 5.1 9.51 5.29 9.7C5.48 9.89 5.73 10 6 10Z"
      />
    </SvgIcon>
  );
};

export default MessageIcon;
