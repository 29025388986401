import { FC, RefObject } from "react";
import { DesktopFooter } from "./DesktopFooter";
import { MobileFooter } from "./MobileFooter";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Box } from "@mui/material";

export interface FooterProps {
    scrollContainerRef: RefObject<HTMLDivElement>;
}

export const Footer: FC<FooterProps> = (props) => {
    const { smallScreen } = useCommonMediaQueries()

    return <Box pb={7}>{smallScreen ? <MobileFooter {...props} /> : <DesktopFooter {...props} />}</Box>
}