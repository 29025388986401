import { FC } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface PaginatorProps {
    totalPages: number
    currentPage: number
}

export const Paginator: FC<PaginatorProps> = ({ totalPages, currentPage }) => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();

    const changePage = (page: number) => {
        searchParams.set('page', page.toString());

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return (
        <Stack direction='row' gap={1.2} >
            <Button
                onClick={() => changePage(Math.max(1, currentPage - 1))}
                sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                    minWidth: '50px',
                    borderRadius: '10px'
                }}
                disabled={currentPage === 1}
            >
                <NavigateBeforeIcon />
            </Button>
            <Box
                p='8px 20px'
                borderRadius='10px'
                border='2px solid #E4E9F81A'
                sx={{ background: 'radial-gradient(117.03% 100% at 51.3% 100%, #141931 0%, #0F1328 56.5%, #05091E 100%)' }}
                display='flex'
                alignItems='center'
            >
                {currentPage}
            </Box>
            <Button
                onClick={() => changePage(Math.min(totalPages, currentPage + 1))}
                sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                    minWidth: '50px',
                    borderRadius: '10px'
                }}
                disabled={currentPage === totalPages}
            >
                <NavigateNextIcon />
            </Button>
        </Stack >
    )
}