import { FC, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import HeaderRectangle from "./Header_rect.webp";
import SmallSearchBarIcon from "./../../../assets/salta-la-banca-redesign/header_redesign/Button_Small@2x.webp";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Balance } from "../userInfo/personalInfo/Balance";
import { ProfilePhoto } from "../userInfo/personalInfo/ProfilePhoto";
import { Logo } from "../logo/Logo";
import { useUser } from "../../features/user/useUser";
import { UserName } from "./UserName";
import { VioletButton } from "../violetButton/VioletButton";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Searchbar } from "../searchbar/Searchbar";
import { LoginModal } from "../login/LoginModal";
import { PATHS } from "../../features/navigation/paths";

interface HeaderProps {
  onHeightChange?: (height: number) => void;
}

export const Header: FC<HeaderProps> = ({ onHeightChange }) => {
  const {
    languagePack: {
      pack: {
        loginForm: loginLang,
        common
      },
    },
  } = useLanguage();
  const headerRef = useRef<HTMLDivElement>(null);
  const { xxSmallScreen, mediumScreen, smallScreen, largeScreen } =
    useCommonMediaQueries();
  const [loginWindowOpen, setLoginWindowOpen] = useState(false);
  const { token, user, refreshUserBalance } = useUser();
  const [justMounted, setJustMounted] = useState(true);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  useEffect(() => {
    if (justMounted && user && token) {
      refreshUserBalance();
      setJustMounted(false);
    }
  }, [justMounted, refreshUserBalance, token, user]);

  useEffect(() => {
    if (onHeightChange) {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        onHeightChange(height);
      }
    }
  }, [onHeightChange]);

  const horizontalRight =
    xxSmallScreen || smallScreen
      ? "-10px"
      : mediumScreen
        ? `${44 * 0.62}px`
        : largeScreen
          ? `${44 * 0.78}px`
          : "44px";

  const horizontalLeft = xxSmallScreen
    ? `${30 * 0.29}px`
    : smallScreen
      ? `${30 * 0.5}px`
      : mediumScreen
        ? `${30 * 0.62}px`
        : largeScreen
          ? `${30 * 0.78}px`
          : "30px";

  const buttonFontSize = xxSmallScreen
    ? "11px"
    : smallScreen
      ? "13px"
      : mediumScreen
        ? "14px"
        : largeScreen
          ? "14px"
          : "14px";;

  return (
    <>
      <Stack
        ref={headerRef}
        sx={{
          boxSizing: "border-box",
          borderBottom: "2px solid rgba(228, 233, 248, 0.1)",
          background: "rgb(5, 9, 30)",
          position: "relative",
          py: "10px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={"10px"}
        >
          <Box display="flex" alignItems="center" gap={6} marginLeft={0}>
            <Link to={PATHS.home}>
              <Logo />
            </Link>
            <Stack direction={"row"} gap={"10px"}>
              {/* Conditionally render Button_Small@2x.webp or SearchGameRD */}
              {xxSmallScreen || smallScreen || mediumScreen ? (
                <Box
                  component="img"
                  src={SmallSearchBarIcon} // Update the path to your image
                  alt="Search Button"
                  onClick={() => setIsSearchExpanded((prev) => !prev)}
                  sx={{
                    cursor: "pointer",
                    width: "40px", // Adjust the size as needed
                    height: "40px", // Adjust the size as needed
                    marginLeft: xxSmallScreen ? -3.5 : smallScreen ? -3 : 0,
                  }}
                />
              ) : (
                <Searchbar />
              )}
            </Stack>
          </Box>

          {/* HeaderRectangle (Divider) */}
          {!(token && user && (smallScreen || xxSmallScreen)) && (
            <Box
              component="img"
              src={HeaderRectangle}
              alt="Divider"
              sx={{
                width: "auto",
                height: "100%",
                alignSelf: "stretch",
                boxSizing: "border-box",
                position: "absolute",
                right: horizontalRight,
                top: "0px", // Align with the top padding
                zIndex: 0, // Ensure this is behind other elements
              }}
            />
          )}

          {token && user ? (
            smallScreen ? (
              <Stack direction="row" alignItems="center">
                <Box display="flex" alignItems="center" marginLeft={-3}>
                  <Balance
                    balance={user.user_balance}
                    currency={xxSmallScreen ? '' : user.user_currency!}
                    dark={false}
                    boxFontSize="14px"
                  />
                  <Link to={PATHS.dashboard}>
                    <IconButton disableRipple>
                      <ProfilePhoto size={45} />
                    </IconButton>
                  </Link>
                </Box>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
                sx={{ position: "relative", zIndex: 1, left: -20 }} // Ensure this is above the HeaderRectangle
              >
                {/* Balance and Username */}
                <Stack direction={"row"} alignItems={"center"} gap={4}>
                  <Balance
                    balance={user.user_balance}
                    currency={xxSmallScreen ? '' : user.user_currency!}
                    dark={false}
                  />
                  <UserName
                    text1={common.welcome + "!"}
                    text2={user.user_name}
                    dark={false}
                  />
                </Stack>

                {/* User Profile Photo */}
                <Box display={"flex"} alignItems={"center"}>
                  <Link to={PATHS.dashboard}>
                    <IconButton disableRipple>
                      <ProfilePhoto size={60} />{" "}
                    </IconButton>
                  </Link>
                </Box>
              </Stack>
            )
          ) : (
            <VioletButton
              onClick={() => setLoginWindowOpen(true)}
              textStyle={{
                fontSize: buttonFontSize,
                fontWeight: 600,
              }}
              sx={{
                marginRight: horizontalLeft,
                position: "relative",
                zIndex: 1,
              }} // Ensure this is above the HeaderRectangle
            >
              {loginLang.loginBTN}
            </VioletButton>
          )}
        </Stack>
      </Stack>

      {/* Render the expanded SearchGameRD component below the header */}
      {(xxSmallScreen || smallScreen || mediumScreen) && isSearchExpanded && (
        <Box
          sx={{
            position: "absolute",
            top: headerRef.current?.offsetHeight, // Position below the header
            left: 0,
            right: 0,
            zIndex: 1000, // Ensure it appears above other content
            boxSizing: "border-box",
            borderBottom: "2px solid rgba(228, 233, 248, 0.1)",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            padding: "10px",
            display: "flex",
            justifyContent: "center", // Center the SearchGameRD component
            backgroundColor: "rgb(5, 9, 30)",
          }}
        >
          <Searchbar />
        </Box>
      )}

      {loginWindowOpen && (
        <LoginModal close={() => setLoginWindowOpen(false)} />
      )}
    </>
  );
};
