import { FC } from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import { useLanguage } from "../../../features/localisation/useLanguage";
import HomeIcon from "../../../../assets/svg/HomeIcon";
import LobbyIcon from "../../../../assets/svg/LobbyIcon";
import CasinoIcon from "../../../../assets/svg/CasinoIcon";
import SportIcon from "../../../../assets/svg/SportIcon";
// import LiveBettingIcon from "../../../../assets/svg/LiveBettingIcon";
import LiveCasinoIcon from "../../../../assets/svg/LiveCasinoIcon";
import CrashGamesIcon from "../../../../assets/svg/CrashGamesIcon";
import TableGamesIcon from "../../../../assets/svg/TableGamesIcon";
import LegendsIcon from "../../../../assets/svg/LegendsIcon";
import BecomeAnAgentIcon from "../../../../assets/svg/BecomeAnAgentIcon";
import { useUser } from "../../../features/user/useUser";
import { MenuItem } from "../menuItem/MenuItem";
import MessageIcon from "../../../../assets/svg/MessageIcon";
import { LanguageSelector } from "../../languageSelector/LanguageSelector";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";
import { PATHS } from "../../../features/navigation/paths";

export const SideMenu: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: {
          dashboard: { sideMenu: landDashboardMenu },
        },
      },
    },
  } = useLanguage();
  const { currentPath } = useQuery()
  const { token, user } = useUser();

  return (
    <Box
      position={"sticky"}
      top={0}
      left={0}
      bottom={0}
      minWidth={"250px"}
      width={"250px"}
      maxWidth={"250px"}
      sx={{
        boxSizing: "border-box",
        border: "3px solid rgba(228, 233, 248, 0.02)",
        borderRadius: "20px",
        backdropFilter: "blur(30px)",
        background:
          "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 41.978%, rgba(40, 47, 79, 0.7) 99.305%)",
        position: "relative",
        width: "250px",
        height: token && user ? "81vh" : "86vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px",
        paddingTop: "10px",
        paddingBottom: "60px",
        overflowY: "auto", // Make the content inside the Box scrollable
        scrollbarWidth: "none", // Hide scrollbar for Firefox
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar for Chrome, Safari, and Opera
        },
      }}
    >
      <Stack
        justifyContent={"space-between"}
        height={"100%"}
        p={2}
        width='100%'
        position="relative"
      >
        {/* Scrollable Content */}
        <Stack
          gap={1}
          sx={{
            overflowY: "auto", // Enable scrolling
            flex: 1, // Take up remaining space
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari, and Opera
            },
          }}
        >
          {/* Menu Items */}
          <MenuItem
            icon={HomeIcon}
            text={landDashboardMenu.home}
            path={PATHS.home}
            active={currentPath.endsWith(PATHS.home)}
          />
          <MenuItem
            icon={LobbyIcon}
            text={landDashboardMenu.lobby}
            path={PATHS.lobby}
            active={currentPath.endsWith(PATHS.lobby)}
          />
          <MenuItem
            icon={CasinoIcon}
            text={landDashboardMenu.casino}
            path={PATHS.casino}
            active={currentPath.endsWith(PATHS.casino)}
          />
          <MenuItem
            icon={SportIcon}
            text={landDashboardMenu.sport}
            path={PATHS.sport}
            active={currentPath.endsWith(PATHS.sport)}
          />
          <MenuItem
            icon={LiveCasinoIcon}
            text={landDashboardMenu.liveCasino}
            path={PATHS.liveCasino}
            active={currentPath.endsWith(PATHS.liveCasino)}
          />
          <MenuItem
            icon={CrashGamesIcon}
            text={landDashboardMenu.crashGames}
            path={PATHS.crash}
            active={currentPath.endsWith(PATHS.crash)}
          />
          <MenuItem
            icon={TableGamesIcon}
            text={landDashboardMenu.tableGames}
            path={PATHS.tableGames}
            active={currentPath.endsWith(PATHS.tableGames)}
          />
          <MenuItem
            icon={LegendsIcon}
            text={landDashboardMenu.legends}
            path={PATHS.legends}
            active={currentPath.endsWith(PATHS.legends)}
          />

          <Divider />

          <MenuItem
            icon={BecomeAnAgentIcon}
            text={landDashboardMenu.becomeAnAgent}
            path={PATHS.becomeAnAgent}
            active={currentPath.endsWith(PATHS.becomeAnAgent)}
          />

          <MenuItem
            text={landDashboardMenu.userInfo}
            path={`${PATHS.dashboard}/${PATHS.userInfo}`}
            active={currentPath.endsWith(PATHS.userInfo)}
          />
          <MenuItem
            text={landDashboardMenu.gameHistory}
            path={`${PATHS.dashboard}/${PATHS.gameHistory}`}
            active={currentPath.endsWith(PATHS.gameHistory)}
          />
          <MenuItem
            text={landDashboardMenu.transactions}
            path={`${PATHS.dashboard}/${PATHS.transactions}`}
            active={currentPath.endsWith(PATHS.transactions)}
          />

          {/* <MenuItem
            text={landDashboardMenu.privacyPolicy}
            path={PATHS.policy}
            active={currentPath.endsWith(PATHS.policy)}
          /> */}
          <MenuItem
            text={landDashboardMenu.termsAndConditions}
            path={PATHS.terms}
            active={currentPath.endsWith(PATHS.terms)}
          />
          <MenuItem
            text={landDashboardMenu.aboutUs}
            path={PATHS.aboutUs}
            active={currentPath.endsWith(PATHS.aboutUs)}
          />
        </Stack>

        <Box
          sx={{
            position: "absolute",
            bottom: "-35px",
            left: "15px",
            right: "15px",
            padding: "0px 0px",
            zIndex: 1,
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              onClick={() => window.Tawk_API?.maximize?.()}
              sx={{
                boxSizing: "border-box",
                border: "1px solid rgba(228, 233, 248, 0.1)",
                borderRadius: "10px",
                background: "linear-gradient(180deg, rgb(28, 34, 63), rgb(40, 47, 79))",
                position: "static",
                width: "44px",
                height: "44px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
                margin: "0px 10px",
                minWidth: "unset",
              }}
            >
              <MessageIcon />
            </Button>
            <LanguageSelector fullWidth shortMode={false} />
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
