import { Box, Typography, Avatar } from "@mui/material";
import coinImage from "../../../../assets/salta-la-banca-redesign/user_section_redesign/Coin@2x.webp";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";

interface BalanceProps {
  balance: number;
  currency?: string;
  dark?: boolean;
  boxFontSize?: string;
  hideBackground?: boolean
  background?: string
  border?: string
  hideIcon?: boolean
}

export const Balance = ({
  balance,
  currency,
  dark = true,
  boxFontSize,
  hideBackground = false,
  background = dark
    ? "radial-gradient(117% 100% at 51% 100%, rgb(20, 25, 49), rgb(15, 19, 40) 56.5%, rgb(5, 9, 30) 100%)"
    : "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 41.978%, rgba(40, 47, 79, 0.7) 99.305%)",
  border = "1px solid rgba(228, 233, 248, 0.1)",
  hideIcon = false
}: BalanceProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
        border,
        ...(!hideBackground && {
          background,
          padding: "5px 10px",
        }),
        width: "fit-content", // Adjusts width to fit the content
        minWidth: "100px", // Minimum width to ensure it doesn't get too small
      }}
    >
      {!hideIcon &&
        <Avatar
          src={coinImage}
          variant="square"
          sx={{ width: 24, height: 24, marginRight: 1 }}
        />
      }
      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Typography
          sx={{ color: "white", fontWeight: 500, fontSize: boxFontSize }}
        >
          {convertCentsToCurrencyString(balance)}
        </Typography>
        {currency &&
          <Typography
            color={"white"}
            sx={{
              userSelect: "none",
              fontWeight: 500,
              fontSize: boxFontSize
            }}
          >
            {currency}
          </Typography>
        }
      </Box>
    </Box>
  );
};
