import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface DecoratedTitleProps {
  children?: ReactNode;
  fontSize?: string | number;
  xxSmallScreen?: boolean;
  smallScreen?: boolean;
}

export const DecoratedTitle: FC<DecoratedTitleProps> = ({ children, fontSize, xxSmallScreen, smallScreen }) => {
  return (
    <Box
      sx={{
        position: 'static',
        height: xxSmallScreen || smallScreen ? 'auto' : '29px', // Allow height to grow on xxSmall screens
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        padding: '20px 0px 20px 0px',
        flex: 'none',
        order: 1,
        flexGrow: 1,
        margin: '0px 20px',
        background: 'linear-gradient(180.00deg, rgb(228, 233, 248), rgb(139, 152, 190))',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        fontSize: fontSize || '29px', // Use the passed fontSize or fallback to '29px'
        fontWeight: 700,
        lineHeight: xxSmallScreen || smallScreen ? '1.2' : '100%', // Adjust lineHeight for xxSmall screens
        letterSpacing: '0%',
        textAlign: 'left',
        whiteSpace: xxSmallScreen || smallScreen ? 'normal' : 'nowrap', // Allow text to wrap on xxSmall screens
        maxWidth: xxSmallScreen || smallScreen ? "100px" : undefined,
      }}
    >
      <Typography
        sx={{
          fontSize: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          letterSpacing: 'inherit',
          textAlign: 'inherit',
          userSelect: 'none',
          whiteSpace: 'inherit', // Inherit whiteSpace from the parent Box
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};