import React from "react";
import { FC, useState, useRef, useEffect } from "react";
import { useGameSearch, minSearchTextLength } from "./useGameSearch";
import { FloatingContainer } from "./FloatingContainer";
import { Divider, Stack, Typography } from "@mui/material";
import { useClickOutside } from "./useClickOutside";
import CustomTextField from "./CustomTextField";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { GameCard } from "../gameCard/GameCard";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export const Searchbar: FC = () => {
  const {
    languagePack: {
      pack: { searchGameForm: lang },
    },
  } = useLanguage();

  const { smallScreen } = useCommonMediaQueries();
  const { games } = usePlayGames();
  const {
    searchText,
    foundGames,
    handleSearchTextChanged,
    setSearchText,
  } = useGameSearch(games);

  const inputRef = useRef<HTMLInputElement>(null);
  const [isResultsOpen, setIsResultsOpen] = useState(false);

  // Combine refs for click-outside detection
  const combinedRef = useRef<HTMLDivElement>(null);

  // Focus the input field when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Open the results container when the search text length is sufficient
  useEffect(() => {
    setIsResultsOpen(searchText.trim().length >= minSearchTextLength);
  }, [searchText]);

  // Close the FloatingContainer and reset the search when clicking outside
  useClickOutside(combinedRef, () => {
    setIsResultsOpen(false);
    setSearchText(""); // Always clear the search text, regardless of results
  });

  return (
    <div ref={combinedRef}>
      <CustomTextField
        value={searchText}
        onChange={handleSearchTextChanged}
        label={lang.searchGame}
        smallScreen={smallScreen}
        textColor="#FFFFFF"
        placeholderColor="rgba(174, 194, 255, 0.3)"
        inputRef={inputRef}
      />

      {/* Use the custom FloatingContainer for search results */}
      <FloatingContainer
        anchorEl={inputRef.current}
        open={isResultsOpen}
        foundGamesCount={foundGames.length}
        foundGames={foundGames}
        searchText={searchText}
      >
        <Stack gap={1}>
          {foundGames.length > 0 ? (
            foundGames.map((game, index) => (
              <React.Fragment key={game.internal_id}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={3}
                >
                  <GameCard
                    searchBarMode
                    width={'80px'}
                    game={game}
                  />
                  <Stack>
                    <Typography
                      variant="body1"
                      sx={{ cursor: "pointer" }}
                      fontWeight='bold'
                    >
                      {game.Name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: '#AEC2FF4D' }}
                    >
                      {game.provider_name}
                    </Typography>
                  </Stack>
                </Stack>
                {/* Add the divider box under each Stack except the last one */}
                {index < foundGames.length - 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))
          ) : (
            <Stack
              direction="column"
              alignItems="center"
              gap={1}
            >
              <SentimentVeryDissatisfiedIcon />
              <Typography
                variant="body2"
                sx={{
                  color: "rgb(228, 233, 248)",
                  textAlign: "center",
                }}
                fontWeight='bold'
              >
                {lang.notFound}
              </Typography>
            </Stack>
          )}
        </Stack>
      </FloatingContainer>
    </div>
  );
};
