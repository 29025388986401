import { FC, useEffect } from "react";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameHistoryTable } from "./GameHistoryTable";
import { useGamesHistoryWatcher } from "../../features/gameHistory/useGamesHistoryWatcher";
import { useAppDispatch } from "../../store";
import { clearGameHistory } from "../../features/gameHistory/gameHistorySlice";
import { GameHistoryCards } from "./GameHistoryCards";

export const GameHistory: FC = () => {
    const { xxSmallScreen } = useCommonMediaQueries();
    const dispatch = useAppDispatch();

    useGamesHistoryWatcher();

    useEffect(() => {
        return () => {
            dispatch(clearGameHistory());
        };
    }, [dispatch]);

    if (xxSmallScreen) return <GameHistoryCards />

    return <GameHistoryTable />
}