import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useTheme } from "@mui/material";

export const useAutoSize = (itemsCount: number, gap: number = 1) => {
    const { smallScreen: sm } = useCommonMediaQueries();
    const theme = useTheme();
    return sm
        ? "100px"
        : `calc((100% - (${theme.spacing(gap)} * ${itemsCount - 1})) / ${itemsCount})`;
};

export const useScalingWidth = (isForSearchBar: boolean = false) => {
    const { xxSmallScreen, smallScreen, xLargeScreen } = useCommonMediaQueries();

    // Define the base widths
    const maxWidth = isForSearchBar ? 80 : 180; // Width for xLargeScreen
    const minWidth = isForSearchBar ? 45 : 100; // Width for smallScreen and xxSmallScreen

    // Calculate the scaling width
    if (xxSmallScreen || smallScreen) {
        return `${minWidth}px`; // Fixed width for small screens
    } else if (xLargeScreen) {
        return `${maxWidth}px`; // Fixed width for xLargeScreen
    } else {
        // For screens between smallScreen and xLargeScreen, scale the width linearly
        const screenWidth = window.innerWidth; // Get the current screen width
        const scaleFactor = (screenWidth - 767) / (1536 - 767); // Normalize between smallScreen and xLargeScreen breakpoints
        const scaledWidth = minWidth + (maxWidth - minWidth) * scaleFactor; // Linear interpolation
        return `${Math.min(Math.max(scaledWidth, minWidth), maxWidth)}px`; // Clamp between minWidth and maxWidth
    }
};
