import { FC } from "react";
import CrashGamesCover from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Crash_games_cover.webp";
import CrashGamesCover2x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Crash_games_cover@2x.webp";
import CrashGamesCover3x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Crash_games_cover@3x.webp";
import SportCover from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Sport_cover.webp";
import SportCover2x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Sport_cover@2x.webp";
import SportCover3x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Sport_cover@3x.webp";
import DotsBackground from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Background_img@3x.webp";
import CasinoCover from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Casino_cover.webp";
import CasinoCover2x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Casino_cover@2x.webp";
import CasinoCover3x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Casino_cover@3x.webp";
import LiveCasinoCover from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Live_casino_cover.webp";
import LiveCasinoCover2x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Live_casino_cover@2x.webp";
import LiveCasinoCover3x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Live_casino_cover@3x.webp";
import TableGamesCover from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Table_games_cover.webp";
import TableGamesCover2x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Table_games_cover@2x.webp";
import TableGamesCover3x from "./../../../assets/salta-la-banca-redesign/section_covers_redesign/Table_games_cover@3x.webp";
import Stack from "@mui/material/Stack";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { CategoryCard } from "./CategoryCard";
import { PATHS } from "../../features/navigation/paths";

export const GameCategories: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: { headerMenu: menuLang, categoriesDescription: catDescLang },
      },
    },
  } = useLanguage();
  const { smallScreen } = useCommonMediaQueries();

  return (
    <Stack
      direction={"row"}
      width={"100%"}
      gap={smallScreen ? '10px' : '20px'}
      sx={{
        overflowX: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#15181D #15181D10",
      }}
    >
      <CategoryCard
        backgroundImgUrl={DotsBackground}
        imageUrl={{
          small: CasinoCover,
          medium: CasinoCover2x,
          large: CasinoCover3x,
        }}
        title={menuLang.casino}
        description={catDescLang.casino}
        url={PATHS.slots}
      />
      <CategoryCard
        backgroundImgUrl={DotsBackground}
        imageUrl={{
          small: SportCover,
          medium: SportCover2x,
          large: SportCover3x,
        }}
        title={menuLang.sport}
        description={catDescLang.sport}
        url={PATHS.sport}
      />
      <CategoryCard
        backgroundImgUrl={DotsBackground}
        imageUrl={{
          small: LiveCasinoCover,
          medium: LiveCasinoCover2x,
          large: LiveCasinoCover3x,
        }}
        title={menuLang.liveCasino}
        description={catDescLang.liveCasino}
        url={PATHS.liveCasino}
      />

      <CategoryCard
        backgroundImgUrl={DotsBackground}
        imageUrl={{
          small: CrashGamesCover,
          medium: CrashGamesCover2x,
          large: CrashGamesCover3x,
        }}
        title={menuLang.crash}
        description={catDescLang.crash}
        url={PATHS.crash}
      />
      <CategoryCard
        backgroundImgUrl={DotsBackground}
        imageUrl={{
          small: TableGamesCover,
          medium: TableGamesCover2x,
          large: TableGamesCover3x,
        }}
        title={menuLang.tableGames}
        description={catDescLang.tableGames}
        url={PATHS.tableGames}
      />
    </Stack>
  );
};
