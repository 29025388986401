import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import { useLanguage } from "./app/features/localisation/useLanguage";
import { usePlayGames } from "./app/features/play_games/usePlayGames";
import { HomePage } from "./app/pages/home/HomePage";
import { MediaqueryIndicator } from "./app/components/dev/MediaqueryIndicator";
import { GamesPage } from "./app/pages/casino/GamesPage";
import { SportPage } from "./app/pages/sport/SportPage";
import { GameLaunchPageRD } from './app/pages/game/GameLaunchPage';
import { LobbyPage } from './app/pages/lobby/LobbyPage';
import { PersonalAreaPage } from './app/pages/personalArea/PersonalAreaPage';
import { UserInfoPage } from './app/pages/personalArea/userInfo/UserInfo';
import { TransactionsPage } from './app/pages/personalArea/transactions/Transactions';
import { GamesHistoryPage } from './app/pages/personalArea/gameHistory/GameHistory';
import { AgeVerification } from './app/components/ageVerification/AgeVerification';
import { TermsAndConditionsPage } from './app/pages/siteInfo/termsAndConditions/TermsAndConditions';
import { BecomeAnAgentPage } from './app/pages/siteInfo/becomeAnAgent/BecomeAnAgentPage';
import { AboutUsPage } from './app/pages/siteInfo/aboutUs/AboutUsPage';
import { PrivacyPolicyPage } from './app/pages/siteInfo/privacyPolicy/PrivacyPolicy';
import { LegendsPage } from './app/pages/legends/LegendsPage';
import { PATHS } from './app/features/navigation/paths';
import { Messenger } from './app/components/messenger/Messenger';
import { SearchResultPage } from './app/pages/searchResult/searchResultPage';

export const App = () => {
    const { languagePack: { pack: { argentina: { headerMenu: menuLang } } } } = useLanguage()
    const { switchLanguageFromStore } = useLanguage()
    const [initLoad, setInitLoad] = useState<boolean>(true)
    const [initLoad2, setInitLoad2] = useState<boolean>(true)
    const { gameSession: { SessionUrl }, getGames, getProviders, providers, groupedGames } = usePlayGames()

    useEffect(() => {
        if (initLoad2) {
            if (groupedGames === null) {
                getGames()
            }
            if (providers === null) {
                getProviders()
            }
            setInitLoad2(false)
        }
    }, [getGames, getProviders, groupedGames, initLoad, initLoad2, providers])

    useEffect(() => {
        if (initLoad) {
            setInitLoad(false)
            switchLanguageFromStore()
        }
    }, [initLoad, switchLanguageFromStore])

    return <>
        <MediaqueryIndicator />
        <Routes>
            <Route path={PATHS.home} element={<HomePage />} />
            <Route path={PATHS.lobby} element={<LobbyPage />} />
            <Route path={PATHS.casino} element={<GamesPage gameType={'slots'} title={menuLang.casino} />} />
            <Route path={PATHS.sport} element={<SportPage />} />
            <Route path={PATHS.liveCasino} element={<GamesPage gameType={'live_casino'} title={menuLang.liveCasino} />} />
            <Route path={PATHS.crash} element={<GamesPage gameType={'crash'} title={menuLang.crash} />} />
            <Route path={PATHS.tableGames} element={<GamesPage gameType={'table_games'} title={menuLang.tableGames} />} />
            <Route path={PATHS.legends} element={<LegendsPage />} />

            <Route path={PATHS.becomeAnAgent} element={<BecomeAnAgentPage />} />
            <Route path={PATHS.policy} element={<PrivacyPolicyPage />} />
            <Route path={PATHS.terms} element={<TermsAndConditionsPage />} />
            <Route path={PATHS.aboutUs} element={<AboutUsPage />} />
            <Route path={PATHS.searchResults} element={<SearchResultPage />} />

            <Route path={PATHS.games} element={<GamesPage title={menuLang.slots} />} />
            <Route path={PATHS.slots} element={<GamesPage gameType={'slots'} title={menuLang.slots} />} />
            <Route path={PATHS.shooters} element={<GamesPage gameType={'shooter'} title={menuLang.shooters} />} />
            <Route path={PATHS.lounchGame} element={<GameLaunchPageRD />} />

            <Route path={PATHS.dashboard} element={<PersonalAreaPage />} >
                <Route index element={<Navigate to={PATHS.userInfo} />} />
                <Route path={PATHS.userInfo} element={<UserInfoPage />} />
                <Route path={PATHS.gameHistory} element={<GamesHistoryPage />} />
                <Route path={PATHS.transactions} element={<TransactionsPage />} />
            </Route>
        </Routes>

        <AgeVerification />

        <Messenger sessionUrl={SessionUrl} />
    </>
}

export default App;
