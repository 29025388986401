import { FC } from "react";
import { useUser } from "../../../features/user/useUser";
import { GameHistory } from "../../../components/gameHistory/GameHistory";
import { Stack } from "@mui/material";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { useGamesHistory } from "../../../features/gameHistory/useGamesHistory";
import { PageSizeSwitcher } from "../../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const GamesHistoryPage: FC = () => {
    const { user, token } = useUser()
    const { totalPages, currentPage, gameHistory } = useGamesHistory();
    const { smallScreen } = useCommonMediaQueries();
    if (!(user && token)) return null;

    return (
        <Stack alignItems='flex-end' gap={5}>
            <GameHistory />

            {!!gameHistory?.length &&
                <>
                    {smallScreen && <PageSizeSwitcher />}
                    <Paginator totalPages={totalPages} currentPage={currentPage} />

                </>
            }
        </Stack>
    )
}