import { FC } from "react";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";

import EnIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/english.png";
import EsIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/spanish.png";
import PtIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/portuguese.png";
import RuIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/russian.png";
import DeIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/german.png";
import FrIcon from "./../../../assets/salta-la-banca-redesign/langs_redesign/french.png";
import { Flag } from "./Flag";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Lang } from "../../features/localisation/useLanguageInterface";

export const LanguageSelector: FC<{ fullWidth?: boolean; shortMode?: boolean }> = ({
    fullWidth,
    shortMode = true,
}) => {
    const isDevMode = process.env.REACT_APP_ENVIRONMENT === "live";
    const {
        switchLanguage,
        languagePack: { lang },
    } = useLanguage();

    const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
        switchLanguage(event.target.value as Lang);
    };

    return (
        <FormControl
            fullWidth={fullWidth}
            size={"small"}
            variant={"outlined"}
            sx={{
                boxSizing: "border-box",
                border: "1px solid rgba(228, 233, 248, 0.1)",
                borderRadius: "10px",
                background: "linear-gradient(180deg, rgb(28, 34, 63), rgb(40, 47, 79))",
                position: "static",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0", // Remove padding to avoid centering
                minWidth: "unset",
                "& .MuiOutlinedInput-root": {
                    padding: 0, // Remove padding
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Space between flag/text and arrow
                    width: "100%", // Ensure it takes full width
                },
                "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start", // Align flag and text to the left
                    padding: "0 0 0 8px", // Add left padding only for flag and text
                    flexGrow: 1, // Allow the flag and text to take up available space
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
                "& .MuiSelect-icon": {
                    marginLeft: "auto", // Move the dropdown arrow to the right
                    paddingRight: "8px", // Add right padding for the arrow
                },
            }}
        >
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={handleLanguageChange}
                displayEmpty
                MenuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: "12px",
                            "& .MuiMenuItem-root": {
                                color: "grey.400",
                            },
                        },
                    },
                }}
            >
                <MenuItem value={Lang.EN}>
                    <Box display={"flex"} alignItems="center">
                        <Flag imageUrl={EnIcon} />
                        <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                            {!shortMode && "ENG"}
                        </Box>
                    </Box>
                </MenuItem>
                <MenuItem value={Lang.ES}>
                    <Box display={"flex"} alignItems="center">
                        <Flag imageUrl={EsIcon} />
                        <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                            {!shortMode && "ESP"}
                        </Box>
                    </Box>
                </MenuItem>
                <MenuItem value={Lang.PT}>
                    <Box display={"flex"} alignItems="center">
                        <Flag imageUrl={PtIcon} />
                        <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                            {!shortMode && "POR"}
                        </Box>
                    </Box>
                </MenuItem>
                {isDevMode && (
                    <MenuItem value={Lang.RU}>
                        <Box display={"flex"} alignItems="center">
                            <Flag imageUrl={RuIcon} />
                            <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                                {!shortMode && "РУС"}
                            </Box>
                        </Box>
                    </MenuItem>
                )}
                {isDevMode && (
                    <MenuItem value={Lang.DE}>
                        <Box display={"flex"} alignItems="center">
                            <Flag imageUrl={DeIcon} />
                            <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                                {!shortMode && "DEU"}
                            </Box>
                        </Box>
                    </MenuItem>
                )}
                {isDevMode && (
                    <MenuItem value={Lang.FR}>
                        <Box display={"flex"} alignItems="center">
                            <Flag imageUrl={FrIcon} />
                            <Box component="span" sx={{ marginLeft: "4px", color: "rgb(228, 233, 248)" }}> {/* Set text color */}
                                {!shortMode && "FRA"}
                            </Box>
                        </Box>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};