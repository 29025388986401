import React, { useEffect, useState, FC } from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../features/localisation/useLanguage";
import { myCommonButtonProps } from "../../pages/siteInfo/general_styles";
import { PATHS } from "../../features/navigation/paths";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";

interface FloatingContainerProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
  foundGamesCount: number;
  foundGames: any[];
  searchText: string
}

export const FloatingContainer: FC<FloatingContainerProps> = ({ anchorEl, open, children, foundGamesCount, foundGames, searchText }) => {
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const navigate = useNavigate();
  const { smallScreen, xxSmallScreen } = useCommonMediaQueries();
  const { currentPath } = useQuery();

  const {
    languagePack: {
      pack: { searchGameForm: lang },
    },
  } = useLanguage();

  useEffect(() => {
    if (anchorEl && open) {
      const rect = anchorEl.getBoundingClientRect();
      setPosition({
        top: 88,//rect.bottom + window.scrollY + topOffset,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [anchorEl, open]);

  const theme = useTheme();

  if (!open) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: position.top,
        left: position.left,
        width: position.width,
        ...(smallScreen && {
          width: "100%",
          left: 0,
          top: 48
        }),
        zIndex: 9999,
      }}
    >
      <Paper
        sx={{
          mt: 1,
          p: 2,
          maxHeight: xxSmallScreen || smallScreen ? "53vh" : "70vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "rgb(5, 9, 30)",
          boxShadow: 3,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderWidth: "0 2px 2px 2px",
          borderStyle: "solid",
          borderColor: "rgba(228, 233, 248, 0.1)",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {children}
        </Box>

        {/* Footer with "Results" and "See all" button */}
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            mt: 2,
            backgroundColor: "rgb(5, 9, 30)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "rgb(228, 233, 248)",
            }}
            fontWeight='bold'
          >
            {lang.results} {foundGamesCount}
          </Typography>

          {foundGamesCount > 1 && (
            <Button
              {...myCommonButtonProps}
              variant="text"
              sx={{
                color: "rgb(228, 233, 248)",
                textTransform: "none",
                ...myCommonButtonProps.sx,
                px: theme.spacing(4),
                m: 0
              }}
              onClick={() => {
                navigate(PATHS.searchResults, { state: { games: foundGames, searchText, from: currentPath } });
              }}
            >
              {lang.seeAll}
            </Button>
          )}
        </Box>
      </Paper>
    </Box>
  );
}