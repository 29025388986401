import { FC } from "react";
import { useGamesHistory } from "../../features/gameHistory/useGamesHistory";
import { useUser } from "../../features/user/useUser";
import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { getCurrentDomain, getMediaCDN } from "../../config";
import { useAppSelector } from "../../store";
import { GameObj } from "../../features/play_games/playGamesModels";
import { Balance } from "../userInfo/personalInfo/Balance";
import { useLanguage } from "../../features/localisation/useLanguage";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

export const GameHistoryCards: FC = () => {
    const { gameHistory } = useGamesHistory();
    const { user } = useUser();
    const {
        languagePack: {
            pack: {
                gamesHistory: gamesHistoryTranslations,
            },
        },
    } = useLanguage();

    const gamePicByName = useAppSelector((state) => {
        const res: { [key: GameObj['Name']]: GameObj['Pic'] } = {};
        if (!state.playGames.games) return {};
        for (let game of state.playGames.games) {
            res[game.Name] = game.Pic;
        }
        return res
    })

    const domain = getCurrentDomain();
    const cdn = getMediaCDN();

    if (!user) return null;

    if (!gameHistory?.length) return (
        <Stack gap={2} width='100%' alignItems='center' >
            <SentimentVeryDissatisfiedIcon fontSize='large' sx={{ color: '#AEC2FF4D' }} />
            <Typography fontWeight='bold'>There is no game history here yet</Typography>
        </Stack>
    )

    return (
        <Stack gap='10px' width='100%'>
            {
                gameHistory?.map(game => (
                    <Card
                        key={game.uuid}
                        sx={{
                            p: '20px',
                            width: '100%',
                            background: "linear-gradient(270deg, #1C223F 0%, #141931 100%)"
                        }}
                    >
                        <Stack gap='10px'>
                            <Typography fontWeight='bold' variant="caption">{game.time}</Typography>
                            <Stack gap='10px' direction='row' justifyContent='space-between'>
                                <Box width='100%'>
                                    <Stack gap='10px'>
                                        <Stack>
                                            <Typography color=' #AEC2FF4D' variant="caption">{gamesHistoryTranslations.providerTableHeader}:</Typography>
                                            <Stack direction='row' gap={1.5} whiteSpace='nowrap'>
                                                <img src={`${domain}/cdn/provider_img/hor/${game.provider_name}.png`} height='30' alt={game.provider_name} />
                                                <Typography fontWeight='bold'>
                                                    {game.provider_name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack gap='4px'>
                                            <Typography color=' #AEC2FF4D' variant="caption">{gamesHistoryTranslations.betAmountTableHeader}:</Typography>
                                            <Balance
                                                balance={game.withdraw_sum!}
                                                currency={user.user_currency!}
                                                background='transparent'
                                                border='none'
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Divider orientation="vertical" sx={{ height: 'initial' }} />
                                <Box width='100%'>
                                    <Stack gap='10px'>
                                        <Stack>
                                            <Typography color=' #AEC2FF4D' variant="caption">{gamesHistoryTranslations.gameTableHeader}:</Typography>
                                            <Stack direction='row' gap={1.5} alignItems='center' whiteSpace='nowrap'>
                                                <img src={`${cdn}/game_img/sqr_mid/${gamePicByName[game.game_name]}`} height='30' style={{ borderRadius: 6 }} alt={game.game_name} />
                                                <Typography fontWeight='bold'>
                                                    {game.game_name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack gap='4px'>
                                            <Typography color=' #AEC2FF4D' variant="caption">{gamesHistoryTranslations.winAmountTableHeader}:</Typography>
                                            <Balance
                                                balance={game.deposit_sum!}
                                                currency={user.user_currency!}
                                                background='#282F4F'
                                                border='none'
                                            />
                                        </Stack>

                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Card>
                ))
            }

        </Stack >
    );
};