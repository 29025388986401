import { useEffect, useRef, useState } from "react";
import { useLanguage } from "../localisation/useLanguage";
import { useUser } from "./useUser";
import { User } from "./user";
import { validateEmail } from "../common_funcs";

export const useUpdateUserInfo = () => {
    const {
        languagePack: {
            pack: { userInfoForm },
        },
    } = useLanguage();
    const {
        user,
        updateUserInfo,
        changePassword,
        isUserInfoUpdateLoading,
        isChangePasswordLoading,
    } = useUser();

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [retypeNewPasswordError, setRetypeNewPasswordError] = useState("");

    const [email, setEmail] = useState<User["user_email"]>(user?.user_email || "");
    const [phone, setPhone] = useState<User["user_phone"]>(user?.user_phone || "");
    const [firstname, setFirstname] = useState<User["user_firstname"]>(user?.user_firstname || "");
    const [lastname, setLastname] = useState<User["user_lastname"]>(user?.user_lastname || "");

    const [currentPassword, setCurrentPassword] = useState<string | undefined>();
    const [newPassword, setNewPassword] = useState<string | undefined>();
    const [retypeNewPassword, setRetypeNewPassword] = useState<string | undefined>();

    useEffect(() => {
        if (user && user !== prevUser.current) {
            setEmail(user.user_email || "");
            setPhone(user.user_phone || "");
            setFirstname(user.user_firstname || "");
            setLastname(user.user_lastname || "");

            prevUser.current = user;
        }
    }, [user]);

    useEffect(() => {
        if (
            (currentPassword !== undefined && currentPassword.trim() === "") ||
            (currentPassword === undefined && (retypeNewPassword || newPassword))
        ) {
            setPasswordError(userInfoForm.emptyFieldWarningMessage);
        } else setPasswordError("");

        if (
            (newPassword !== undefined && newPassword.trim() === "") ||
            (newPassword === undefined && (currentPassword || retypeNewPassword))
        ) {
            setNewPasswordError(userInfoForm.emptyFieldWarningMessage);
        } else {
            setNewPasswordError("");
        }

        if (
            ((newPassword || currentPassword) && retypeNewPassword === undefined) ||
            (retypeNewPassword !== undefined && retypeNewPassword.trim() === "")
        ) {
            setRetypeNewPasswordError(userInfoForm.emptyFieldWarningMessage);
        } else if (newPassword && retypeNewPassword && newPassword !== retypeNewPassword) {
            setRetypeNewPasswordError(userInfoForm.passNotMatchWarningMessage);
        } else {
            setRetypeNewPasswordError("");
        }

        const emailValidationResult = validateEmail(
            email as string,
            userInfoForm.emailIsRequiredMess,
            userInfoForm.notValidEmail
        );
        setEmailError(emailValidationResult);
    }, [
        currentPassword,
        newPassword,
        retypeNewPassword,
        email,
        userInfoForm.emptyFieldWarningMessage,
        userInfoForm.passNotMatchWarningMessage,
        userInfoForm.emailIsRequiredMess,
        userInfoForm.notValidEmail,
    ]);

    const prevUser = useRef<User | null>(null);

    const handleSaveInfo = () => {
        if (user) {
            const updatedUser: User = {
                ...user,
                user_email: email,
                user_phone: phone,
                user_firstname: firstname,
                user_lastname: lastname,
            };

            updateUserInfo(updatedUser);
        }
    };

    const handleUpdatePassword = () => {
        if (Boolean(currentPassword) && Boolean(newPassword) && Boolean(retypeNewPassword)) {
            changePassword(currentPassword as string, newPassword as string, () => {
                setCurrentPassword(undefined);
                setNewPassword(undefined);
                setRetypeNewPassword(undefined);
            });
        }
    };

    return {
        userInfoForm,
        emailError,
        passwordError,
        newPasswordError,
        retypeNewPasswordError,
        email,
        phone,
        firstname,
        lastname,
        currentPassword,
        newPassword,
        retypeNewPassword,
        handleSaveInfo,
        handleUpdatePassword,
        isUserInfoUpdateLoading,
        isChangePasswordLoading,
        setEmail,
        setEmailError,
        setPhone,
        setLastname,
        setFirstname,
        setCurrentPassword,
        setNewPassword,
        setRetypeNewPassword,
    };
};
