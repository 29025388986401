// Called models in oropuro

import { ApiResponse } from "../ApiErrors/apiResponseModels";

export type GameType =
  | "slots"
  | "crash"
  | "table_games"
  | "shooter"
  | "live_casino"
  | "casino"
  | "virtuals";
export interface GameProvider {
  provider_name: string;
  title?: string | null;
  logo?: string | null;
}

export interface GameObj {
  Name: string;
  internal_id: number;
  external_id: string;
  Pic: string | null;
  GameRang: string | null;
  provider_name: string;
  game_type: string;
}

export interface ProviderGroup {
  provider: GameProvider;
  games: GameObj[];
  gamesCount: number;
}

export interface OutcomeGameSession {
  SessionId: string | null;
  SessionUrl: string | null;
}
export type SportType = "live" | "";

export interface PlayGamesState {
  gameType: string | null;
  providers: GameProvider[] | null;
  providersFiltered: GameProvider[] | null; // only providers which have games
  selectedProvider: string | null;
  games: GameObj[] | null;
  groupedGames: ProviderGroup[] | null; // games grouped by provider
  gameSession: OutcomeGameSession;
  sportUrl: string | null;

  isProvidersOutcomeLoading: boolean;
  isOutcomeGameLoading: boolean;
  isGameSessionLoading: boolean;
  isSportsBookLoading: boolean;
}

export enum PlayGamesLoader {
  isProvidersOutcomeLoading,
  isOutcomeGameLoading,
  isGameSessionLoading,
  isSportsBookLoading,
}

export interface iUsePlayGames extends PlayGamesState {
  getProviders: (onSuccess?: () => void) => void;
  getGames: (providerName?: string) => void;
  startGameSession: (gameId: number) => void;
  // openSportsBook: () => void
  openSportsBook: (extension?: SportType) => void;
}

export interface ShowProvidersResponse extends ApiResponse {
  providers?: GameProvider[];
}

export interface GetGamesResponse extends ApiResponse {
  games?: GameObj[];
}

export interface StartGameSessionResponse extends ApiResponse {
  SessionId?: string;
  SessionUrl?: string;
}

export interface OpenSportsBookResponse extends ApiResponse {
  url?: string;
}
