import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC } from "react";
import { useGamesHistory } from "../../features/gameHistory/useGamesHistory";
import { useUser } from "../../features/user/useUser";
import { Stack, Typography } from "@mui/material";
import { getCurrentDomain, getMediaCDN } from "../../config";
import { useAppSelector } from "../../store";
import { GameObj } from "../../features/play_games/playGamesModels";
import { useLanguage } from "../../features/localisation/useLanguage";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Balance } from "../userInfo/personalInfo/Balance";

export const GameHistoryTable: FC = () => {
  const { gameHistory } = useGamesHistory();
  const { user } = useUser();
  const {
    languagePack: {
      pack: {
        gamesHistory: gamesHistoryTranslations,
      },
    },
  } = useLanguage();

  const gamePicByName = useAppSelector((state) => {
    const res: { [key: GameObj['Name']]: GameObj['Pic'] } = {};
    if (!state.playGames.games) return {};
    for (let game of state.playGames.games) {
      res[game.Name] = game.Pic;
    }
    return res
  })

  const domain = getCurrentDomain();
  const cdn = getMediaCDN();

  if (!user) return null;

  return (
    <Stack width='100%' gap={4}>

      <TableContainer >
        <Table
          sx={{
            minWidth: 700,
            borderSpacing: "0 6px",
            borderCollapse: "separate",
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: '#AEC2FF4D' }}>
                <Typography fontWeight='bold'>
                  {gamesHistoryTranslations.providerTableHeader}
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#AEC2FF4D' }}>
                <Typography fontWeight='bold'>
                  {gamesHistoryTranslations.gameTableHeader}
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#AEC2FF4D' }}>
                <Typography fontWeight='bold'>
                  {gamesHistoryTranslations.betAmountTableHeader}
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#AEC2FF4D' }}>
                <Typography fontWeight='bold'>
                  {gamesHistoryTranslations.winAmountTableHeader}
                </Typography>
              </TableCell>
              <TableCell sx={{ color: '#AEC2FF4D' }}>
                <Typography fontWeight='bold'>
                  {gamesHistoryTranslations.dateTimeTableHeader}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameHistory?.map((game) => (
              <TableRow key={game.uuid}>
                <TableCell>
                  <Stack direction='row' gap={1.5} whiteSpace='nowrap'>
                    <img src={`${domain}/cdn/provider_img/hor/${game.provider_name}.png`} width='40' alt={game.provider_name} />
                    <Typography fontWeight='bold'>
                      {game.provider_name}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell >
                  <Stack direction='row' gap={1.5} alignItems='center' whiteSpace='nowrap'>
                    <img src={`${cdn}/game_img/sqr_mid/${gamePicByName[game.game_name]}`} width='30' style={{ borderRadius: 6 }} alt={game.game_name} />
                    <Typography fontWeight='bold'>
                      {game.game_name}
                    </Typography>
                  </Stack>

                </TableCell>
                <TableCell>
                  <Balance
                    balance={game.withdraw_sum!}
                    currency={user.user_currency!}
                    hideBackground
                    border='none'
                  />
                </TableCell>
                <TableCell>
                  <Balance
                    balance={game.deposit_sum!}
                    currency={user.user_currency!}
                    background='#282F4F'
                    border='none'
                  />
                </TableCell>
                <TableCell>
                  <Typography fontWeight='bold' whiteSpace='nowrap'>
                    {game.time}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
            {!gameHistory?.length &&
              <TableRow>
                {new Array(5).fill('').map((_, index) => (
                  <TableCell key={index}>
                    <Typography fontWeight='bold'>-</Typography>
                  </TableCell>
                ))}
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      {!gameHistory?.length &&
        <Stack gap={2} width='100%' alignItems='center' >
          <SentimentVeryDissatisfiedIcon fontSize='large' sx={{ color: '#AEC2FF4D' }} />
          <Typography fontWeight='bold'>There is no game history here yet</Typography>
        </Stack>
      }
    </Stack>
  );
};
