import { FC } from "react";
import { PATHS } from "../../features/navigation/paths";
import { GameObj } from "../../features/play_games/playGamesModels";
import { getMediaCDN } from "../../config";
import { alpha, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link } from "react-router-dom";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useSnackbar } from "notistack";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { TopGameHolder } from "../../features/games/game";

interface GameCardProps {
    disableLink?: boolean
    width: string
    game: GameObj | TopGameHolder
    searchBarMode?: boolean
}

export const GameCard: FC<GameCardProps> = ({ width, game, searchBarMode = false, disableLink = false }) => {
    const cdn = getMediaCDN();
    const picUrl = `${cdn}/game_img/sqr_mid/${"internal_id" in game ? game.Pic : game.gamePic}`;
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries();
    const {
        languagePack: {
            pack: {
                argentina: { common: lang },
            },
        },
    } = useLanguage();
    const { enqueueSnackbar } = useSnackbar();
    const { isGameSessionLoading, startGameSession } = usePlayGames();

    const handleGameClick = (game: GameObj | TopGameHolder) => {
        if (!isGameSessionLoading) {
            startGameSession("internal_id" in game ? game.internal_id : game.gameID);
            return;
        }

        enqueueSnackbar("Login required", { variant: "warning" });
    };

    const gameLink = disableLink ? undefined : `${PATHS.lounchGame}?internalId=${"internal_id" in game ? game.internal_id : game.gameID}`;
    const onClick = () => handleGameClick(game);
    const title = "internal_id" in game ? game.Name : '';

    return (
        <Box
            {...(gameLink !== undefined ? { component: Link, to: gameLink } : {})}
            onClick={onClick}
            width={width}
            minWidth={width}
            sx={(theme) => ({
                cursor: "pointer",
                backgroundColor: theme.palette.background.paper,
                position: "relative",
                borderRadius: "20px",
                aspectRatio: "1/1.3",
                overflow: "hidden",
                // border: mobileMode
                //   ? `2px solid ${theme.palette.primary.main}`
                //   : undefined,
                "&:hover .overlay": {
                    opacity: 1,
                    visibility: "visible",
                },
                "&:before": {
                    content: `'${title}'`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: theme.typography.pxToRem(12),
                    color: alpha("#fff", 0.6),
                },
            })}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${picUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <Box
                borderRadius={"inherit"}
                className="overlay"
                position={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                    backdropFilter: "blur(8px)",
                    backgroundColor: alpha("#000", 0.3),
                    opacity: 0,
                    visibility: "hidden",
                    transition: "opacity 0.3s ease, visibility 0.3s ease",
                    pb: "10%",
                    position: "relative", // Required for pseudo-element positioning
                    overflow: "hidden", // Ensure the pseudo-element doesn't overflow the border radius
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1, // Place the pseudo-element behind the content
                        borderRadius: "inherit", // Match the border radius of the Box
                        padding: "2px", // Simulate the border width
                        background: "linear-gradient(180deg, #B431AD, #D43DCC 100%)", // Gradient border
                        mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)", // Mask to create the border effect
                        maskComposite: "exclude", // Exclude the inner content from the gradient
                    },
                }}
            >
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"15px"}
                    sx={{
                        p: (theme) => xxSmallScreen || smallScreen || searchBarMode ? 0 : theme.spacing(1, 1.5),
                        backgroundImage: "linear-gradient(180deg, #B431AD, #D43DCC 100%)",
                        color: "black",
                        gap: xxSmallScreen || smallScreen || searchBarMode ? 0 : 0.5,
                        position: "relative",
                        overflow: "hidden",
                        width: xxSmallScreen || smallScreen || searchBarMode ? "40px" : "auto",
                        height: xxSmallScreen || smallScreen || searchBarMode ? "40px" : "auto",
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            border: "2px solid",
                            borderColor: alpha("#D43DCC", 0.6),
                            borderRadius: "15px",
                            pointerEvents: "none",
                        },
                    }}
                >
                    <PlayArrowIcon sx={{ color: "rgb(228, 233, 248)" }} />
                    {!searchBarMode &&
                        <Typography
                            sx={{
                                color: "rgb(228, 233, 248)",
                                fontWeight: 600,
                                display: xxSmallScreen || smallScreen || searchBarMode ? "none" : "block",
                            }}
                        >
                            {lang.playGame}
                        </Typography>
                    }
                </Box>
                {!searchBarMode &&
                    <Typography
                        sx={{
                            position: "absolute",
                            bottom: "10%",
                            left: 0,
                            right: 0,
                            textAlign: "center",
                        }}
                    >
                        {title}
                    </Typography>
                }
            </Box>
        </Box>
    )
};
