import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useLanguage } from "../../features/localisation/useLanguage";
import TopProvidersIcon from "../../../assets/svg/TopProvidersIcon";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { TopWins } from "../../components/topWins/TopWins";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { GameObj } from "../../features/play_games/playGamesModels";
import { GameCard } from "../../components/gameCard/GameCard";
import DirectionalIconButton from "../../components/directionalIconButton/DirectionalIconButton";
import { ProvidersListSection } from "../../components/providersList/ProvidersListSection";

export const SearchResultPage: FC = () => {
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries();
    const location = useLocation();
    const { games, searchText, from } = location.state as { games: GameObj[], searchText: string, from: string };
    const navigate = useNavigate();

    const itemsPerRow = xxSmallScreen ? 3 : smallScreen ? 4 : 9;
    const remainingItems = itemsPerRow - (games.length % itemsPerRow);
    const width = smallScreen ? '100px' : '180px';
    const emptyBlocks = Array.from({ length: remainingItems }, (_, index) => (
        <Box key={`empty-${index}`} minWidth={width} />
    ));

    const {
        languagePack: {
            pack: {
                argentina: { common: lang },
                searchGameForm
            },
        },
    } = useLanguage();

    return (
        <PageLayout pageName={"Home"} carouselDisabled>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
                <DirectionalIconButton direction='left' onClick={() => navigate(from, { replace: true })} />
                <Typography>
                    "{searchText}" &nbsp; {searchGameForm.searchResults}
                </Typography>
            </Stack>

            <Stack gap={xxSmallScreen ? 1 : 2}>

                <Stack
                    direction={"row"}
                    flexWrap={"wrap"}
                    justifyContent={"space-evenly"}
                    gap={xxSmallScreen ? 1 : 2}
                    sx={{
                        marginTop: "24px",
                        marginBottom: "24px",
                    }}
                >
                    {games.map((game) => (
                        <GameCard
                            width={width}
                            key={game.internal_id}
                            game={game}
                        />
                    ))}
                    {emptyBlocks}
                </Stack>

                <ProvidersListSection
                    icon={<TopProvidersIcon fontSize="large" fill="#B431AD" />}
                    title={lang.topProviders}
                />

                <TopWins />
            </Stack>
        </PageLayout>
    );
};
