import { FC } from "react";
import { ConfirmationDialog } from "../../components/common/ConfirmationDialog";
import { useUser } from "../../features/user/useUser";
import { useLanguage } from "../../features/localisation/useLanguage";

interface LogOutProps {
    close?: () => void
}

export const LogOutDialog: FC<LogOutProps> = ({ close = () => { } }) => {
    const { languagePack: { pack: { dashboardMenu: lang } } } = useLanguage()
    const { logOut } = useUser()

    return (
        <ConfirmationDialog
            close={close}
            message={lang.logOutConfirmationMessage}
            onConfirm={logOut}
        />
    )
}