import { FC } from "react";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import DirectionalIconButton from "../directionalIconButton/DirectionalIconButton";

interface ImageSet {
  small: string;
  medium: string;
  large: string;
}

interface SectionCardProps {
  imageUrl: ImageSet;
  backgroundImgUrl?: string;
  title: string;
  description: string;
  url: string;
}

export const CategoryCard: FC<SectionCardProps> = ({
  imageUrl,
  title,
  description,
  url,
}) => {
  const { smallScreen } =
    useCommonMediaQueries();
  const image = imageUrl.large

  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <Box
        height={smallScreen ? '170px' : '310px'}
        display='flex'
        alignItems='flex-end'
        overflow='hidden'

      >
        <Stack
          sx={{
            height: smallScreen ? '114px' : '220px',
            width: smallScreen ? '140px' : '290px',
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #141931 0%, #1C223F 100%)',
            boxShadow: 'inset 0 0 0 4px #E4E9F80D',
          }}
          alignItems='center'
        >
          <Box mt={smallScreen ? '-40px' : "-60px"}>
            <img src={image} height={smallScreen ? '100' : '145'} alt={title} />
          </Box>
          <Stack p={smallScreen ? '10px' : '20px'} pt="0" gap={1} width='100%'>
            <Typography variant={smallScreen ? "body1" : "h5"} fontWeight='bold' textAlign='center'>{title}</Typography>
            {!smallScreen &&
              <Stack direction='row' gap={5}>
                <Typography variant="body1" color='#AEC2FF4D' fontWeight='bold' lineHeight='1.2'>{description}</Typography>
                <DirectionalIconButton
                  direction={"right"}
                />
              </Stack>
            }
          </Stack>
        </Stack>
      </Box>
    </Link>
  );
};
