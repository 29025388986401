import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { FC } from "react";

export type ShowPasswordState = {
  current: boolean;
  new: boolean;
  confirm: boolean;
};

export type PasswordsState = {
  current: string;
  new: string;
  confirm: string;
};

interface PasswordFieldProps {
  label: string;
  placeholder?: string;
  value: string
  showPassword: boolean;
  onChange: (
    value: string
  ) => void;
  toggleShowPassword: () => void;
  error: string;
  smallScreen?: boolean
}

export const PasswordField: FC<PasswordFieldProps> = ({
  label,
  value,
  showPassword,
  onChange,
  toggleShowPassword,
  error,
  placeholder,
  smallScreen = false
}) => (
  <Box sx={{ mb: 2 }} height='100%'>
    <Typography sx={{ color: "white", mb: 1 }}>{label}</Typography>
    <TextField
      fullWidth
      type={showPassword ? "text" : "password"}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleShowPassword}>
              {showPassword ? (
                <Visibility sx={{ color: "white" }} />
              ) : (
                <VisibilityOff sx={{ color: "white" }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
        sx: {
          boxSizing: "border-box",
          border: "1px solid rgba(228, 233, 248, 0.1)",
          borderRadius: "10px",
          background:
            "radial-gradient(117% 100% at 51% 100%, rgb(20, 25, 49), rgb(15, 19, 40) 56.5%, rgb(5, 9, 30) 100%)",
          color: "white",
          width: "100%",
          ...(smallScreen && {
            height: '40px',
            padding: '0 !important'
          })
        },
      }}
      sx={{ padding: '0 !important' }}
      {...(error && {
        error: true,
        helperText: error
      })}
      placeholder={placeholder}
    />
  </Box>
);
