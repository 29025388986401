import { Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { PageLayout } from "../../components/pageLayout/PageLayout";
import LogoutIcon from '@mui/icons-material/Logout';
import { PATHS } from "../../features/navigation/paths";
import { PageSizeSwitcher } from "../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useState } from "react";
import { LogOutDialog } from "../../components/logoutDialog/LogoutDialog";
import { useLanguage } from "../../features/localisation/useLanguage";

export const PersonalAreaPage = () => {
    const [showLoagoutDialog, setShowLogoutDialog] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { smallScreen } = useCommonMediaQueries();
    const {
        languagePack: {
            pack: {
                common,
                argentina: { dashboard: { loggedInUserMenu } }
            },
        },
    } = useLanguage();

    const selected = {
        [PATHS.userInfo]: PATHS.userInfo,
        [PATHS.gameHistory]: PATHS.gameHistory,
        [PATHS.transactions]: PATHS.transactions
    }[location.pathname.split('/')[2] || PATHS.userInfo]

    return (
        <PageLayout carouselDisabled>
            <Stack direction='column' spacing={3}>
                <Typography variant="h4">{common.userProfile}</Typography>
                <Stack direction='row' justifyContent='space-between' height='56px'>
                    <ToggleButtonGroup
                        value={selected}
                        exclusive
                        onChange={(_, path) => navigate(path)}
                    >
                        {[[loggedInUserMenu.userInfo, PATHS.userInfo], [loggedInUserMenu.userGameHistory, PATHS.gameHistory], [loggedInUserMenu.userTransactions, PATHS.transactions]].map(([title, path]) => (
                            <ToggleButton
                                value={path}
                                selected={selected === path}
                                sx={smallScreen ? { fontSize: '12px' } : {}}
                                key={title}
                            >
                                {title}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    {
                        !smallScreen &&
                        <>
                            {selected === PATHS.userInfo ?
                                <Button
                                    variant="contained"
                                    startIcon={<LogoutIcon />}
                                    sx={{ background: (theme) => theme.palette.background.paper, color: 'white', border: '1px solid #E4E9F81A' }}
                                    onClick={() => setShowLogoutDialog(true)}
                                >
                                    {loggedInUserMenu.userLogOut}
                                </Button>
                                :
                                <PageSizeSwitcher />
                            }
                        </>
                    }

                </Stack>

                {
                    showLoagoutDialog && <LogOutDialog close={() => setShowLogoutDialog(false)} />
                }

                <Outlet context={{ logOut: () => setShowLogoutDialog(true) }} />
            </Stack>
        </PageLayout >
    )
}