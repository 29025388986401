import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { TopGamesSection } from "../../components/games/topGamesSection/TopGamesSection";
import { useLanguage } from "../../features/localisation/useLanguage";
import CasinoIcon from "../../../assets/svg/CasinoIcon";
import TopProvidersIcon from "../../../assets/svg/TopProvidersIcon";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { Link } from "react-router-dom";
import { PATHS } from "../../features/navigation/paths";
import casinoBg from './../../../assets/category-card-background/casino.svg'
import liveCasinoBg from './../../../assets/category-card-background/live_casino.svg'
import crashBg from './../../../assets/category-card-background/crash.svg'
import tableBg from './../../../assets/category-card-background/table.svg'
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { TopWins } from "../../components/topWins/TopWins";
import { ProvidersListSection } from "../../components/providersList/ProvidersListSection";

export const LobbyPage: FC = () => {
    const { mediumScreen, smallScreen, xxSmallScreen } = useCommonMediaQueries();
    const {
        languagePack: {
            pack: {
                argentina: { common: lang, dashboard: { sideMenu } },

            },
        },
    } = useLanguage();

    const cards = [
        { id: 0, title: sideMenu.casino, path: PATHS.casino, bg: casinoBg },
        { id: 1, title: sideMenu.liveCasino, path: PATHS.liveCasino, bg: liveCasinoBg },
        { id: 2, title: sideMenu.crashGames, path: PATHS.crash, bg: crashBg },
        { id: 3, title: sideMenu.tableGames, path: PATHS.tableGames, bg: tableBg },
    ]

    return (
        <PageLayout pageName="Lobby" carouselDisabled>
            <Stack direction='column' gap={xxSmallScreen ? 3 : 4}>
                <Stack direction={xxSmallScreen ? 'column' : 'row'} spacing={2} overflow='auto'>
                    {cards.map(card =>
                        <Link to={card.path} style={{ textDecoration: 'none' }} key={card.id}>
                            <Box
                                key={card.id}
                                width={xxSmallScreen ? '100%' : smallScreen ? '292px' : mediumScreen ? '320px' : '360px'}
                                padding={'24px 34px 94px 14px'}
                                sx={{
                                    backgroundImage: `url(${card.bg}), linear-gradient(180deg, #141931 0%, #1C223F 100%)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'
                                }}
                                borderRadius='10px'
                                boxShadow='inset 0 0 0 4px #E4E9F80D'
                            >

                                <Typography
                                    sx={{
                                        backgroundImage: 'linear-gradient(180deg, #E4E9F8 6.9%, #8B98BE 98.28%)',
                                        '-webkit-background-clip': 'text',
                                        color: 'transparent'
                                    }}
                                    fontWeight='bold'
                                    variant='h5'
                                >
                                    {card.title}
                                </Typography>
                            </Box>
                        </Link>
                    )}
                </Stack>

                <TopGamesSection
                    icon={<CasinoIcon fontSize="large" fill="#B431AD" />}
                    title={lang.topGames}
                />

                <ProvidersListSection
                    icon={<TopProvidersIcon fontSize="large" fill="#B431AD" />}
                    title={lang.topProviders}
                />

                <TopWins />

            </Stack>
        </PageLayout >
    )
}
