import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface LobbyIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const LobbyIcon: FC<LobbyIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 14.75">
      <path
        // fill="#ADC2FF"
        fill={fill} // Use the fill prop
        fillOpacity="1.0"
        fillRule="evenodd"
        d="M5 8L5 7C5 6.2 4.68 5.44 4.12 4.88C3.56 4.31 2.8 4 2 4C2 3.73 2.03 3.47 2.07 3.22C2.23 2.44 2.61 1.73 3.17 1.17C3.73 0.61 4.44 0.23 5.21 0.07C5.6 0 6.07 0 7 0L13 0C13.92 0 14.39 0 14.78 0.07C15.55 0.23 16.26 0.61 16.82 1.17C17.38 1.73 17.76 2.44 17.92 3.22C17.96 3.47 17.99 3.73 17.99 4C17.19 4 16.43 4.31 15.87 4.88C15.31 5.44 15 6.2 15 7L15 8L5 8ZM3.55 13L16.44 13C16.71 13 16.98 12.96 17.25 12.9L17.25 14C17.25 14.19 17.32 14.38 17.46 14.53C17.61 14.67 17.8 14.75 18 14.75C18.19 14.75 18.38 14.67 18.53 14.53C18.67 14.38 18.75 14.19 18.75 14L18.75 12.15C19.14 11.81 19.45 11.4 19.67 10.93C19.88 10.46 20 9.95 20 9.44L20 7C20 6.46 19.78 5.96 19.41 5.58C19.03 5.21 18.53 5 18 5C17.46 5 16.96 5.21 16.58 5.58C16.21 5.96 16 6.46 16 7L16 8.2C16 8.41 15.91 8.61 15.76 8.76C15.61 8.91 15.41 9 15.2 9L4.79 9C4.58 9 4.38 8.91 4.23 8.76C4.08 8.61 4 8.41 4 8.2L4 7C4 6.46 3.78 5.96 3.41 5.58C3.03 5.21 2.53 5 2 5C1.46 5 0.96 5.21 0.58 5.58C0.21 5.96 0 6.46 0 7L0 9.44C0 9.95 0.11 10.46 0.32 10.93C0.54 11.4 0.85 11.81 1.25 12.15L1.25 14C1.25 14.19 1.32 14.38 1.46 14.53C1.61 14.67 1.8 14.75 2 14.75C2.19 14.75 2.38 14.67 2.53 14.53C2.67 14.38 2.75 14.19 2.75 14L2.75 12.9C3.01 12.96 3.28 13 3.55 13Z"
      />
    </SvgIcon>
  );
};

export default LobbyIcon;
