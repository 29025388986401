import Stack from "@mui/material/Stack";
import { FC, useEffect, useRef } from "react";
import { ProviderCard } from "./ProviderCard";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { getCurrentDomain } from "../../config";
import { SectionDivider } from "../sectionDivider/SectionDivider";

export const ProvidersListSection: FC<{
  title?: string;
  icon?: JSX.Element;
}> = ({ title, icon }) => {
  const { groupedGames } = usePlayGames();
  const domain = getCurrentDomain();
  const stackRef = useRef<HTMLDivElement | null>(null);
  const velocityRef = useRef<number>(0);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (stackRef.current) {
        event.preventDefault();
        velocityRef.current += event.deltaY * 0.2; // Увеличение скорости прокрутки
        if (animationFrameRef.current === null) {
          requestAnimationFrame(step);
        }
      }
    };

    const step = () => {
      if (stackRef.current) {
        stackRef.current.scrollLeft += velocityRef.current;
        velocityRef.current *= 0.95; // Затухание скорости

        if (Math.abs(velocityRef.current) > 0.5) {
          animationFrameRef.current = requestAnimationFrame(step);
        } else {
          animationFrameRef.current = null;
        }
      }
    };

    const stackElement = stackRef.current;
    if (stackElement) {
      stackElement.addEventListener("wheel", handleWheel);
    }

    return () => {
      if (stackElement) {
        stackElement.removeEventListener("wheel", handleWheel);
      }
      if (animationFrameRef.current !== null) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <Box>
      {/* SectionDividerRD on top */}
      <SectionDivider
        icon={icon}
        title={title}
        showLeftRightButtons={false}
      />

      {/* Horizontal Stack for ProviderCards */}
      <Stack
        ref={stackRef}
        py={"5px"}
        direction={"row"}
        sx={{
          overflowX: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#15181D #15181D60",
          userSelect: "none",
          mt: 2,
        }}
        gap={"10px"}
      >
        {groupedGames !== null &&
          groupedGames.map((group, index) => (
            <Link
              key={`${index}-group-${group.provider.provider_name}`}
              to={`/games?providerName=${group.provider.provider_name}`}
            >
              <ProviderCard
                onClick={() => { }}
                providerLogo={`${domain}/cdn/provider_img/hor/${group.provider.provider_name}.png`}
                providerName={group.provider.title ?? "No name"}
                gamesCount={group.gamesCount}
              />
            </Link>
          ))}
      </Stack>
    </Box>
  );
};
