import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Balance } from "../userInfo/personalInfo/Balance";
import { useTopWins } from "../../features/top_wins/useTopWins";
import iconFirstPlace from './../../../assets/salta-la-banca/legends/legend_first_place.png';
import iconSecondPlace from './../../../assets/salta-la-banca/legends/legend_second_place.png';
import iconThirdPlace from './../../../assets/salta-la-banca/legends/legend_third_place.png';
import { useLanguage } from "../../features/localisation/useLanguage";

export const TopWinsCards: FC = () => {
    const { topWins } = useTopWins();
    const {
        languagePack: {
            pack: {
                gamesHistory,
            },
        },
    } = useLanguage();

    const icons = [iconFirstPlace, iconSecondPlace, iconThirdPlace];

    return <Stack gap='10px' width='100%'>
        {
            topWins?.map((topWin, index) => (
                <Card
                    key={topWin.uuid}
                    sx={{
                        p: '20px',
                        width: '100%',
                        background: "linear-gradient(270deg, #1C223F 0%, #141931 100%)"
                    }}
                >
                    <Stack gap='10px'>
                        {icons[index] ? <img src={icons[index]} height='24px' width='66px' alt='Cup Icon' /> : <Typography fontWeight='bold' >{index + 1}</Typography>
                        }
                        <Stack gap='10px' direction='row' justifyContent='space-between'>
                            <Box width='100%'>
                                <Stack gap='10px'>
                                    <Stack>
                                        <Typography color=' #AEC2FF4D' variant="caption">{gamesHistory.userTableHeader}:</Typography>
                                        <Typography fontWeight='bold' textTransform='capitalize'>
                                            {topWin.user_name}
                                        </Typography>
                                    </Stack>
                                    <Stack gap='4px'>
                                        <Typography color=' #AEC2FF4D' variant="caption">{gamesHistory.betAmountTableHeader}:</Typography>
                                        <Balance
                                            balance={topWin.withdraw_sum!}
                                            hideBackground
                                            border='none'
                                        />
                                    </Stack>
                                </Stack>
                            </Box>
                            <Divider orientation="vertical" sx={{ height: 'initial' }} />
                            <Box width='100%'>
                                <Stack gap='10px'>
                                    <Stack>
                                        <Typography color=' #AEC2FF4D' variant="caption">{gamesHistory.multiplierTableHeader}:</Typography>
                                        <Typography fontWeight='bold' textTransform='capitalize'>
                                            x{topWin.multiplier}
                                        </Typography>
                                    </Stack>
                                    <Stack gap='4px'>
                                        <Typography color=' #AEC2FF4D' variant="caption">{gamesHistory.winAmountTableHeader}:</Typography>
                                        <Balance
                                            balance={topWin.deposit_sum!}
                                            background='#282F4F'
                                            border='none'
                                        />
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                </Card>
            ))
        }

    </Stack >
}