import { Box, Stack } from "@mui/material";
import { FC } from "react";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { useScalingWidth } from "../hooks/useAutoSize";
import { GameCard } from "../../gameCard/GameCard";

interface GamesListProps {
  games: GameObj[];
}

export const FullGamesList: FC<GamesListProps> = ({
  games
}) => {
  const { xxSmallScreen, smallScreen } = useCommonMediaQueries();
  const width = useScalingWidth();
  const gap = smallScreen ? 1 : 1.5;

  const itemsPerRow = xxSmallScreen ? 3 : smallScreen ? 4 : 9;
  const remainingItems = itemsPerRow - (games.length % itemsPerRow);

  const emptyBlocks = Array.from({ length: remainingItems }, (_, index) => (
    <Box key={`empty-${index}`} minWidth={width} />
  ));

  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-evenly"}
      gap={gap}
      sx={{
        marginTop: "24px",
        marginBottom: "24px",
      }}
    >
      {games.map((game) => (
        <GameCard
          width={width}
          key={game.internal_id}
          game={game}
        />
      ))}
      {emptyBlocks}
    </Stack>
  );
};
