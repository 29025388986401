import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { GameType } from "../../../features/play_games/playGamesModels";
import { useGameSectionLogic } from "../hooks/useGameSectionLogic";
import { useScalingWidth } from "../hooks/useAutoSize";
import { GameCard } from "../../gameCard/GameCard";
import { SectionDivider } from "../../sectionDivider/SectionDivider";

export const GamesSection: FC<{
  gameType?: GameType;
  title?: string;
  icon?: JSX.Element;
  link?: string;
}> = ({ gameType, title, icon, link }) => {
  const { filteredGames, offset, page, totalPages, switchPage } =
    useGameSectionLogic(gameType);
  const gap = 1.6;
  const gameCardWidth = useScalingWidth();

  return (
    <Stack gap={2}>
      <SectionDivider
        icon={icon}
        title={title}
        link={link}
        leftButtonDisabled={page <= 1}
        rightButtonDisabled={page === totalPages}
        onLeftButtonClick={() => switchPage("back")}
        onRightButtonClick={() => switchPage("forward")}
      />

      <Box overflow={"auto"} display={"flex"} gap={gap}>
        {filteredGames?.slice(offset, offset + 8).map((game) => (
          <GameCard
            width={gameCardWidth}
            key={game.internal_id}
            game={game}
          />
        ))}
      </Box>
    </Stack>
  );
};
