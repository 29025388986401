import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface MenuLinesProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const MenuLines: FC<MenuLinesProps> = ({ fill = "#E4E9F8", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 12">
      <path
        fill={fill} // Use the fill prop
        fillOpacity="1.0"
        fillRule="evenodd"
        d="M0 1C0 0.44 0.44 0 1 0L15 0C15.55 0 16 0.44 16 1C16 1.55 15.55 2 15 2L1 2C0.44 2 0 1.55 0 1ZM0 6C0 5.44 0.44 5 1 5L11 5C11.55 5 12 5.44 12 6C12 6.55 11.55 7 11 7L1 7C0.44 7 0 6.55 0 6ZM1 10C0.44 10 0 10.44 0 11C0 11.55 0.44 12 1 12L15 12C15.55 12 16 11.55 16 11C16 10.44 15.55 10 15 10L1 10Z"
      />
    </SvgIcon>
  );
};

export default MenuLines;