import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useUser } from "../../features/user/useUser";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { useAppDispatch, useAppSelector } from "../../store";
import { getDeviceType } from "../../features/common_funcs";
import { setGameSession } from "../../features/play_games/playGamesSlice";
import TopProvidersIcon from "../../../assets/svg/TopProvidersIcon";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { getCurrentDomain } from "../../config";
import { TopWins } from "../../components/topWins/TopWins";
import { ProvidersListSection } from "../../components/providersList/ProvidersListSection";

export const GameLaunchPageRD: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: { common: lang },
      },
    },
  } = useLanguage();
  const theme = useTheme();
  const { portraitMode } = useCommonMediaQueries();
  const { user, refreshUserBalance } = useUser();
  const [justMounted, setJustMounted] = useState<boolean>(true);
  const { startGameSession, gameSession } = usePlayGames();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const internalId = queryParams.get("internalId");
  const dispatch = useAppDispatch();
  const internalIdPrev = useRef<string | undefined | null>();
  const navigate = useNavigate();
  const deviceType = getDeviceType();
  const [fullPageMode, setFullPageMode] = useState<boolean>(
    deviceType === "mobile"
  );
  const { smallScreen } = useCommonMediaQueries();
  const domain = getCurrentDomain();
  const [game, provider] = useAppSelector((state) => {
    if (!internalId) return [null, null];
    const game = state.playGames.games?.find(game => +game.internal_id === +internalId);
    const provider = state.playGames.providers?.find(provider => provider.provider_name === game?.provider_name);

    return [game, provider]
  })

  useEffect(() => {
    if (justMounted) {
      setJustMounted(false);
      if (gameSession.SessionUrl !== null) {
        dispatch(setGameSession({ SessionId: null, SessionUrl: null }));
      }
    }
  }, [dispatch, gameSession.SessionUrl, justMounted]);

  useEffect(() => {
    if (internalId !== internalIdPrev.current) {
      internalIdPrev.current = internalId;
      dispatch(setGameSession({ SessionId: null, SessionUrl: null }));
      if (internalId !== null) {
        startGameSession(parseInt(internalId));
      }
    }
  }, [dispatch, internalId, startGameSession]);

  const goBackOrHome = () => {
    refreshUserBalance();
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const commonButtonProps: ButtonProps = {
    variant: "outlined",
    sx: {
      minWidth: "34px",
      height: "34px",
      px: theme.spacing(1),
      backgroundColor: alpha("#fff", 0.08),
      borderWidth: "1px",
      borderColor: alpha("#fff", 0.1),
      color: "#E4DCD5",
      "&:hover": {
        borderWidth: "1px",
      },
    },
  };

  const switchFullPageMode = () => {
    setFullPageMode((prevState) => !prevState);
  };

  return (
    <PageLayout pageName="Home" carouselDisabled mobileBottomMenuDisabled>
      <Stack gap={4}>
        <Stack width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            py={portraitMode ? 0 : 2}
            mb={smallScreen ? 2 : 0}
          >
            <Button {...commonButtonProps} onClick={goBackOrHome}>
              <ArrowBackIosIcon sx={{ mr: "-8px", fontSize: "16px" }} />
            </Button>
            <Button {...commonButtonProps} onClick={switchFullPageMode}>
              <FullscreenIcon />
            </Button>
          </Box>

          {user !== null ? (
            <Stack
              sx={{
                backgroundColor: '#05091E'
              }}
              borderRadius='20px'
              overflow='hidden'
              border='2px solid var(--Stroke-Stroke-10, #E4E9F81A)'
            >
              <Box
                sx={{ backgroundColor: "inherit" }}
                left={0}
                right={0}
                top={0}
                bottom={0}
                position={fullPageMode ? "fixed" : undefined}
                width={"100%"}
                height={
                  fullPageMode ? undefined : portraitMode ? "75dvh" : "80dvh"
                }
                zIndex={fullPageMode ? 1000 : undefined}
              >
                {fullPageMode && (
                  <Box
                    onClick={goBackOrHome}
                    borderRadius={"16px"}
                    position={"fixed"}
                    left={"20px"}
                    top={"20px"}
                    height={"40px"}
                    width={"40px"}
                    sx={{
                      backgroundColor: alpha("#000", 0.3),
                      cursor: "pointer",
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ArrowBackIosIcon sx={{ mr: "-8px" }} />
                  </Box>
                )}
                {fullPageMode && (
                  <Box
                    onClick={switchFullPageMode}
                    borderRadius={"16px"}
                    position={"fixed"}
                    right={"20px"}
                    top={"20px"}
                    height={"40px"}
                    width={"40px"}
                    sx={{
                      backgroundColor: alpha("#000", 0.3),
                      cursor: "pointer",
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <FullscreenExitIcon />
                  </Box>
                )}
                {gameSession.SessionUrl && (
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      margin: "0",
                      padding: "0",
                      zIndex: 1000,
                    }}
                    src={gameSession.SessionUrl}
                    allowFullScreen={true}
                    allow="autoplay"
                    title={gameSession.SessionId ?? ""}
                  />
                )}
              </Box>

              <Stack
                direction='row'
                width='100%'
                justifyContent='space-between'
                p={smallScreen ? '16px' : '24px 30px'}
                pr={smallScreen ? undefined : '60px'}
                alignItems='center'
              >
                <Typography
                  sx={{
                    backgroundImage: 'linear-gradient(180deg, #E4E9F8 6.9%, #8B98BE 98.28%)',
                    '-webkit-background-clip': 'text',
                    color: 'transparent'
                  }}
                  fontWeight='bold'
                  variant={smallScreen ? 'body1' : 'h4'}
                >
                  {game?.Name}
                </Typography>

                <img
                  src={`${domain}/cdn/provider_img/hor/${provider?.provider_name}.png`} width={smallScreen ? '100' : '172'}
                  alt={provider?.provider_name}
                />
              </Stack>

            </Stack>
          ) : (
            <Paper
              sx={{
                width: "100%",
                height: "50dvh",
                p: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant={"h2"}>You need to be logged in</Typography>
            </Paper>
          )}
        </Stack>

        <ProvidersListSection
          icon={<TopProvidersIcon fontSize="large" fill="#B431AD" />}
          title={lang.topProviders}
        />

        <TopWins />
      </Stack>
    </PageLayout>
  );
};
