import { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useTransactions } from "../../../features/transactions/useTransactions";
import { getNumberParam } from "../../../features/common_funcs";
import { setGamePageSize } from "../../../features/gameHistory/gameHistorySlice";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { useUser } from "../../../features/user/useUser";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { PageSizeSwitcher } from "../../../components/common/pageSizeSwitcher/PageSizeSwitcher";
import { Transactions } from "../../../components/transactionsTable/Transactions";

export const TransactionsPage: FC = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { pageSize, currentPage, getTransactions, transactions, clearTransactions, totalPages } = useTransactions()
    const { user, token } = useUser()
    const { smallScreen } = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            clearTransactions()
        }
    }, [clearTransactions])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page: number | null = getNumberParam(searchParams.get('page'))
        const itemsOnPage: number | null = getNumberParam(searchParams.get('pageSize'))
        if ((itemsOnPage && itemsOnPage !== pageSize) && (page && page !== currentPage)) {
            getTransactions(page, itemsOnPage)
        } else if ((itemsOnPage && itemsOnPage !== pageSize) && transactions.length === 0) {
            getTransactions(1, itemsOnPage)
        } else if (itemsOnPage && itemsOnPage !== pageSize) {
            dispatch(setGamePageSize(itemsOnPage))
            getTransactions(1, itemsOnPage)
        } else if (page && page !== currentPage) {
            getTransactions(page)
        } else if (transactions.length === 0) {
            getTransactions(1)
        } else if (!Boolean(itemsOnPage) && !Boolean(page) && (pageSize !== 30 || currentPage !== 1) && transactions.length !== 0) {
            getTransactions(1, 30)
        }
    }, [currentPage, transactions, dispatch, getTransactions, location.search, pageSize])

    if (!(user && token)) return null

    return (
        <Stack alignItems='flex-end' gap={5} >
            <Transactions />

            {
                !!transactions.length &&
                <>
                    {smallScreen && <PageSizeSwitcher />}

                    <Paginator totalPages={totalPages} currentPage={currentPage} />

                </>
            }
        </Stack>

    )
}