import { Avatar, Box } from "@mui/material";
import userImage from "../../../../assets/salta-la-banca-redesign/user_section_redesign/user_image@2x.webp";

interface ProfilePhotoProps {
  size?: number; // Optional size prop to customize the photo size
}

export const ProfilePhoto = ({
  size = 162, // Default size is 162
}: ProfilePhotoProps) => {
  // Define ratios for borderRadius and contour width
  const borderRadiusRatio = 0.208; // borderRadius = size * borderRadiusRatio
  const contourWidthRatio = 0.03; // contourWidth = size * contourWidthRatio

  // Calculate borderRadius and contour width using the ratios
  const borderRadius = size * borderRadiusRatio;
  const contourWidth = size * contourWidthRatio;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // marginLeft: 1, // Move the profile picture slightly to the left
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          borderRadius: `${borderRadius}px`, // Use dynamic borderRadius
          background: "linear-gradient(180deg, #D43DCC, #B431AD 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: `${contourWidth}px`, // Use dynamic contour width
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: `${borderRadius}px`, // Use dynamic borderRadius
            background: "rgb(28, 34, 63)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar
            src={userImage}
            variant="square"
            sx={{ width: "100%", height: "100%", borderRadius: `${borderRadius}px` }} // Use dynamic borderRadius
          />
        </Box>
      </Box>
    </Box>
  );
};