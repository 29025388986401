import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const esLang: LangPack = {
    lang: Lang.ES,
    langName: "Spanish",
    langShort: "es",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Sí",
            errorCodeTitle: "Código de error",
            pageSizeSwitcherLabel: "Filas por página",
            ageConfirmationMessage: "¿Confirma que tiene 18 años?",
            iConfirmBTN: "Confirmo",
            welcome: "Bienvenido",
            userProfile: "Perfil de usuario",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "¿Estás seguro de que quieres cerrar sesión?",
            logOut: "Cerrar sesión",
            depositMoney: "Depositar dinero",
            gamesHistory: "Historial de juegos",
            transactions: "Transacciones",
            paymentAccounts: "Cuentas de pago",
            userInfo: "Información de usuario",
            withDrawMoney: "Retirar dinero",
        },
        mobileMenu: {
            profile: "Perfil",
            topUpBalance: "Recargar saldo",
            logout: "Cerrar sesión",
        },
        dashboard: {
            title: "Tablero",
            youMustBeLoggedMessage: "¡Debes iniciar sesión para ver esta sección!",
        },
        internalErrorMessages: {
            networkError: "Se produjo un error de red al ejecutar la solicitud.",
            error502: "Se produjo un error 502 del servidor al ejecutar la solicitud.",
        },
        errorMessages: {
            2: "Solicitud vacía",
            3: "Correo electrónico ya existente",
            4: "Número de teléfono ya existente",
            5: "Usuario ya ha iniciado sesión",
            6: "Contraseña incorrecta",
            7: "Resultado de juegos vacío",
            8: "ID de resultado no coincide con la ID de la solicitud",
            9: "Error en la solicitud de resultado",
            10: "ID de sesión no presente en la respuesta",
            11: "Usuario ya existente",
            12: "Tu token no es válido. Inicia sesión de nuevo.",
            13: "No tienes suficientes derechos para realizar esta acción",
            14: "Falta la ID de usuario para esta acción",
            15: "Falta la cantidad de dinero",
            16: "La acción no existe",
            17: "Falta el token",
            18: "Dinero insuficiente",
            19: "Debes ser el padre de este usuario para realizar esta acción",
            20: "El usuario no existe",
            21: "Tipo de valor incorrecto para este campo",
            22: "Falta la contraseña",
            23: "Falta el nombre de usuario",
            24: "Falta la ID de juego",
            25: "Falta la nueva contraseña",
            26: "No se pudo configurar el token",
            27: "No se pudo eliminar el token",
            28: "Falta la ID de usuario",
            29: "Formato de fecha y hora incorrecto",
            30: "No se pudo actualizar la prohibición del usuario",
            31: "Este usuario debe estar en tu árbol para realizar esta acción",
            32: "Este usuario está prohibido y no puede realizar ninguna acción",
            33: "Falta la moneda",
            34: "Falta el valor máximo",
            35: "El tipo de límite debe ser único, diario, semanal o mensual",
            36: "Falta el tipo de límite",
            37: "El proveedor no existe",
            38: "Falta la cantidad de condición de nivel",
            39: "Falta el tipo de condición de nivel",
            40: "Falta el título de nivel",
            41: "El cashback para este nivel ya existe",
            42: "No existe cashback para este nivel",
            43: "El bonus para este código promocional ya existe",
            44: "El bonus no existe",
            45: "Falta el código promocional",
            46: "Falta la hora de inicio",
            47: "Falta la cantidad de condición de depósito",
            48: "Falta la apuesta",
            49: "Falta la validez en días",
            50: "Falta el ID",
            51: "Falta el tipo de cashback, debe ser 'apuesta' o 'depósito'",
            52: "Falta la tasa de cashback",
            53: "Esta moneda no está disponible",
            54: "Falta el parámetro de prohibición - true o false",
            55: "El grupo bancario para esta moneda no es válido",
            56: "Falta el nombre del método de pago",
            57: "Faltan parámetros obligatorios para este método de pago",
            58: "Este método de pago no existe",
            59: "Este método de pago no admite la moneda de este usuario",
            60: "Este usuario no tiene una cuenta existente para este sistema de pago",
            61: "El padre de este usuario no tiene una cuenta existente para este sistema de pago",
            62: "Falta el ID de transferencia",
            63: "No existe una transferencia con esta ID",
            64: "Esta transferencia es solicitada por un padre diferente",
            65: "Todavía tienes un bonus que no ha caducado",
        },
        loginForm: {
            usernameFieldLabel: "Nombre de usuario",
            passwordFieldLabel: "Contraseña",
            loginBTN: "Entrar",
            authorization: "Autorización",
        },
        searchGameForm: {
            searchGame: "Buscar juegos",
            notFound:
                "No encontramos ningún resultado que coincida con tu solicitud, intenta con algo más.",
            results: "Resultados:",
            seeAll: "Ver todos",
            searchResults: "Resultados de la búsqueda",
        },
        userInfoForm: {
            emailIsRequiredMess: "Correo electrónico obligatorio",
            notValidEmail: "Ingresa una dirección de correo electrónico válida",
            emailFieldLabel: "Correo electrónico",
            phoneFieldLabel: "Teléfono",
            firstnameFieldLabel: "Nombre",
            lastnameFieldLabel: "Apellido",
            saveInfoBTN: "Guardar información",
            currentPassFieldLabel: "Contraseña actual",
            newPassFieldLabel: "Nueva contraseña",
            confirmPassFieldLabel: "Confirmar contraseña",
            updatePassBTN: "Actualizar contraseña",
            emptyFieldWarningMessage: "Este campo no debe estar vacío",
            passNotMatchWarningMessage:
                "La nueva contraseña y la contraseña de confirmación no coinciden",
            usernameLabel: "Apodo",
            password: "Contraseña",
            balance: "Saldo",
        },
        transactionsTable: {
            amountTableHeader: "Cantidad",
            methodTableHeader: "Método",
            statusTableHeader: "Estado",
            depositWithdrawTableHeader: "Depósito/Retiro",
            dateTimeTableHeader: "Fecha/Hora",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Proveedor",
            gameTableHeader: "Juego",
            betAmountTableHeader: "Cantidad apostada",
            winAmountTableHeader: "Cantidad ganada",
            dateTimeTableHeader: "Fecha/Hora",
            userTableHeader: "Usuario",
            multiplierTableHeader: "Multiplicador",
            payoutTableHeader: "Pago",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "No hay más métodos de pago disponibles",
            paymentMethodsDropdownLabel: "Métodos de pago",
            addBTN: "Agregar",
            yourPaymentAccountsTitle: "Tus cuentas de pago",
            addPaymentMethodBTN: "Agregar método de pago",
        },
        moneyInput: {
            maxAmountMessage: "Valor máximo",
            exceedAmountError: "Se ha excedido el valor máximo.",
            moreThanOneDotError: "Ingresaste un punto extra.",
            moreThanTwoDecimals: "Ingresaste más de dos decimales.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirar de usuario",
            moneySuccessfulRequestedMessage: "Has solicitado %money% para el pago.",
            requestBtn: "Solicitar dinero",
        },
        topWinModal: {
            aboutBet: "Sobre la apuesta",
            dateLabel: "Fecha",
            betIdLable: "ID de apuesta",
            betAmount: "Cantidad apostada",
            multiplier: "Multiplicador",
            payout: "Pago",
            play: "Jugar",
        },
        argentina: {
            common: {
                linkToAllTitle: "Todos",
                topGames: "Juegos Principales",
                topLiveCasino: "Mejor Casino en Vivo",
                topProviders: "Mejores Proveedores",
                topCrashGames: "Mejores Juegos de Crash",
                topTableGames: "Mejores Juegos de Mesa",
                liveFeed: "Transmisión en vivo",
                seeAllBTN: "Mostrar todo",
                returnBackBTN: "Volver atrás",
                playGame: "Jugar",
            },
            termsAndConditions: {
                content: [
                    "Términos y Condiciones",
                    "Bienvenido a Salta La Blanca, su destino confiable de casino en línea. Al acceder a nuestro sitio web o utilizar cualquiera de nuestros servicios, usted reconoce y acepta estar sujeto a estos Términos y Condiciones. Por favor, tómese un momento para leerlos detenidamente, ya que describen los derechos y obligaciones de ambas partes para garantizar una experiencia de juego justa y transparente.",
                    "Aceptación de los Términos",
                    "Al registrar una cuenta o utilizar cualquier función de nuestra plataforma, usted confirma que ha leído, comprendido y aceptado estos Términos y Condiciones. Si no está de acuerdo con alguna de las disposiciones aquí descritas, le pedimos que se abstenga de utilizar nuestros servicios. Su uso continuo de la plataforma constituye la confirmación de su aceptación.",
                    "Elegibilidad para Usar",
                    "El acceso a Salta La Blanca está estrictamente limitado a personas que cumplan con los requisitos de edad legal para apostar en su jurisdicción, generalmente 18 años o más. Es su responsabilidad determinar si la participación en juegos de azar en línea está permitida según las leyes de su país o región. Nos reservamos el derecho de solicitar verificación de edad en cualquier momento y podemos suspender cuentas si no se cumplen los requisitos de elegibilidad.",
                    "Cuenta de Usuario",
                    "Para acceder a nuestros juegos y servicios, debe crear una cuenta proporcionando información precisa y actualizada, incluyendo su nombre, dirección de correo electrónico y otros detalles relevantes. Usted es responsable de mantener la confidencialidad de las credenciales de su cuenta y de asegurarse de que personas no autorizadas no tengan acceso. Si sospecha de alguna actividad no autorizada, debe notificarnos de inmediato para evitar un uso indebido.",
                    "Depósitos y Retiros",
                    "Los depósitos en su cuenta de juego deben realizarse utilizando métodos de pago aprobados oficialmente por Salta La Blanca. Todas las transacciones se procesan de forma segura, y tomamos las precauciones necesarias para proteger su información financiera. Los retiros pueden estar sujetos a procedimientos de verificación de identidad para cumplir con los requisitos legales y regulatorios. Tenga en cuenta que los tiempos de procesamiento de los retiros pueden variar según el método de pago elegido.",
                    "Bonificaciones y Promociones",
                    "Ofrecemos una variedad de bonificaciones y promociones emocionantes para mejorar su experiencia de juego. Cada bonificación está sujeta a términos y condiciones específicos, como requisitos de apuesta y períodos de vencimiento, que se detallarán claramente en la información de la promoción. El uso indebido o abuso de las ofertas promocionales, incluidos los intentos de eludir las reglas, puede resultar en la pérdida de las bonificaciones y las ganancias asociadas.",
                    "Juego Responsable",
                    "En Salta La Blanca, estamos comprometidos a promover el juego responsable y garantizar que nuestra plataforma sea un entorno seguro para todos los usuarios. Proporcionamos herramientas para ayudarle a gestionar su actividad de juego, como establecer límites de depósito, límites de apuesta y opciones de autoexclusión. Si considera que sus hábitos de juego se están volviendo problemáticos, le recomendamos encarecidamente que busque ayuda a través de recursos disponibles como [Soporte de Juego Responsable].",
                    "Actividades Prohibidas",
                    "Para mantener un entorno de juego justo y seguro, se prohíben estrictamente las prácticas fraudulentas, manipuladoras o injustas. Esto incluye, entre otros, el uso de bots automatizados, la colusión con otros jugadores o cualquier intento de explotar vulnerabilidades del sistema. Participar en tales actividades resultará en la suspensión o cierre inmediato de su cuenta y la pérdida de cualquier ganancia.",
                    "Propiedad Intelectual",
                    "Todo el contenido en la plataforma de Salta La Blanca, incluyendo pero no limitado a logotipos, diseños, texto, software y multimedia, es propiedad exclusiva de nuestra empresa. Estos materiales están protegidos por las leyes internacionales de derechos de autor y propiedad intelectual. Cualquier uso, reproducción o distribución no autorizada de nuestro contenido está estrictamente prohibido y puede resultar en acciones legales.",
                    "Limitación de Responsabilidad",
                    "Si bien nos esforzamos por ofrecer una experiencia de juego fluida y agradable, Salta La Blanca no garantiza que nuestra plataforma esté libre de errores, interrupciones o problemas técnicos. No seremos responsables por pérdidas directas, indirectas o incidentales derivadas del uso de la plataforma, incluyendo pérdidas resultantes de transacciones fallidas, fallos técnicos o acceso no autorizado a la cuenta.",
                    "Modificaciones",
                    "Salta La Blanca se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento para reflejar cambios en los requisitos legales, prácticas comerciales o comentarios de los usuarios. Las actualizaciones se publicarán en nuestro sitio web, y le recomendamos que revise estos Términos periódicamente. Su uso continuo de la plataforma después de que se hayan realizado cambios constituye su aceptación de los Términos revisados.",
                    "Ley Aplicable y Disputas",
                    "Estos Términos y Condiciones se rigen por las leyes de [indicar jurisdicción]. Cualquier disputa derivada del uso de nuestros servicios se resolverá de acuerdo con estas leyes. En caso de desacuerdo, recomendamos a los usuarios que contacten a nuestro equipo de soporte para buscar una resolución amistosa antes de emprender acciones legales.",
                    "Contáctenos",
                ],
                email: `Para cualquier pregunta, inquietud o comentario sobre estos Términos y Condiciones, no dude en comunicarse con nuestro equipo de soporte dedicado en <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a>. Estamos aquí para ayudarle y garantizar que su experiencia en nuestra plataforma sea agradable y segura.`,
            },
            aboutUs: {
                content: [
                    "Sobre Nosotros",
                    "Bienvenido a Salta La Blanca, tu principal destino en línea para una experiencia de juego emocionante y diversa. Ya seas un jugador experimentado o nuevo en los juegos en línea, nuestra plataforma está diseñada para ofrecerte la combinación perfecta de emoción, variedad y entretenimiento de calidad.",
                    "Lo que Ofrecemos",
                    "En Salta La Blanca, nos enorgullecemos de ofrecer una amplia gama de opciones de juego que se adaptan a las preferencias de cada jugador. Esto es lo que puedes disfrutar en nuestra plataforma:",
                    "Clásicos del Casino: Revive el encanto atemporal de los juegos tradicionales de casino como el blackjack, la ruleta y el bacará. Con mejoras modernas y diseños elegantes, estos clásicos siguen siendo tan emocionantes como siempre, ofreciendo tanto estrategia como suerte para una experiencia envolvente.",
                    "Juegos de Crash: ¿Buscas una descarga de adrenalina rápida? Nuestros juegos de crash son perfectos para los jugadores que aman la acción intensa donde cada segundo cuenta. Pon a prueba tus reflejos e intuición para ganar grande en esta categoría de juego única y emocionante.",
                    "Casino en Vivo: Sumérgete en la auténtica atmósfera del casino sin salir de casa. Con crupieres reales transmitiendo en alta definición, nuestro casino en vivo lleva la emoción de los casinos físicos directamente a tu pantalla. Interactúa con los crupieres y otros jugadores para una experiencia verdaderamente social y envolvente.",
                    "Apuestas Deportivas: Para los entusiastas del deporte, ofrecemos una sección completa de apuestas deportivas donde puedes apostar en tus equipos, partidos y eventos favoritos. Con cuotas competitivas y una variedad de deportes para elegir, nunca ha sido tan fácil convertir tu pasión por el deporte en apuestas emocionantes.",
                    "Juegos de Mesa: ¿Amas los juegos de estrategia y habilidad? Explora una amplia selección de juegos de mesa que desafían tu mente mientras ofrecen entretenimiento sin fin. Desde póker hasta craps, tenemos algo para cada entusiasta de los juegos de mesa.",
                    "Una Amplia Gama de Proveedores: Para garantizar la más alta calidad, nos asociamos con los mejores proveedores de juegos de casino de la industria. Esto significa que tendrás acceso a una impresionante biblioteca de juegos con gráficos impresionantes, funciones innovadoras y un rendimiento confiable.",
                    "¿Por Qué Elegirnos?",
                    "En Salta La Blanca, no solo ofrecemos juegos; nos esforzamos por crear un entorno de juego que sea entretenido, seguro y centrado en el jugador. Esto es lo que nos hace destacar:",
                    "Innovación: Actualizamos constantemente nuestra plataforma con las últimas tendencias y tecnologías para ofrecer una experiencia de juego vanguardista. Desde gráficos de alta calidad hasta mecánicas de juego únicas, estamos comprometidos a mantener las cosas frescas y emocionantes para nuestros jugadores.",
                    "Variedad: Entendemos que cada jugador es único, por lo que ofrecemos una amplia gama de juegos y opciones de apuestas. No importa cuáles sean tus preferencias, encontrarás algo que se adapte a tu estilo y te mantenga entretenido.",
                    "Juego Justo: La integridad es el núcleo de lo que hacemos. Nuestros juegos están basados en tecnología de Generador de Números Aleatorios (RNG) confiable, lo que garantiza que todos los resultados sean justos e imparciales. Nuestras asociaciones con proveedores reputados garantizan aún más transparencia y confiabilidad.",
                    "Enfoque Centrado en el Jugador: Tu satisfacción es nuestra prioridad. Hemos diseñado nuestra plataforma para que sea fácil de usar e intuitiva, con una navegación fluida y funciones que hacen que el juego sea agradable y sin complicaciones. Además, nuestro dedicado equipo de soporte al cliente siempre está listo para ayudarte con cualquier pregunta o inquietud.",
                    "Únete a Salta La Blanca, donde cada giro, lanzamiento y apuesta crea oportunidades para momentos inolvidables. ¿Listo para sumergirte en la acción? ¡Hagamos que cada juego sea una experiencia ganadora!",
                    "Soporte en vivo",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Sé un Agente",
                    "¿Eres apasionado de los juegos en línea y buscas una oportunidad para convertir esa pasión en una asociación rentable? Únete al Programa de Agentes de Salta La Blanca y conviértete en una parte vital de nuestra comunidad en crecimiento. Esta es tu oportunidad de ganar, conectar y prosperar en el dinámico mundo del entretenimiento en línea mientras promocionas una plataforma en la que realmente puedes confiar.",
                    "¿Por qué asociarse con Salta La Blanca?",
                    "Cuando te unes a nuestro Programa de Agentes, no solo te estás registrando, sino que te estás uniendo a una red que valora tus contribuciones y recompensa tus esfuerzos. Esto es lo que nos diferencia:",
                    "Alto Potencial de Ganancias: Ofrecemos una de las estructuras de comisiones más competitivas de la industria, brindándote la oportunidad de maximizar tus ingresos según la actividad y lealtad de los jugadores que atraes a nuestra plataforma.",
                    "Herramientas de Marketing Avanzadas: Obtén acceso a un conjunto de herramientas profesionales, que incluyen enlaces de seguimiento personalizados, análisis detallados de rendimiento y una variedad de materiales promocionales de alta calidad diseñados para ayudarte a tener éxito.",
                    "Soporte Integral: Nuestro equipo de afiliados está aquí para apoyarte en cada paso. Ya sea que tengas preguntas técnicas o necesites asesoramiento de marketing, estamos a solo un mensaje de distancia.",
                    "Portafolio de Juegos Diverso: Promociona una plataforma que ofrece algo para todos, desde clásicos de casino hasta juegos con crupieres en vivo, juegos de crash, apuestas deportivas y más, todo proporcionado por los mejores proveedores de juegos.",
                    "¿Qué hace un Agente?",
                    "Como agente de Salta La Blanca, tu rol es difundir la palabra sobre nuestra plataforma y atraer nuevos jugadores. Utilizarás tu red, creatividad y habilidades de marketing para aumentar tu audiencia, todo mientras ganas recompensas sustanciales. Es un ajuste perfecto, ya seas un experto en marketing, un creador de contenido o simplemente alguien con una gran pasión por los juegos.",
                    "¿Cómo funciona el programa?",
                    "Regístrate: Completa un proceso de registro rápido y sencillo para convertirte en un agente oficial.",
                    "Obtén tus Herramientas: Accede a tu panel personalizado con enlaces de seguimiento, banners promocionales y otros recursos de marketing.",
                    "Promociona Nuestra Plataforma: Usa tus herramientas para compartir Salta La Blanca con jugadores potenciales a través de sitios web, redes sociales, foros o cualquier plataforma que te convenga.",
                    "Gana Comisiones: Por cada jugador que refieras, ganarás comisiones según su actividad, asegurando un flujo constante de ingresos.",
                    "¿Quién puede unirse?",
                    "Nuestro programa está abierto a todos aquellos con el deseo de tener éxito y una conexión con el mundo de los juegos. Ya seas un influencer con una gran audiencia, un entusiasta de las redes sociales o alguien con una red de amigos que aman los juegos, te proporcionamos todo lo que necesitas para convertir tus esfuerzos en resultados tangibles.",
                    "Tu Éxito es Nuestro Éxito",
                    "En Salta La Blanca, creemos en construir relaciones sólidas y mutuamente beneficiosas con nuestros agentes. Por eso invertimos en tu éxito ofreciendo sistemas de pago transparentes, soporte continuo y una plataforma que los jugadores aman. Cuando tú tienes éxito, nosotros también.",
                    "¿Listo para unirte?",
                    "Convertirse en un agente de Salta La Blanca es simple y gratificante. Da el primer paso hacia un futuro más emocionante registrándote hoy. Haz clic en [Conviértete en un Agente] y comienza a ganar mientras formas parte de uno de los nombres más confiables en los juegos en línea.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Inicio",
                    lobby: "Vestíbulo",
                    casino: "Casino",
                    sport: "Deportes",
                    liveBetting: "Apuestas en Vivo",
                    liveCasino: "Casino en Vivo",
                    crashGames: "Juegos de Crash",
                    tableGames: "Juegos de Mesa",
                    legends: "Leyendas",
                    becomeAnAgent: "Conviértete en Agente",
                    privacyPolicy: "Política de Privacidad",
                    responsibleGaming: "Juego Responsable",
                    termsAndConditions: "Términos y Condiciones",
                    aboutUs: "Sobre Nosotros",
                    userInfo: "Información del usuario",
                    gameHistory: "Historial de juegos",
                    transactions: "Transacciones",
                },
                loggedInUserMenu: {
                    userInfo: "Información del usuario",
                    userTransactions: "Transacciones",
                    userGameHistory: "Historial de juegos",
                    userLogOut: "Cerrar sesión",
                },
                userInfo: {
                    userInfoSection: "Información del usuario",
                    changePasswordSection: "Cambiar contraseña",
                },
            },
            carousel: {
                slide1: {
                    header: "Get ready to play!<br/> Win and Repeat – the Salta la Banca experience awaits!",
                    tagline: "¡Tira y Gana con Galaxsys!",
                },
                slide2: {
                    header: "Descubre la Emoción<br/>solo en Salta la Banca",
                    tagline: "¡Únete al juego hoy mismo!",
                },
                slide3: {
                    header: "Imagina Más, Gana Más <br/>en Imagine Live",
                    tagline: "Las apuestas son reales",
                },
                slide4: {
                    header: "¡Siente la emoción del juego!<br/>¡Vive el momento de la victoria!",
                    tagline: "¡Únete ahora!",
                },
                slide5: {
                    header: "Ezugi está llegando",
                    tagline: "¡Al final de esta semana!",
                },
            },
            menuLinkTitles: {
                home: "Inicio",
                casino: "Casino",
                liveCasino: "Casino en vivo",
                virtualGames: "Juegos virtuales",
            },
            sideBarSections: {
                games: "Juegos",
                topCrashGames: "Juegos de crésis",
                other: "Otro",
                sportsRules: "Reglas deportivas",
            },
            headerMenu: {
                home: "Inicio",
                casino: "Casino",
                slots: "Tragamonedas",
                shooters: "Juegos de Disparos",
                tableGames: "Juegos de Mesa",
                liveCasino: "Casino en Vivo",
                crash: "Juegos de Crash",
                sport: "Deportes",
                menu: "Menú",
                lobby: "Vestíbulo",
            },
            categoriesDescription: {
                casino: "Adéntrate en la Emoción del Casino",
                slots: "¡Tragaperras!",
                shooters: "¡Apunta y Dispara!",
                tableGames: "Juegos de Mesa Clásicos, Emociones Modernas – ¡Juega y Gana!",
                liveCasino: "Únete a la Emoción de la Acción en Vivo del Casino",
                crash: "¡Persigue el Multiplicador y Rompe los Límites!",
                sport: "Haz Tus Apuestas en los Juegos Más Importantes",
            },
            footer: {
                content: [
                    "Bienvenido a Salta la Banca",
                    `¡Tu destino definitivo para la emoción de los juegos en línea! Sumérgete en un mundo expansivo de apuestas deportivas, explora nuestra amplia colección de juegos de casino y disfruta de la acción en vivo con crupieres en cualquier momento y lugar.\n\nCon transacciones seguras y una experiencia sin interrupciones, estamos aquí para elevar tu experiencia de juego.\n\n¡Únete a nosotros y que comience la emoción!\n\nTodos los derechos reservados © Salta la Banca 2024-2025`,
                    `Debes tener al menos 18 años para registrarte y abrir una cuenta con nosotros. La empresa se reserva el derecho de solicitar prueba de edad a cualquier cliente, y las cuentas pueden suspenderse hasta que se proporcione una prueba de edad satisfactoria. La administración del sitio no se hace responsable de las acciones o decisiones tomadas por los usuarios. Este sitio ofrece una oportunidad de entretenimiento y juegos, y todas las actividades están destinadas únicamente a fines recreativos. Todos los derechos reservados. El uso o reproducción no autorizada del contenido del sitio está estrictamente prohibido y protegido por la ley.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Inicio",
                    "Deportes",
                    "Casino",
                    "Casino en Vivo",
                    "Juegos de Crash",
                    "Juegos de Mesa",
                ],
                columnAboutUs: [
                    "Sobre Nosotros",
                    "Afiliados",
                    "Términos de Bonificación",
                    "Verificación de Identidad",
                    "Política de Privacidad",
                    "Juego Responsable",
                    "Opciones de Pago",
                ],
                contactUsBTN: "Contáctanos",
            },
        },
    },
};
