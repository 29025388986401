import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface BecomeAnAgentIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const BecomeAnAgentIcon: FC<BecomeAnAgentIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        // fill="rgba(174, 194, 255, 0.3)"
        fill={fill} // Use the fill prop
        fillOpacity="1.000000"
        fillRule="nonzero"
        d="M8 3C8 3.39 7.92 3.78 7.77 4.14C7.62 4.51 7.39 4.84 7.12 5.12C6.84 5.39 6.51 5.62 6.14 5.77C5.78 5.92 5.39 6 5 6C4.6 6 4.21 5.92 3.85 5.77C3.48 5.62 3.15 5.39 2.87 5.12C2.6 4.84 2.37 4.51 2.22 4.14C2.07 3.78 2 3.39 2 3C2 2.2 2.31 1.44 2.87 0.87C3.44 0.31 4.2 0 5 0C5.79 0 6.55 0.31 7.12 0.87C7.68 1.44 8 2.2 8 3ZM10 12C10.39 12 10.78 11.92 11.14 11.77C11.51 11.62 11.84 11.39 12.12 11.12C12.39 10.84 12.62 10.51 12.77 10.14C12.92 9.78 13 9.39 13 9C13 8.6 12.92 8.21 12.77 7.85C12.62 7.48 12.39 7.15 12.12 6.87C11.84 6.6 11.51 6.37 11.14 6.22C10.78 6.07 10.39 6 10 6C9.2 6 8.44 6.31 7.87 6.87C7.31 7.44 7 8.2 7 9C7 9.79 7.31 10.55 7.87 11.12C8.44 11.68 9.2 12 10 12ZM5.09 8L2 8C1.46 8 0.96 8.21 0.58 8.58C0.21 8.96 0 9.46 0 10L0 10.41C0 11.6 0.68 12.58 1.59 13.22C2.13 13.61 2.78 13.89 3.49 14.06C4.03 13.09 4.96 12.36 6.08 12.1C5.62 11.53 5.3 10.87 5.13 10.16C4.96 9.45 4.95 8.71 5.09 8ZM13.91 12.1C14.46 12.23 14.97 12.47 15.41 12.81C15.86 13.15 16.23 13.57 16.5 14.06C17.21 13.89 17.86 13.61 18.4 13.22C19.31 12.58 20 11.6 20 10.41L20 10C20 9.46 19.78 8.96 19.41 8.58C19.03 8.21 18.53 8 18 8L14.9 8C15.04 8.71 15.03 9.45 14.86 10.16C14.69 10.87 14.37 11.53 13.91 12.1ZM7 14C6.46 14 5.96 14.21 5.58 14.58C5.21 14.96 5 15.46 5 16L5 16.33C5 17.5 5.71 18.45 6.61 19.04C7.52 19.66 8.72 20 10 20C11.27 20 12.47 19.65 13.38 19.04C14.28 18.45 15 17.5 15 16.33L15 16C15 15.46 14.78 14.96 14.41 14.58C14.03 14.21 13.53 14 13 14L7 14ZM16 6C16.79 6 17.55 5.68 18.12 5.12C18.68 4.55 19 3.79 19 3C19 2.2 18.68 1.44 18.12 0.87C17.55 0.31 16.79 0 16 0C15.2 0 14.44 0.31 13.87 0.87C13.31 1.44 13 2.2 13 3C13 3.79 13.31 4.55 13.87 5.12C14.44 5.68 15.2 6 16 6Z"
      />
    </SvgIcon>
  );
};

export default BecomeAnAgentIcon;
