import { FC } from "react";
import { Box, Typography, List, ListItem, ListItemIcon } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useLanguage } from "./../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "./../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {
  titleTextStyle,
  subtitleTextStyle,
  contentTextStyle,
} from "./../general_styles";
import { VioletButton } from "../../../components/violetButton/VioletButton";
import HeadMicrophoneIcon from "./../../../../assets/svg/HeadMicrophoneIcon";
import { Logo } from "../../../components/logo/Logo";
import { PageLayout } from "../../../components/pageLayout/PageLayout";

interface ListItemWithMarkerProps {
  text: string | JSX.Element;
}

const ListItemWithMarker: FC<ListItemWithMarkerProps> = ({ text }) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: "auto", marginRight: "10px" }}>
        <CircleIcon
          sx={{ fontSize: "12px", color: "rgba(228, 233, 248, 0.5)" }}
        />
      </ListItemIcon>
      {text}
    </ListItem>
  );
};

export const AboutUsPage: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: {
          aboutUs: { content },
        },
      },
    },
  } = useLanguage();

  const { smallScreen, xxSmallScreen } = useCommonMediaQueries();

  return (
    <PageLayout pageName={"About Us"} carouselDisabled>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "40px",

        }}
      >
        <Typography sx={{ ...titleTextStyle, ...(xxSmallScreen && { fontSize: '16px' }) }}>{content[0]}</Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ zoom: xxSmallScreen || smallScreen ? 2.5 : 4 }}>
            <Logo />
          </Box>
        </Box>
        <Typography sx={contentTextStyle}>{content[1]}</Typography>
        <Typography sx={subtitleTextStyle}>{content[2]}</Typography>
        <Typography sx={contentTextStyle}>{content[3]}</Typography>
        <List>
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[4]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[5]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[6]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[7]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[8]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[9]}</Typography>}
          />
        </List>
        <Typography sx={subtitleTextStyle}>{content[10]}</Typography>
        <Typography sx={contentTextStyle}>{content[11]}</Typography>
        <List>
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[12]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[13]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[14]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[15]}</Typography>}
          />
        </List>
        <Typography sx={contentTextStyle}>{content[16]}</Typography>
        <Box
          sx={{
            display: "inline-flex",
            alignSelf: xxSmallScreen || smallScreen ? "center" : "flex-start", // Center on small screens, otherwise align to the start
            width: xxSmallScreen || smallScreen ? "100%" : "auto", // Ensure the Box takes full width on small screens
            justifyContent:
              xxSmallScreen || smallScreen ? "center" : "flex-start", // Center the button within the Box on small screens
          }}
        >
          <VioletButton
            textStyle={{
              fontWeight: 600,
            }}
            onClick={() => window.Tawk_API?.maximize?.()}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <HeadMicrophoneIcon fontSize="small" /> {/* Adjust icon size */}
              <span>{content[17]}</span>
            </Box>
          </VioletButton>
        </Box>
      </Box>
    </PageLayout>
  );
};
