import { FC, useState } from "react";
import {
  TextField,
  Card,
  IconButton,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import MuiPhoneInput from "mui-phone-input";
import DoneIcon from '@mui/icons-material/Done';
import { ProfilePhoto } from "./ProfilePhoto";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Balance } from "./Balance";
import { Edit } from "@mui/icons-material";
import { User } from "../../../features/user/user";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface PersonalInfoProps {
  balance: number;
  userName: string;
  firstname: User['user_firstname'];
  lastname: User['user_firstname'];
  email: User['user_firstname'];
  phone: User['user_firstname'];
  emailError: string;
  setFirstname: (v: User['user_firstname']) => void;
  setLastname: (v: User['user_firstname']) => void;
  setEmail: (v: User['user_firstname']) => void;
  setPhone: (v: User['user_firstname']) => void;
  handleSaveInfo: VoidFunction
}

const fieldTitleStyle = {
  color: "rgba(174, 194, 255, 0.3)",
  fontSize: 12,
  fontWeight: 600,
  lineHeight: "100%",
  textAlign: "left",
};

export const PersonalInfo: React.FC<PersonalInfoProps> = ({
  balance,
  userName,
  firstname,
  lastname,
  email,
  phone,
  emailError,
  setFirstname,
  setLastname,
  setEmail,
  setPhone,
  handleSaveInfo
}) => {
  const [editField, setEditField] = useState<string | null>(null);
  const { languagePack: { pack: { userInfoForm }, }, } = useLanguage();

  const handleEdit = (field: string) => {
    setEditField(field);
  };

  const handleSave = () => {
    setEditField(null)
    handleSaveInfo()
  };

  const { smallScreen } = useCommonMediaQueries();

  return (
    <Box height='100%'>
      <Card
        sx={{
          gap: smallScreen ? 2 : 3,
          padding: 2,
          display: "flex",
          flexDirection: smallScreen ? "column" : "row",
          borderRadius: "20px",
          background:
            "linear-gradient(180deg, rgb(20, 25, 49), rgb(28, 34, 63) 100%)",
          height: '100%',
          justifyContent: 'center',
          alignItems: smallScreen ? undefined : 'center',
          border: 'none'
        }}
      >

        <Stack direction='row' gap={smallScreen ? 2 : 4}>
          <ProfilePhoto size={smallScreen ? 100 : 240} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: 0.5,
            }}
          >
            <Typography sx={fieldTitleStyle}>
              {userInfoForm.usernameLabel}
            </Typography>
            <Typography sx={{ color: "white", fontSize: smallScreen ? 14 : 18, marginBottom: smallScreen ? 0.5 : 2, textTransform: "uppercase" }}>
              {userName}
            </Typography>

            <Typography sx={fieldTitleStyle}>
              {userInfoForm.balance}
            </Typography>
            <Balance balance={balance} currency={"ARS"} />
          </Box>
        </Stack>

        <Divider orientation={smallScreen ? 'horizontal' : 'vertical'} />

        <Stack
          color='white'
          flex={1}
          gap={1.5}
        >
          <Input
            type="text"
            title={userInfoForm.firstnameFieldLabel}
            value={firstname}
            onChange={setFirstname}
            onSave={handleSave}
            onEdit={() => handleEdit('firstName')}
            edit={editField === 'firstName'}
            placeholder='John'
          />
          <Input
            type="text"
            title={userInfoForm.lastnameFieldLabel}
            value={lastname}
            onChange={setLastname}
            onSave={handleSave}
            onEdit={() => handleEdit('lastName')}
            edit={editField === 'lastName'}
            placeholder='Doe'
          />

          <Input
            type="text"
            title={userInfoForm.emailFieldLabel}
            value={email}
            onChange={setEmail}
            onSave={handleSave}
            onEdit={() => handleEdit('email')}
            edit={editField === 'email'}
            placeholder='JohnDoe313@gmail.com'
            error={editField === 'email' ? emailError : ''}
          />

          <Input
            type="phone"
            title={userInfoForm.phoneFieldLabel}
            value={phone}
            onChange={setPhone}
            onSave={handleSave}
            onEdit={() => handleEdit('phone')}
            edit={editField === 'phone'}
            placeholder="+1 234 567 88 99"
          />

        </Stack>

      </Card >
    </Box >
  );
};

interface InputProps {
  type: 'text' | 'phone';
  title: string;
  value: string | undefined;
  onChange: (newValue: string) => void;
  onSave: VoidFunction;
  onEdit: VoidFunction;
  edit: boolean;
  placeholder: string;
  error?: string;
}


const Input: FC<InputProps> = ({ type, title, value, onChange, onSave, onEdit, edit, placeholder, error }) => {
  const Field = type === "phone" ? (
    <MuiPhoneInput
      value={value}
      onChange={(value) => onChange(value as string)}
      sx={{
        input: { color: "white" },
        boxSizing: "border-box",
        width: "100%",
        "& .MuiPhoneInput-flagButton": {
          color: "white",
          "& img": {
            borderRadius: "50%",
            width: "24px",
            height: "24px",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        "& input": {
          padding: '6px 0 10px 0'
        },
        "& .MuiPhoneInput-countryList": {
          background:
            "linear-gradient(180deg, rgb(20, 25, 49), rgb(28, 34, 63) 100%)",
          color: "white",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        },
        '& .MuiInputBase-root': {
          paddingLeft: 0
        }
      }}
      autoFocus
      placeholder={placeholder}
      disabled={!edit}
      variant={edit ? "standard" : "outlined"}
      {...(error && {
        error: true,
        helperText: error
      })}
    />) : (
    <TextField
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      variant={edit ? "standard" : "outlined"}
      sx={{
        input: { color: "white" },
        boxSizing: "border-box",
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        "& input": {
          padding: '6px 0 10px 0'
        }
      }}
      size='small'
      autoFocus
      placeholder={placeholder}
      disabled={!edit}
      {...(error && {
        error: true,
        helperText: error
      })}
    />
  )


  return (
    <Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Stack justifyContent='space-between' width='100%'>
          <Typography sx={fieldTitleStyle}>
            {title}
          </Typography>
          {Field}
        </Stack>

        <IconButton
          onClick={() => {
            edit ? onSave() : onEdit()
          }}
          sx={{ color: "white", marginLeft: 1 }}
          disabled={!!error && edit}
        >
          {edit ? <DoneIcon /> : <Edit />}
        </IconButton>
      </Box>
    </Box>
  )
}
