import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface CrashGamesIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const CrashGamesIcon: FC<CrashGamesIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16.7344 20">
      <path
        // fill="#ADC2FF"
        fill={fill} // Use the fill prop
        fillOpacity="1.000000"
        fillRule="evenodd"
        d="M16.28 0L8.95 0C8.77 0 8.61 0.11 8.54 0.28C8.46 0.45 8.5 0.64 8.63 0.77L8.95 1.08L2.33 7.7C1.64 8.38 1.64 9.5 2.33 10.18L4.26 12.11C4.09 12.44 3.33 13.52 0.64 13.68C0.26 13.7 -0.02 14.02 0 14.39C0.02 14.76 0.32 15.04 0.68 15.04C0.69 15.04 0.7 15.04 0.72 15.04C3.47 14.89 4.7 13.83 5.24 13.09L5.51 13.36L0.62 18.25C0.22 18.65 0.22 19.29 0.62 19.69C0.82 19.9 1.08 20 1.34 20C1.61 20 1.87 19.9 2.07 19.69L7.03 14.73C7.26 14.84 7.52 14.91 7.79 14.91C8.25 14.91 8.7 14.72 9.03 14.4L15.64 7.78L15.95 8.09C16.08 8.22 16.28 8.26 16.45 8.19C16.62 8.12 16.73 7.95 16.73 7.77L16.73 0.45C16.73 0.2 16.53 0 16.28 0ZM9.23 2.73L11.76 5.25L14.09 7.4L13.76 7.73L11.95 9.55L10 11.49L8.06 13.43C7.91 13.58 7.65 13.58 7.51 13.43L4.85 10.78C4.63 10.55 4.42 10.35 4.24 10.18C3.33 9.31 3.05 9.03 3.57 8.39L5.39 6.57L7.3 4.66L9.23 2.73ZM13.22 7.66L6.2 6.69L8.13 4.77L10 5.02L13.36 7.52L13.22 7.66ZM4.37 8.5L11.33 9.46L9.31 11.39L6.55 11.01C6.51 11.01 5.08 9.65 4.12 8.75L4.37 8.5Z"
      />
    </SvgIcon>
  );
};

export default CrashGamesIcon;
