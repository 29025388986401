import { FC } from "react";
import { useUser } from "../../../features/user/useUser";
import { PersonalInfo } from "../../../components/userInfo/personalInfo/PersonalInfo";
import { ChangePassword } from "../../../components/userInfo/changePassword/ChangePassword";
import { Box, Button } from "@mui/material";
import { useUpdateUserInfo } from "../../../features/user/useUpdateUserInfo";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import LogoutIcon from '@mui/icons-material/Logout';
import { useOutletContext } from "react-router-dom";

export const UserInfoPage: FC = () => {
    const { logOut } = useOutletContext<{ logOut: VoidFunction }>();
    const { user } = useUser();
    const { firstname, lastname, email, phone, setFirstname, setLastname, setEmail, setPhone, handleSaveInfo, handleUpdatePassword, emailError, currentPassword, newPassword, retypeNewPassword, setCurrentPassword, setNewPassword, setRetypeNewPassword, passwordError, newPasswordError, retypeNewPasswordError } = useUpdateUserInfo();
    const { xLargeScreen, smallScreen } = useCommonMediaQueries();

    if (!user) return null;

    return (
        <Box
            display='grid'
            gridTemplateColumns='5.5fr minmax(326px, 4.5fr)'
            gap="20px"
            {...(xLargeScreen && {
                gridTemplateColumns: 'auto',
                gridTemplateRows: 'auto auto'
            })}
        >
            <PersonalInfo
                balance={user.user_balance}
                userName={user.user_name}
                firstname={firstname}
                lastname={lastname}
                email={email}
                phone={phone}
                setFirstname={setFirstname}
                setLastname={setLastname}
                setEmail={setEmail}
                setPhone={setPhone}
                handleSaveInfo={handleSaveInfo}
                emailError={emailError}
            />

            {smallScreen &&
                <Button
                    variant="contained"
                    startIcon={<LogoutIcon />}
                    sx={{ background: (theme) => theme.palette.background.paper, color: 'white', border: '1px solid #E4E9F81A' }}
                    onClick={logOut}
                >
                    Logout
                </Button>
            }

            <ChangePassword
                currentPassword={currentPassword!}
                newPassword={newPassword!}
                retypeNewPassword={retypeNewPassword!}
                setCurrentPassword={setCurrentPassword}
                setNewPassword={setNewPassword}
                setRetypeNewPassword={setRetypeNewPassword}
                passwordError={passwordError}
                newPasswordError={newPasswordError}
                retypeNewPasswordError={retypeNewPasswordError}
                handleUpdatePassword={handleUpdatePassword}
            />


        </Box>
    )
}


// <ConfirmationDialog close={close} message={lang.logOutConfirmationMessage} onConfirm={() => {logOut()}}/>