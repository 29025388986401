import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface LegendsIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const LegendsIcon: FC<LegendsIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20.75">
      <path
        fill={fill} // Use the fill prop
        fillOpacity="1.000000"
        fillRule="evenodd"
        d="M10 0C11.46 -0.01 12.92 0.11 14.37 0.34C15.51 0.53 16.08 0.63 16.56 1.22C17.03 1.8 17.01 2.43 16.96 3.69L16.96 3.7C16.78 8.05 15.84 13.48 10.75 13.96L10.75 17.5L12.17 17.5C12.41 17.5 12.63 17.58 12.81 17.72C12.99 17.87 13.11 18.07 13.16 18.3L13.34 19.25L16 19.25C16.19 19.25 16.38 19.32 16.53 19.46C16.67 19.61 16.75 19.8 16.75 20C16.75 20.19 16.67 20.39 16.53 20.53C16.38 20.67 16.19 20.75 16 20.75L4 20.75C3.8 20.75 3.61 20.67 3.46 20.53C3.32 20.39 3.25 20.19 3.25 20C3.25 19.8 3.32 19.61 3.46 19.46C3.61 19.32 3.8 19.25 4 19.25L6.65 19.25L6.83 18.3C6.88 18.07 7 17.87 7.18 17.72C7.36 17.58 7.58 17.5 7.82 17.5L9.25 17.5L9.25 13.96C4.15 13.48 3.21 8.05 3.03 3.7C2.98 2.44 2.96 1.8 3.43 1.22C3.91 0.63 4.48 0.53 5.62 0.34C6.74 0.15 8.21 0 10 0ZM0 6.23L0 6.16C0 5.12 0 4.59 0.28 4.2C0.51 3.88 0.87 3.71 1.52 3.49L1.53 3.54L1.54 3.76C1.6 5.46 1.79 7.45 2.33 9.29L1.54 8.85C0.79 8.43 0.41 8.23 0.2 7.87C0 7.52 0 7.09 0 6.23ZM20 6.23L20 6.16C20 5.11 20 4.59 19.71 4.2C19.48 3.88 19.12 3.71 18.47 3.49L18.46 3.54L18.45 3.76C18.39 5.46 18.2 7.45 17.66 9.29L18.45 8.85C19.2 8.43 19.58 8.23 19.79 7.87C20 7.52 20 7.09 20 6.23Z"
      />
    </SvgIcon>
  );
};

export default LegendsIcon;