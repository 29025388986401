import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { GameType } from "../../../features/play_games/playGamesModels";
import { useScalingWidth } from "../hooks/useAutoSize";
import { GameCard } from "../../gameCard/GameCard";
import { TopGameHolder } from "../../../features/games/game";
import { SectionDivider } from "../../sectionDivider/SectionDivider";

export const TopGamesSection: FC<{
  gameType?: GameType;
  title?: string;
  icon?: JSX.Element;
  link?: string;
}> = ({ title, icon }) => {
  const gap = 1.6;
  const gameCardWidth = useScalingWidth();

  return (
    <Stack gap={2}>
      <SectionDivider
        icon={icon}
        title={title}
        showLeftRightButtons={false}
      />
      <Box overflow={"auto"} display={"flex"} gap={gap}>
        {topGamesListProduction?.map((game) => (
          <GameCard
            game={game}
            width={gameCardWidth}
            key={game.gameID}
          />
        ))}
      </Box>
    </Stack>
  );
};

const topGamesListProduction: TopGameHolder[] = [
  { gamePic: "galaxsys.ninja_crash_vertical.webp", gameID: 3854 },
  { gamePic: "DreamPlay.DualHearts_vertical.webp", gameID: 10751 },
  { gamePic: "BGaming.PlinkoXY_vertical.webp", gameID: 7908 },
  { gamePic: "Tada.ShanghaiBeauty_vertical.webp", gameID: 10278 },
  { gamePic: "Mancala.MioandNekoRock_vertical.webp", gameID: 10446 },
  { gamePic: "AmigoGaming.FruitsandCoins_vertical2.webp", gameID: 8126 },
  { gamePic: "ezugi.spanish_roulette_1.jpg", gameID: 3711 },
  { gamePic: "bgaming.LuckyOak.webp", gameID: 7923 },
];