import { FC } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { useTransactions } from "../../features/transactions/useTransactions";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Stack, Typography } from "@mui/material";
import { Balance } from "../userInfo/personalInfo/Balance";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { getColorForTransactionApproveStatus } from "../../features/transactions/funcs";

export const TransactionsTable: FC = () => {
    const { languagePack: { pack: { transactionsTable } } } = useLanguage()
    const { transactions } = useTransactions()

    return (
        <Stack width='100%' gap={4}>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: 650, borderSpacing: "0 6px",
                        borderCollapse: "separate",
                    }} aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#AEC2FF4D' }}>{transactionsTable.operationTableHeader}</TableCell>
                            <TableCell sx={{ color: '#AEC2FF4D' }}>{transactionsTable.dateTimeTableHeader}</TableCell>
                            <TableCell sx={{ color: '#AEC2FF4D' }}>{transactionsTable.methodTableHeader}</TableCell>
                            <TableCell sx={{ color: '#AEC2FF4D' }}>{transactionsTable.amountTableHeader}</TableCell>
                            <TableCell sx={{ color: '#AEC2FF4D' }}>{transactionsTable.statusTableHeader}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((transaction, index) => (
                            <TableRow
                                key={transaction.transfer_id + '-transfer-' + index}
                            >
                                <TableCell>
                                    <Typography fontWeight='bold' whiteSpace='nowrap' textTransform='capitalize'>
                                        {transaction.transfer_type}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' whiteSpace='nowrap' textTransform='capitalize'>
                                        {transaction.respond_time ?? transaction.request_time}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' whiteSpace='nowrap' textTransform='capitalize'>
                                        {transaction.money_transfer_method_name || transaction.method_name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' whiteSpace='nowrap' textTransform='capitalize'>
                                        <Balance
                                            balance={transaction.transfer_amount}
                                            currency={transaction.transfer_currency!}
                                            hideBackground
                                            border='none'
                                            hideIcon
                                        />
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color={getColorForTransactionApproveStatus(transaction.transfer_status)} whiteSpace='nowrap' textTransform='capitalize'>
                                        {transaction.transfer_status}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        {!transactions?.length &&
                            <TableRow>
                                {new Array(5).fill('').map((_, index) => (
                                    <TableCell key={index}>
                                        <Typography fontWeight='bold'>-</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        }

                    </TableBody>
                </Table>
            </TableContainer>
            {!transactions?.length &&
                <Stack gap={2} width='100%' alignItems='center' >
                    <SentimentVeryDissatisfiedIcon fontSize='large' sx={{ color: '#AEC2FF4D' }} />
                    <Typography fontWeight='bold'>There is no game history here yet</Typography>
                </Stack>
            }
        </Stack>
    )
}

