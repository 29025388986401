import { FC, useState } from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { VioletButton } from "../../violetButton/VioletButton";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { PasswordField, ShowPasswordState } from "./PasswordField";
import { useLanguage } from "../../../features/localisation/useLanguage";

interface ChangePasswordProps {
  currentPassword: string
  newPassword: string
  retypeNewPassword: string
  setCurrentPassword: (newPassword: string) => void
  setNewPassword: (newPassword: string) => void
  setRetypeNewPassword: (newPassword: string) => void
  passwordError: string
  newPasswordError: string
  retypeNewPasswordError: string
  handleUpdatePassword: VoidFunction
}

export const ChangePassword: FC<ChangePasswordProps> = ({
  currentPassword,
  newPassword,
  retypeNewPassword,
  setCurrentPassword,
  setNewPassword,
  setRetypeNewPassword,
  passwordError,
  newPasswordError,
  retypeNewPasswordError,
  handleUpdatePassword
}) => {
  const [showPassword, setShowPassword] = useState<ShowPasswordState>({
    current: false,
    new: false,
    confirm: false,
  });
  const { languagePack: { pack: { userInfoForm, argentina: { dashboard: { userInfo } } }, }, } = useLanguage();

  const { smallScreen } = useCommonMediaQueries();

  return (
    <Stack gap={2}>
      {smallScreen &&
        <Typography
          sx={{
            color: "white",
            fontWeight: "bold",
            position: "relative",
            fontSize: "24px",
          }}
        >
          {userInfo.changePasswordSection}
        </Typography>
      }

      <Box
        sx={{
          display: "flex",
          flexDirection: smallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: smallScreen ? undefined : "center",
          gap: smallScreen ? 0 : "30px",
          padding: smallScreen ? 2 : "40px 40px 30px 40px",
          borderRadius: "20px",
          background:
            "linear-gradient(180deg, rgb(20, 25, 49), rgb(28, 34, 63) 100%)",
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            gap: 4,
            Height: '100%'
          }}
        >
          {!smallScreen &&
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                position: "relative",
                top: "-42px",
                fontSize: "24px",
              }}
            >
              {userInfo.changePasswordSection}
            </Typography>
          }
          <PasswordField
            label={userInfoForm.currentPassFieldLabel}
            placeholder={userInfoForm.password}
            value={currentPassword}
            onChange={(value) => setCurrentPassword(value)}
            showPassword={showPassword.current}
            toggleShowPassword={() => setShowPassword({ ...showPassword, current: !showPassword.current })}
            error={passwordError}
            smallScreen={smallScreen}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <PasswordField
            label={userInfoForm.newPassFieldLabel}
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
            showPassword={showPassword.new}
            toggleShowPassword={() => setShowPassword({ ...showPassword, new: !showPassword.new })}
            error={newPasswordError}
            placeholder={userInfoForm.password}
            smallScreen={smallScreen}
          />

          <PasswordField
            label={userInfoForm.confirmPassFieldLabel}
            value={retypeNewPassword}
            onChange={(value) => setRetypeNewPassword(value)}
            showPassword={showPassword.confirm}
            toggleShowPassword={() => setShowPassword({ ...showPassword, confirm: !showPassword.confirm })}
            error={retypeNewPasswordError}
            placeholder={userInfoForm.password}
            smallScreen={smallScreen}
          />

          <VioletButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleUpdatePassword}
          >
            {userInfoForm.updatePassBTN}
          </VioletButton>
        </Box>
      </Box >
    </Stack>
  );
};
