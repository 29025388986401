import { FC } from "react";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Box, Typography } from "@mui/material";
import { titleTextStyle } from "../general_styles";
import { PageLayout } from "../../../components/pageLayout/PageLayout";

export const PrivacyPolicyPage: FC = () => {
    const { smallScreen, xxSmallScreen, mediumScreen } = useCommonMediaQueries();

    return (
        <PageLayout pageName="Privacy Policy" carouselDisabled>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginBottom: "40px",
                    marginLeft:
                        xxSmallScreen || smallScreen || mediumScreen ? "20px" : "0px",
                }}
            >
                <Typography sx={titleTextStyle}>Privacy Policy</Typography>
            </Box>
        </PageLayout>
    );
};