import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface HomeIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const HomeIcon: FC<HomeIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19.8335">
      <path
        fill={fill} // Use the fill prop
        fillOpacity="1.000000"
        fillRule="evenodd"
        d="M7.42 2.45C8.99 1.46 10.99 1.46 12.56 2.45L18.68 6.27C19.09 6.52 19.61 6.4 19.86 6C20.12 5.6 19.99 5.07 19.59 4.82L13.47 0.99C11.34 -0.34 8.64 -0.34 6.51 0.99L0.4 4.81C0 5.06 -0.13 5.59 0.13 5.99C0.38 6.39 0.91 6.52 1.31 6.26L7.42 2.45ZM1.71 8.77C1.36 9.01 1.07 9.32 0.87 9.7C0.67 10.07 0.57 10.49 0.57 10.91L0.57 15.26C0.57 17.78 2.61 19.83 5.14 19.83L6.57 19.83L6.57 14.69C6.57 14 6.84 13.35 7.32 12.87C7.8 12.39 8.46 12.11 9.14 12.11L10.85 12.11C11.53 12.11 12.19 12.39 12.67 12.87C13.15 13.35 13.42 14 13.42 14.69L13.42 19.83L14.85 19.83C17.38 19.83 19.42 17.78 19.42 15.26L19.42 10.91C19.42 10.49 19.32 10.07 19.12 9.7C18.92 9.32 18.63 9.01 18.28 8.77L11.89 4.52C10.74 3.76 9.25 3.76 8.1 4.52L1.71 8.77Z"
      />
    </SvgIcon>
  );
};

export default HomeIcon;
