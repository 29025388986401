import { FC } from "react";
import { Stack } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { useScalingWidth } from "../hooks/useAutoSize";
import { GameCard } from "../../gameCard/GameCard";

interface ShortGamesListProps {
  games: GameObj[];
}

export const ShortGamesList: FC<ShortGamesListProps> = ({
  games
}) => {
  const { smallScreen } = useCommonMediaQueries();
  const width = useScalingWidth();

  return (
    <Stack
      py={"5px"}
      direction={"row"}
      sx={{
        overflowX: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#15181D #15181D60",
        userSelect: "none",
        marginTop: "24px", // Add top margin of 24px
      }}
      gap={smallScreen ? "10px" : "1vw"}
      justifyContent={
        smallScreen || games.length < 9 ? "start" : "space-between"
      }
    >
      {games.map((game) => (
        <GameCard
          key={game.internal_id}
          game={game}
          width={width}
        />
      ))}
    </Stack>
  );
};
