import React from 'react';
import { Button, styled } from '@mui/material';

const VioletButtonStyled = styled(Button)({
  boxSizing: 'border-box',
  borderRadius: '10px',
  background: 'linear-gradient(180deg, #B431AD, #D43DCC 100%)',
  height: '44px', // Fixed height
  display: 'inline-flex', // Ensure the button only takes the width of its content
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 20px', // Add padding to ensure spacing around the text
  gap: '10px',
  color: 'white', // Default text color
  textTransform: 'none', // Disable uppercase transformation
  whiteSpace: 'nowrap', // Prevent text from wrapping into two lines
  width: 'auto',
  '&:hover': {
    background: 'linear-gradient(180deg, #D43DCC, #B431AD 100%)', // Optional: hover effect
  },
});

interface VioletButtonProps {
  children: React.ReactNode; // Button text or content
  textStyle?: React.CSSProperties; // Optional prop for custom text styling
  sx?: any; // Add sx prop to accept custom styles
  onClick?: () => void; // Add onClick prop to handle click events
  endIcon?: React.ReactNode; // Add endIcon prop to handle end icons
  disabled?: boolean; // Add disabled prop to handle disabled state
  variant?: 'text' | 'outlined' | 'contained'; // Add variant prop to handle button variants
}

export const VioletButton: React.FC<VioletButtonProps> = ({ children, textStyle, sx, onClick, endIcon, disabled, variant, ...props }) => {
  return (
    <VioletButtonStyled variant={variant} sx={sx} onClick={onClick} disabled={disabled} endIcon={endIcon} {...props}>
      <span style={textStyle}>{children}</span>
    </VioletButtonStyled>
  );
};