import { FC, MouseEvent } from "react";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface DirectionalIconButtonProps {
  direction: "left" | "right";
  url?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const DirectionalIconButton: FC<DirectionalIconButtonProps> = ({
  direction,
  url,
  disabled,
  onClick,
}) => {
  const { xxSmallScreen } = useCommonMediaQueries();

  const iconButton = (
    <IconButton
      color="primary"
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: xxSmallScreen ? '36px' : "44px",
        height: xxSmallScreen ? '36px' : "44px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#3A3F65",
        borderRadius: "12px",
      }}
    >
      {direction === "right" ? (
        <ChevronRightIcon sx={{ fontSize: 24, color: "white" }} />
      ) : (
        <ChevronLeftIcon sx={{ fontSize: 24, color: "white" }} />
      )}
    </IconButton>
  );

  return (
    <Box alignSelf={"end"}>
      {url ? <Link to={url}>{iconButton}</Link> : iconButton}
    </Box>
  );
};

export default DirectionalIconButton;