import { createTheme } from "@mui/material/styles";
import { alpha, getContrastRatio } from "@mui/material";

declare module "@mui/material/styles" {
    interface Palette {
        surface: Palette["primary"];
    }

    interface PaletteOptions {
        surface?: PaletteOptions["primary"];
    }
}

export const theme = createTheme({
    typography: {
        fontFamily: "Inter",
    },
    palette: {
        background: {
            default: "#15120c",
            paper: "#1C223F",
        },
        mode: "dark",
        primary: {
            light: "#F7E8D3",
            main: "rgb(228, 233, 248)", // Updated main color
            dark: "#b431ad",
            contrastText: "#18171F",
        },
        surface: {
            main: "#2a1d27",
            light: alpha("#2a1d27", 0.5),
            dark: alpha("#2a1d27", 0.9),
            contrastText: getContrastRatio("#fff", "#22242A") > 4.5 ? "#111" : "#fff",
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    border: `1px solid ${alpha("#fff", 0.07)}`,
                    backgroundImage: "none",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    borderWidth: "2px",
                    "&:hover": {
                        borderWidth: "2px",
                    },
                },
                outlined: ({ theme }) =>
                    theme.unstable_sx({
                        color: "white",
                        borderColor: theme.palette.primary.main,
                        "&:hover": {
                            borderColor: theme.palette.primary.dark,
                        },
                    }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) =>
                    theme.unstable_sx({
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "12px",
                            "&:hover fieldset": {
                                borderColor: theme.palette.primary.dark,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: theme.palette.primary.main,
                            },
                        },
                    }),
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: "#1C223F",
                    borderRadius: 20,
                    width: "fit-content",
                },
                grouped: {
                    margin: "4px",
                    border: "none",
                    borderRadius: "16px !important",
                    "&.Mui-selected": {
                        background: "linear-gradient(180deg, #B431AD 0%, #D43DCC 100%)",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: "transparent",
                    color: "#9E9E9E",
                },
                body: {
                    fontSize: 14,
                },
                root: {
                    border: "none",
                    "&:first-of-type": {
                        borderTopLeftRadius: "25px !important",
                        borderBottomLeftRadius: "25px !important",
                        paddingLeft: "30px",
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: "25px !important",
                        borderBottomRightRadius: "25px !important",
                        paddingRight: "30px",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "& td": {
                        backgroundColor: "#1C223F",
                    },
                    border: "none",
                    "& .MuiTableCell-root": {
                        border: "none",
                        marginTop: "6px",
                    },
                },
            },
        },
    },
});
