import React, { FC, useState } from "react";
import { Dialog, DialogContent, IconButton, InputAdornment, Slide, Stack, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import backgroundImage from './../../../assets/login_modal_desktop.svg'
import CloseIcon from '@mui/icons-material/Close';
import { VioletButton } from "../violetButton/VioletButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUser } from "../../features/user/useUser";
import { useLanguage } from "../../features/localisation/useLanguage";

interface LoginWindowProps {
  close?: () => void;
}

export const LoginModal: FC<LoginWindowProps> = ({ close }) => {
  const { xxSmallScreen, mediumScreen } = useCommonMediaQueries();
  const [opened, setOpened] = useState(true);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const { logIn, isLogInLoading } = useUser();
  const {
    languagePack: {
      pack: { loginForm: lang },
    },
  } = useLanguage();

  const pendingClose = () => {
    setOpened(false);
    if (close) {
      setTimeout(() => close(), 500);
    }
  };

  return (
    <Dialog
      open={opened}
      onClose={pendingClose}
      TransitionComponent={Transition}
      maxWidth={false}
      sx={xxSmallScreen ? {
        ".MuiPaper-root": {
          margin: 0,
          maxWidth: '95%'
        }
      } : {}}
    >
      <DialogContent
        sx={{
          background: "radial-gradient(117.03% 100% at 51.3% 100%, #141931 0%, #0F1328 56.5%, #05091E 100%)",
          height: '500px',
          minWidth: '750px',
          ...(mediumScreen && {
            height: '340px',
            minWidth: '300px'
          }),
          padding: 0,

        }}
      >
        <Stack direction='row' height='100%'>
          {!mediumScreen && <img src={backgroundImage} height='100%' alt='Illustration' />}
          <Stack
            width='100%'
            p={xxSmallScreen ? '16px' : mediumScreen ? '24px' : '50px 50px 50px 0'}
            gap='20px'
          >
            <CloseIcon onClick={pendingClose} sx={{ position: 'absolute', top: 20, right: 20, height: 20, width: 20, cursor: 'pointer', color: '#AEC2FF4D' }} />

            <Typography variant={mediumScreen ? "h6" : "h5"} fontWeight='bold' color="transparent" sx={{ background: 'linear-gradient(180deg, #E4E9F8 6.9%, #8B98BE 98.28%)', '-webkit-background-clip': 'text' }}>Authorization</Typography>

            <Stack>
              <Typography variant='caption' fontWeight='bold' gutterBottom color='#E4E9F8'>{lang.usernameFieldLabel}</Typography>
              <TextField
                variant="outlined"
                inputProps={{ sx: { p: '8.5px 14px' } }}
                placeholder={lang.usernameFieldLabel}
                InputProps={{
                  sx: {
                    background: 'linear-gradient(180deg, #141931 0%, #1C223F 100%)',
                  }
                }}
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </Stack>
            <Stack>
              <Typography variant='caption' fontWeight='bold' gutterBottom color='#E4E9F8'>{lang.passwordFieldLabel}</Typography>
              <TextField
                variant="outlined"
                inputProps={{
                  sx: { p: '8.5px 14px' },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        disableRipple
                        sx={{ color: '#AEC2FF4D' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  type: showPassword ? 'text' : 'password',
                  sx: {
                    background: 'linear-gradient(180deg, #141931 0%, #1C223F 100%)',
                  }
                }}
                placeholder={lang.passwordFieldLabel}
                sx={{
                  overflow: 'hidden'
                }}
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Stack>

            <VioletButton
              sx={{ mt: 'auto' }}
              onClick={() => {
                if (isLogInLoading) return;
                logIn(username, password, () => setOpened(false))
              }}
            >
              {lang.loginBTN}
            </VioletButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog >
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
