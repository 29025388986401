import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface CasinoIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop to customize the color
}

const CasinoIcon: FC<CasinoIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 13.1831">
      <path
        fill={fill} // Use the fill prop
        fillOpacity="1"
        fillRule="evenodd"
        d="M11.83 6.39C11.07 7.86 10.67 9.49 10.66 11.14L10.66 12.19L6.73 12.19C6.74 10.55 7.09 8.93 7.77 7.45C8.49 5.85 9.43 4.37 10.58 3.04L4.75 3.04L4.75 0L15 0L15 1.61C13.78 3.09 12.72 4.7 11.83 6.39ZM17.33 9.78C16.7 10.57 16.27 11.5 16.08 12.5L15.95 13.12L12.5 12.49C12.85 10.66 13.84 9.02 15.28 7.85L12.78 7.35L13.83 5.14L20 6.35L19.8 7.32C18.9 8.06 18.07 8.88 17.33 9.78ZM5.14 11.72C4.9 10.74 4.9 9.71 5.14 8.73C5.42 7.59 5.83 6.48 6.34 5.43L6.11 4.47L0 5.94L0 8.37L2.5 7.78C1.66 9.45 1.45 11.36 1.9 13.18L5.29 12.35L5.14 11.72Z"
      />
    </SvgIcon>
  );
};

export default CasinoIcon;