import { FC, ReactNode } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {
  titleTextStyle,
  subtitleTextStyle,
  contentTextStyle,
} from "./../general_styles";
import { VioletButton } from "../../../components/violetButton/VioletButton";
import BecomeAnAgentIcon from "../../../../assets/svg/BecomeAnAgentIcon";
import { Logo } from "../../../components/logo/Logo";
import { PageLayout } from "../../../components/pageLayout/PageLayout";

interface ListItemWithMarkerProps {
  text: string | JSX.Element;
}

const ListItemWithMarker: FC<ListItemWithMarkerProps> = ({ text }) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: "auto", marginRight: "10px" }}>
        <CircleIcon
          sx={{ fontSize: "12px", color: "rgba(228, 233, 248, 0.5)" }}
        />
      </ListItemIcon>
      {text}
    </ListItem>
  );
};

interface ListItemWithNumberProps {
  text: ReactNode;
  index: number; // Add an index prop for numbering
}

const ListItemWithNumber: FC<ListItemWithNumberProps> = ({ text, index }) => {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: "auto", marginRight: "10px" }}>
        {/* Display the number instead of a circle icon */}
        <span style={{ fontSize: "14px", color: "rgba(228, 233, 248, 0.5)" }}>
          {index + 1}.
        </span>
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export const BecomeAnAgentPage: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: {
          becomeAnAgent: { content },
        },
      },
    },
  } = useLanguage();

  const { smallScreen, xxSmallScreen, mediumScreen } = useCommonMediaQueries();

  return (
    <PageLayout pageName="Become an Agent" carouselDisabled>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginBottom: "40px",
          marginLeft:
            xxSmallScreen || smallScreen || mediumScreen ? "20px" : "0px",
        }}
      >
        <Typography sx={titleTextStyle}>{content[0]}</Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ zoom: xxSmallScreen || smallScreen ? 2 : 4 }}>
            <Logo />
          </Box>
        </Box>
        <Typography sx={contentTextStyle}>{content[1]}</Typography>
        <Typography sx={subtitleTextStyle}>{content[2]}</Typography>
        <Typography sx={contentTextStyle}>{content[3]}</Typography>
        <List>
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[4]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[5]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[6]}</Typography>}
          />
          <ListItemWithMarker
            text={<Typography sx={contentTextStyle}>{content[7]}</Typography>}
          />
        </List>
        <Typography sx={subtitleTextStyle}>{content[8]}</Typography>
        <Typography sx={contentTextStyle}>{content[9]}</Typography>
        <Typography sx={subtitleTextStyle}>{content[10]}</Typography>
        <List>
          <ListItemWithNumber
            text={<Typography sx={contentTextStyle}>{content[11]}</Typography>}
            index={0}
          />
          <ListItemWithNumber
            text={<Typography sx={contentTextStyle}>{content[12]}</Typography>}
            index={1}
          />
          <ListItemWithNumber
            text={<Typography sx={contentTextStyle}>{content[13]}</Typography>}
            index={2}
          />
          <ListItemWithNumber
            text={<Typography sx={contentTextStyle}>{content[14]}</Typography>}
            index={3}
          />
        </List>
        <Typography sx={subtitleTextStyle}>{content[15]}</Typography>
        <Typography sx={contentTextStyle}>{content[16]}</Typography>
        <Typography sx={subtitleTextStyle}>{content[17]}</Typography>
        <Typography sx={contentTextStyle}>{content[18]}</Typography>
        <Typography sx={subtitleTextStyle}>{content[19]}</Typography>
        <Typography sx={contentTextStyle}>{content[20]}</Typography>

        <Box
          sx={{
            display: "inline-flex",
            alignSelf: xxSmallScreen || smallScreen ? "center" : "flex-start", // Center on small screens, otherwise align to the start
            width: xxSmallScreen || smallScreen ? "100%" : "auto", // Ensure the Box takes full width on small screens
            justifyContent:
              xxSmallScreen || smallScreen ? "center" : "flex-start", // Center the button within the Box on small screens
          }}
        >
          <VioletButton
            textStyle={{
              fontWeight: 600,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <BecomeAnAgentIcon fontSize="small" /> {/* Adjust icon size */}
              <span>{content[0]}</span>
            </Box>
          </VioletButton>
        </Box>
      </Box>
    </PageLayout>
  );
};
