import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FC, useState } from "react";
import { useUser } from "../../features/user/useUser";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Stack, Typography } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { getMediaCDN } from "../../config";
import { Balance } from "../userInfo/personalInfo/Balance";
import { useTopWins } from "../../features/top_wins/useTopWins";
import iconFirstPlace from './../../../assets/salta-la-banca/legends/legend_first_place.png';
import iconSecondPlace from './../../../assets/salta-la-banca/legends/legend_second_place.png';
import iconThirdPlace from './../../../assets/salta-la-banca/legends/legend_third_place.png';
import { InfoPopup } from "./InfoPopup";
import { TopWinGame } from "../../features/top_wins/models/model";

interface GameHistoryTableProps {
    legends: boolean
}

export const TopWinsTable: FC<GameHistoryTableProps> = ({
    legends
}) => {
    const { topWins } = useTopWins();
    const [gameToShowDetails, setGameToShowDetails] = useState<TopWinGame | null>(null);
    // const [category, setCategory] = useState<0 | 1 | 2>(0);
    const {
        languagePack: {
            pack: {
                gamesHistory,
            },
        },
    } = useLanguage();
    const { user } = useUser();
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries();
    const cdn = getMediaCDN();

    if (!user) return null;

    const icons = [iconFirstPlace, iconSecondPlace, iconThirdPlace];

    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: smallScreen ? 'unset' : 700,
                        borderSpacing: "0 6px",
                        borderCollapse: "separate",
                    }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#AEC2FF4D' }} >
                                <Typography fontWeight='bold'>
                                    {legends ? '#' : gamesHistory.gameTableHeader}
                                </Typography>
                            </TableCell>
                            {!smallScreen &&
                                <TableCell sx={{ color: '#AEC2FF4D' }}>
                                    <Typography fontWeight='bold'>
                                        {gamesHistory.userTableHeader}
                                    </Typography>
                                </TableCell>
                            }
                            {!xxSmallScreen &&
                                <TableCell sx={{ color: '#AEC2FF4D' }}>
                                    <Typography fontWeight='bold'>
                                        {gamesHistory.multiplierTableHeader}
                                    </Typography>
                                </TableCell>
                            }
                            {!smallScreen &&
                                <TableCell sx={{ color: '#AEC2FF4D' }}>
                                    <Typography fontWeight='bold'>
                                        {gamesHistory.betAmountTableHeader}
                                    </Typography>
                                </TableCell>
                            }

                            <TableCell sx={{ color: '#AEC2FF4D' }}>
                                <Typography fontWeight='bold' marginLeft={smallScreen ? '14px' : ''}>
                                    {gamesHistory.payoutTableHeader}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topWins?.map((game, index) => (
                            <TableRow key={game.uuid} onClick={() => setGameToShowDetails(game)} sx={{ cursor: 'pointer' }}>
                                <TableCell sx={smallScreen ? { p: '12px 0 12px 14px !important' } : {}}>
                                    {legends ?
                                        icons[index] ?
                                            <img src={icons[index]} height='24px' width='66px' alt='Cup Icon' /> :
                                            <Typography fontWeight='bold'>
                                                {index + 1}
                                            </Typography>
                                        :
                                        <Stack direction='row' gap={1.5} alignItems='center' whiteSpace='nowrap'>
                                            <img src={`${cdn}/game_img/sqr_mid/${game.picUrl}`} width='30' style={{ borderRadius: 6 }} alt={game.game_name} />
                                            <Typography fontWeight='bold'>
                                                {game.game_name}
                                            </Typography>
                                        </Stack>
                                    }
                                </TableCell>
                                {!smallScreen &&
                                    <TableCell>
                                        <Typography fontWeight='bold' whiteSpace='nowrap'>
                                            {game.user_name}
                                        </Typography>
                                    </TableCell>
                                }
                                {!xxSmallScreen &&
                                    <TableCell>
                                        <Typography fontWeight='bold' whiteSpace='nowrap'>
                                            x{game.multiplier}
                                        </Typography>
                                    </TableCell>
                                }
                                {!smallScreen &&
                                    <TableCell>
                                        <Balance
                                            balance={game.withdraw_sum!}
                                            currency={user.user_currency!}
                                            hideBackground
                                            border='none'
                                        />
                                    </TableCell>

                                }
                                <TableCell sx={smallScreen ? { p: '12px 8px 12px !important' } : {}}>
                                    <Balance
                                        balance={game.deposit_sum!}
                                        background='#282F4F'
                                        border='none'
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <InfoPopup game={gameToShowDetails} close={() => setGameToShowDetails(null)} />
        </>
    );
};