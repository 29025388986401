import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { OverlaySpinner } from "../loaders/OverlaySpinner";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Menu } from "../menu/Menu";
import { Header } from "../header/Header";

import { BottomMenu } from "../menu/bottomMenu/BottomMenu";
import { Carousel } from "../carousel/Carousel";
import { Footer } from "../footer/Footer";

interface PageLayoutProps {
    children: ReactNode;
    pageName?: string;
    carouselDisabled?: boolean;
    footerDisabled?: boolean;
    flexGrowContentDisabled?: boolean;
    mobileBottomMenuDisabled?: boolean;
}

export const PageLayout: FC<PageLayoutProps> = ({
    children,
    carouselDisabled = false,
    footerDisabled = false,
    flexGrowContentDisabled = false,
    mobileBottomMenuDisabled = false,
    pageName
}) => {
    const { isGameSessionLoading, isOutcomeGameLoading } =
        usePlayGames();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { largeScreen, xxSmallScreen } = useCommonMediaQueries();
    const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

    useEffect(() => {
        if (!pageName) return;

        const siteName = process.env.REACT_APP_SITE_NAME ?? "Casino"
        const title = document.head.getElementsByTagName('title')[0];
        title.innerText = pageName ? `${siteName} | ${pageName}` : siteName;

        return () => {
            document.title = siteName;
        };
    }, [pageName]);

    const horizontalPadding = xxSmallScreen ? 1.6 : 3.2;

    return (
        <Stack
            sx={{
                background: "rgb(5, 9, 30)",
                height: "100dvh",
            }}
        >
            <Header />
            <Box
                display="flex"
                flexGrow={1}
                overflow="hidden"
                pt={2}
                pl={horizontalPadding}
                gap={7}
            >
                <Menu opened={drawerOpened} onClose={setDrawerOpened} />

                <Stack
                    ref={scrollContainerRef}
                    flexGrow={1}
                    pr={horizontalPadding}
                    sx={{
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#15181D #15181D60",
                    }}
                >
                    <Stack position={"relative"} minHeight={0} height={"100%"}>
                        {!carouselDisabled && <Carousel />}


                        <Box height='fit-content'>
                            {children}
                        </Box>

                        {!flexGrowContentDisabled && <Box flexGrow={1} />}
                        {!footerDisabled && (
                            <Footer scrollContainerRef={scrollContainerRef} />
                        )}
                    </Stack>
                    {(isOutcomeGameLoading || isGameSessionLoading) && <OverlaySpinner />}
                </Stack>
            </Box>

            {largeScreen && !mobileBottomMenuDisabled && (
                <BottomMenu
                    requestSwitchDrawer={() => setDrawerOpened((prevState) => !prevState)}
                />
            )}

        </Stack>
    );
};
