import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface LiveCasinoIconProps extends SvgIconProps {
  fill?: string; // Add a fill prop
}

const LiveFeedIcon: FC<LiveCasinoIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9146 5.71069C12.3272 5.39046 11.6689 5.22267 10.9999 5.22267C10.3309 5.22267 9.67262 5.39046 9.08524 5.71069L2.69191 9.19602C2.0611 9.54024 1.53467 10.048 1.16795 10.666C0.801237 11.284 0.607777 11.9894 0.60791 12.708V19.292C0.607777 20.0106 0.801237 20.716 1.16795 21.334C1.53467 21.952 2.0611 22.4598 2.69191 22.804L9.08524 26.2894C9.67262 26.6096 10.3309 26.7774 10.9999 26.7774C11.6689 26.7774 12.3272 26.6096 12.9146 26.2894L19.3066 22.804C19.9376 22.46 20.4643 21.9523 20.8313 21.3342C21.1983 20.7162 21.3919 20.0108 21.3919 19.292V12.7067C21.3919 11.9879 21.1983 11.2825 20.8313 10.6645C20.4643 10.0464 19.9376 9.53873 19.3066 9.19469L12.9146 5.71069ZM10.9999 12C10.6212 12 10.3679 12.4534 9.86124 13.364L9.73058 13.5987C9.58658 13.8574 9.51458 13.9854 9.40258 14.0707C9.28924 14.156 9.14924 14.188 8.86924 14.2507L8.61591 14.3094C7.63191 14.532 7.13991 14.6427 7.02258 15.0187C6.90524 15.3947 7.24124 15.788 7.91191 16.572L8.08524 16.7747C8.27591 16.9974 8.37191 17.108 8.41458 17.2467C8.45724 17.3854 8.44258 17.5334 8.41458 17.8307L8.38791 18.1014C8.28658 19.148 8.23591 19.672 8.54124 19.904C8.84791 20.136 9.30924 19.924 10.2306 19.5L10.4679 19.3907C10.7306 19.2707 10.8612 19.2107 10.9999 19.2107C11.1386 19.2107 11.2692 19.2707 11.5319 19.3907L11.7692 19.5C12.6906 19.9254 13.1519 20.136 13.4586 19.904C13.7652 19.672 13.7132 19.148 13.6119 18.1014L13.5852 17.8307C13.5572 17.5334 13.5426 17.3854 13.5852 17.2467C13.6279 17.108 13.7239 16.9974 13.9146 16.7747L14.0879 16.572C14.7586 15.788 15.0946 15.396 14.9772 15.0187C14.8599 14.6427 14.3679 14.532 13.3839 14.3094L13.1306 14.2507C12.8506 14.188 12.7106 14.1574 12.5972 14.0707C12.4852 13.9854 12.4132 13.8574 12.2692 13.5987L12.1386 13.364C11.6319 12.4547 11.3786 12 10.9999 12Z" fill="#B431AD" />
        <path d="M9.66658 0.666687H12.3332C14.8479 0.666687 16.1039 0.666687 16.8852 1.44802C17.6666 2.22935 17.6666 3.48535 17.6666 6.00002V6.02402L13.8719 3.95469C12.9909 3.47435 12.0034 3.22267 10.9999 3.22267C9.99642 3.22267 9.00897 3.47435 8.12791 3.95469L4.33324 6.02402V6.00002C4.33324 3.48535 4.33324 2.22935 5.11458 1.44802C5.89591 0.666687 7.15191 0.666687 9.66658 0.666687Z" fill="#B431AD" />
      </svg>
    </SvgIcon>
  );
};

export default LiveFeedIcon;
