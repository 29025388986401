import {
    GetGamesResponse,
    iUsePlayGames,
    OpenSportsBookResponse,
    PlayGamesLoader,
    ShowProvidersResponse,
    SportType,
    StartGameSessionResponse,
} from "../../../app/features/play_games/playGamesModels";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { useLanguage } from "../../../app/features/localisation/useLanguage";
import { useCallback } from "react";
import {
    setGameSession,
    setOutcomeGames,
    setPlayGamesLoader,
    setProvidersOutcome,
    setSelectedProviderName,
    setSportUrl,
} from "../../../app/features/play_games/playGamesSlice";
import axios, { AxiosError } from "axios";
import Config, { getApiHost } from "../../../app/config";
import LoaderService, { ApiRequest } from "../../components/games/hooks/loaderService";
import { getDeviceType } from "../common_funcs";

export const usePlayGames = (): iUsePlayGames => {
    const {
        setLocalizedError,
        handleNetworkErrors,
        languagePack: { langShort },
    } = useLanguage();
    const token = useAppSelector((state) => state.user.token ?? null);
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.playGames);
    const apiURL = getApiHost();

    const getProviders = useCallback(
        (onSuccess: () => void = () => {}) => {
            if (apiURL) {
                dispatch(
                    setPlayGamesLoader({
                        loader: PlayGamesLoader.isProvidersOutcomeLoading,
                        isLoading: true,
                    })
                );

                axios.create({ ...Config.axiosConfig });

                const data = new FormData();
                data.append("action", "ShowProviders");
                if (token) {
                    data.append("token", token);
                }

                axios
                    .post<ShowProvidersResponse>(apiURL, data)
                    .then((response) => {
                        const { success, error, providers } = response.data;
                        if (success && providers !== undefined) {
                            dispatch(setProvidersOutcome(providers));
                            onSuccess();
                        }
                        if (error) {
                            if (error.code === 2) {
                                dispatch(setProvidersOutcome([]));
                            } else {
                                setLocalizedError(error);
                            }
                        }
                    })
                    .catch((error: Error | AxiosError) => {
                        dispatch(setProvidersOutcome([]));
                        handleNetworkErrors(error);
                    })
                    .finally(() => {
                        dispatch(
                            setPlayGamesLoader({
                                loader: PlayGamesLoader.isProvidersOutcomeLoading,
                                isLoading: false,
                            })
                        );
                    });
            }
        },
        [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token]
    );

    const getGames = useCallback(
        (providerName?: string) => {
            if (!LoaderService.isLoading(ApiRequest.GetGames)) {
                if (providerName !== state.selectedProvider) {
                    dispatch(setSelectedProviderName(providerName ?? null));
                }
                if (apiURL) {
                    LoaderService.setLoading(ApiRequest.GetGames, true);
                    dispatch(
                        setPlayGamesLoader({
                            loader: PlayGamesLoader.isOutcomeGameLoading,
                            isLoading: true,
                        })
                    );

                    axios.create({ ...Config.axiosConfig });

                    const data = new FormData();
                    data.append("action", ApiRequest.GetGames);
                    if (providerName) {
                        data.append("provider", providerName);
                    }
                    if (token) {
                        data.append("token", token);
                    }
                    data.append("format", "vertical");

                    axios
                        .post<GetGamesResponse>(apiURL, data)
                        .then((response) => {
                            const { success, error, games } = response.data;
                            if (success && games) dispatch(setOutcomeGames(games));
                            if (error) {
                                setLocalizedError(error);
                            }
                        })
                        .catch((error: Error | AxiosError) => {
                            dispatch(setOutcomeGames([]));
                            handleNetworkErrors(error);
                        })
                        .finally(() => {
                            LoaderService.finishLoading(ApiRequest.GetGames);
                            dispatch(
                                setPlayGamesLoader({
                                    loader: PlayGamesLoader.isOutcomeGameLoading,
                                    isLoading: false,
                                })
                            );
                        });
                }
            }
        },
        [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.selectedProvider, token]
    );

    const startGameSession = useCallback(
        (gameId: number) => {
            if (token && apiURL) {
                dispatch(
                    setPlayGamesLoader({
                        loader: PlayGamesLoader.isGameSessionLoading,
                        isLoading: true,
                    })
                );

                axios.create({ ...Config.axiosConfig });

                const data = new FormData();
                data.append("action", "StartSession");
                data.append("token", token);
                data.append("game", gameId.toString());
                data.append("language", langShort);
                data.append("device", getDeviceType());

                axios
                    .post<StartGameSessionResponse>(apiURL, data)
                    .then((response) => {
                        const { success, error, SessionId, SessionUrl } = response.data;
                        if (success) {
                            // if (SessionId) {
                            dispatch(
                                setGameSession({
                                    SessionId: SessionId ?? null,
                                    SessionUrl: SessionUrl ?? null,
                                })
                            );
                            // }
                        }
                        if (error) {
                            setLocalizedError(error);
                        }
                    })
                    .catch(handleNetworkErrors)
                    .finally(() => {
                        dispatch(
                            setPlayGamesLoader({
                                loader: PlayGamesLoader.isGameSessionLoading,
                                isLoading: false,
                            })
                        );
                    });
            }
        },
        [apiURL, dispatch, handleNetworkErrors, langShort, setLocalizedError, token]
    );

    const openSportsBook = useCallback(
        (extension?: SportType) => {
            if (apiURL) {
                dispatch(
                    setPlayGamesLoader({
                        loader: PlayGamesLoader.isSportsBookLoading,
                        isLoading: true,
                    })
                );

                axios.create({ ...Config.axiosConfig });

                const data = new FormData();
                data.append("action", "OpenSportsBook");
                if (token) data.append("token", token);
                if (extension) {
                    // Check if `extension` is provided
                    data.append("extension", extension);
                }
                data.append("lang", langShort);

                axios
                    .post<OpenSportsBookResponse>(apiURL, data)
                    .then((response) => {
                        const { success, error, url } = response.data;
                        if (success && url) {
                            dispatch(setSportUrl(url));
                        }
                        if (error) {
                            setLocalizedError(error);
                        }
                    })
                    .catch(handleNetworkErrors)
                    .finally(() => {
                        dispatch(
                            setPlayGamesLoader({
                                loader: PlayGamesLoader.isSportsBookLoading,
                                isLoading: false,
                            })
                        );
                    });
            }
        },
        [apiURL, dispatch, handleNetworkErrors, langShort, setLocalizedError, token]
    );

    return { ...state, getProviders, getGames, startGameSession, openSportsBook };
};
