import { FC } from "react";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { CarouselSlide } from "../../components/carousel/CarouselSlide";
import HallOfFrame from "../../../assets/salta-la-banca/carousel-slides-redesign/hall_of_frame.png";
import { TopWins } from "../../components/topWins/TopWins";
import { Stack } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const LegendsPage: FC = () => {
  const { mediumScreen } = useCommonMediaQueries();

  return (
    <PageLayout pageName="Hall of Fame" carouselDisabled>
      <Stack gap={mediumScreen ? 2 : 4}>
        <CarouselSlide bgImage={HallOfFrame} />
        <TopWins legends showDivider={false} />
      </Stack>
    </PageLayout>
  );
};