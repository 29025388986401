import { FC } from "react";
import { Box } from "@mui/material";
import logo from './../../../assets/salta-la-banca/Logo.webp'
import logo2x from './../../../assets/salta-la-banca/Logo@2x.webp'
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface LogoProps {
    height?: number;
    width?: number
}

export const Logo: FC<LogoProps> = ({ height, width }) => {
    const { xxSmallScreen, smallScreen } = useCommonMediaQueries()

    return <Box
        width={width || (xxSmallScreen ? '110px' : smallScreen ? '150px' : '196px')}
        height={height || (xxSmallScreen || smallScreen ? '32px' : '40px')}
        sx={{
            backgroundImage: `url(${xxSmallScreen ? logo : logo2x})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
        }}
    />
}