import { FC } from "react";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { SideMenu } from "./sideMenu/SideMenu";
import { MobileDrawer } from "./mobileDrawer/MobileDrawer";

interface MenuProps {
    opened: boolean | null;
    onClose: (opened: boolean) => void;
}

export const Menu: FC<MenuProps> = (props) => {
    const { largeScreen } = useCommonMediaQueries();

    if (!largeScreen) return <SideMenu />

    return <MobileDrawer {...props} opened={props.opened!} />
}