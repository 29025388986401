import { FC } from "react";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Box, Typography } from "@mui/material";
import { contentTextStyle, subtitleTextStyle, titleTextStyle } from "../general_styles";
import { PageLayout } from "../../../components/pageLayout/PageLayout";

export const TermsAndConditionsPage: FC = () => {
    const {
        languagePack: {
            pack: {
                argentina: {
                    termsAndConditions: { content, email },
                },
            },
        },
    } = useLanguage();

    const { smallScreen, xxSmallScreen, mediumScreen } = useCommonMediaQueries();


    return (
        <PageLayout pageName="Terms and Conditions" carouselDisabled>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    marginBottom: "40px",
                    marginLeft:
                        xxSmallScreen || smallScreen || mediumScreen ? "20px" : "0px",
                }}
            >
                <Typography sx={titleTextStyle}>{content[0]}</Typography>
                <Typography sx={contentTextStyle}>{content[1]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[2]}</Typography>
                <Typography sx={contentTextStyle}>{content[3]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[4]}</Typography>
                <Typography sx={contentTextStyle}>{content[5]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[6]}</Typography>
                <Typography sx={contentTextStyle}>{content[7]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[8]}</Typography>
                <Typography sx={contentTextStyle}>{content[9]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[10]}</Typography>
                <Typography sx={contentTextStyle}>{content[11]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[12]}</Typography>
                <Typography sx={contentTextStyle}>{content[13]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[14]}</Typography>
                <Typography sx={contentTextStyle}>{content[15]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[16]}</Typography>
                <Typography sx={contentTextStyle}>{content[17]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[18]}</Typography>
                <Typography sx={contentTextStyle}>{content[19]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[20]}</Typography>
                <Typography sx={contentTextStyle}>{content[21]}</Typography>
                <Typography sx={subtitleTextStyle}>{content[22]}</Typography>
                <Typography sx={contentTextStyle}>{content[23]}</Typography>
                <Typography
                    sx={contentTextStyle}
                    dangerouslySetInnerHTML={{ __html: email }}
                />
            </Box>
        </PageLayout>
    );
};