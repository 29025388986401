import { FC } from "react";
import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface PageSizeSwitcherProps { }

export const PageSizeSwitcher: FC<PageSizeSwitcherProps> = () => {
    const { languagePack: { pack: { common: lang } } } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { smallScreen } = useCommonMediaQueries();

    const handleSelect = (event: SelectChangeEvent<string>) => {
        searchParams.set('pageSize', event.target.value);
        searchParams.set('page', '1');

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    const sizes = [
        '10', '20', '30', '40'
    ];

    return (
        <Stack direction='row' minWidth={smallScreen ? '200px' : '230px'} gap={2} alignItems='center'>
            <Typography
                whiteSpace='nowrap'
                variant={smallScreen ? "body1" : "h6"}
                color={smallScreen ? '#AEC2FF4D' : undefined}
            >
                {lang.pageSizeSwitcherLabel}:
            </Typography>
            <FormControl fullWidth>
                <Select
                    value={searchParams.get("pageSize")! || '10'}
                    onChange={handleSelect}
                    sx={{
                        background: 'linear-gradient(180deg, #1C223F 0%, #282F4F 100%)',
                        borderRadius: '10px',
                    }}
                    {...(smallScreen && {
                        inputProps: {
                            sx: {
                                padding: '10px 14px',
                            }
                        }
                    })}
                >
                    {sizes.map(size => <MenuItem key={'page_size_' + size} value={size}>{size}</MenuItem>)}
                </Select>
            </FormControl>
        </Stack>
    )
};
