import { FC, ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface CarouselSlideProps {
  children?: ReactNode;
  bgImage?: string;
  backgroundPosition?: string;
}
export const CarouselSlide: FC<CarouselSlideProps> = ({
  children,
  bgImage,
  backgroundPosition,
}) => {
  const { smallScreen } = useCommonMediaQueries();

  return (
    <Box sx={{ filter: "url(#rounded)" }}>
      <Stack
        //   height={mediumScreen ? `${40 * 1.33}vw` : `${300 * 1.33}px`}
        // height={smallScreen ? '210px' : mediumScreen ? `${219 * 1.26}px` : '350px'}
        alignItems={"center"}
        width={"100%"}
        sx={{
          backgroundImage: bgImage ? `url(${bgImage})` : undefined,
          backgroundSize: "contain",
          backgroundPosition: backgroundPosition ?? "center",
          aspectRatio: smallScreen ? "0.7/0.238" : "2.806/0.8",
          backgroundRepeat: "no-repeat",
          clipPath: "polygon(6% 0%, 100% 0, 94% 100%, 0% 100%)",
          borderRadius: "20px",
        }}
      >
        <Stack
          pr={"16px"}
          // width={mediumScreen ? "100%" : "1500px"}
          direction={"row"}
          height={"100%"}
        >
          {children}
        </Stack>
        <svg style={{ visibility: 'hidden', position: 'absolute', width: 0, height: 0 }} xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="rounded">
              <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur"></feGaussianBlur>
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"></feColorMatrix>
              <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
            </filter>
          </defs>
        </svg>
      </Stack>
    </Box>
  );
};
