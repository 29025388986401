import { FC } from "react";
import { Button, Box, Typography } from "@mui/material";
import { FooterProps } from "./Footer";
import { Link } from "react-router-dom";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Logo } from "../logo/Logo";
import HeadPhonesIcon from "../../../assets/svg/HeadPhonesIcon";
import { PATHS } from "../../features/navigation/paths";

export const MobileFooter: FC<FooterProps> = ({ scrollContainerRef }) => {
  const {
    languagePack: {
      pack: {
        argentina: {
          footer: { content, columnSaltaLaBanca, contactUsBTN },
        },
      },
    },
  } = useLanguage();

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        padding: "20px",
        borderRadius: "20px",
        background:
          "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 5.946%,rgba(40, 47, 79, 0.7) 95.5%)",
        width: "100%",
        boxSizing: "border-box",
      }}
      mt={2}
    >
      <Link to={PATHS.home} onClick={scrollToTop} style={{ textDecoration: "none" }}>
        <Logo width={212} height={44} />
      </Link>

      <Typography
        sx={{
          color: "rgb(228, 233, 248)",
          fontSize: "16px",
          fontWeight: 800,
          lineHeight: "29px",
          textAlign: "left",
        }}
      >
        {content[0]}
      </Typography>

      <Button
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          padding: "10px 20px 10px 10px",
          border: "2px solid rgba(228, 233, 248, 0.1)",
          borderRadius: "10px",
          alignSelf: "flex-start",
        }}
        onClick={() => window.Tawk_API?.maximize?.()}
      >
        <HeadPhonesIcon /> {contactUsBTN}
      </Button>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Typography
          sx={{
            color: "rgba(174, 194, 255, 0.3)",
            fontSize: "14px",
            fontWeight: 800,
            lineHeight: "17px",
            textAlign: "left",
          }}
        >
          {columnSaltaLaBanca[0]}
        </Typography>
        <Link
          to={PATHS.home}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[1]}
        </Link>
        <Link
          to={PATHS.sport}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[2]}
        </Link>
        <Link
          to={PATHS.casino}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[3]}
        </Link>
        <Link
          to={PATHS.liveCasino}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[4]}
        </Link>
        <Link
          to={PATHS.crash}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[5]}
        </Link>
        <Link
          to={PATHS.tableGames}
          style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
        >
          {columnSaltaLaBanca[6]}
        </Link>
      </Box>

    </Box>
  );
};
