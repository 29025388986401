import { FC, useEffect } from "react";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useAppDispatch } from "../../store";
import { clearTransactions } from "../../features/transactions/transactionsSlice";
import { TransactionsCards } from "./TransactionCards";
import { TransactionsTable } from "./TransactionsTable";

export const Transactions: FC = () => {
    const { xxSmallScreen } = useCommonMediaQueries();
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearTransactions())
        };
    }, [dispatch]);

    if (xxSmallScreen) return <TransactionsCards />

    return <TransactionsTable />
}