import { Stack } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { useLocation } from "react-router-dom";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { getCurrentDomain } from "../../../config";
import { ShortGamesList } from "../shortGamesList/ShortGamesList";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { SectionDivider } from "../../sectionDivider/SectionDivider";
import { FullGamesList } from "../fullGamesList/FullGamesList";

interface GamesProps {
  gameType?: number;
  onProviderNameChanged?: (providerName: string | null) => void;
}

export const Games: FC<GamesProps> = ({
  onProviderNameChanged = () => { },
}) => {
  const {
    languagePack: {
      pack: {
        argentina: { common: lang },
      },
    },
  } = useLanguage();
  const location = useLocation();
  const currentPath = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const providerName = searchParams.get("providerName") ?? undefined;
  const { groupedGames, providers } = usePlayGames();
  const filteredGroupedGames =
    providerName !== undefined && groupedGames !== null
      ? groupedGames.filter(
        (group) => group.provider.provider_name === providerName
      )
      : groupedGames;
  const prevProviderName = useRef<string | undefined>();
  const domain = getCurrentDomain();
  const { xxSmallScreen } = useCommonMediaQueries();

  useEffect(() => {
    if (providers !== null && providers.length > 0) {
      if (prevProviderName.current !== providerName) {
        if (providerName !== undefined) {
          const provider = providers.find(
            (provider) => provider.provider_name === providerName
          );
          if (provider) {
            onProviderNameChanged(provider.title ?? provider.provider_name);
          }
        } else {
          onProviderNameChanged(null);
        }
        prevProviderName.current = providerName;
      }
    }
  }, [onProviderNameChanged, providerName, providers]);

  return (
    <Stack gap={xxSmallScreen ? 3 : 4}>
      {providerName === undefined &&
        groupedGames !== null &&
        groupedGames.map((group) => (
          <Stack key={group.provider.provider_name} >
            <SectionDivider
              showLeftRightButtons={false}
              link={`${currentPath}?providerName=${group.provider.provider_name}`}
              title={group.provider.title ?? "..."}
              icon={`${domain}/cdn/provider_img/mini_sqr/${group.provider.provider_name}.png`}
            />
            <ShortGamesList
              games={group.games.slice(0, 9)}
            />
          </Stack>
        ))}

      {providerName !== undefined &&
        filteredGroupedGames !== null &&
        filteredGroupedGames.length === 1 && (
          <Stack>
            <SectionDivider
              showLeftRightButtons={false}
              link={currentPath}
              title={filteredGroupedGames[0].provider.title ?? "..."}
              icon={`${domain}/cdn/provider_img/mini_sqr/${filteredGroupedGames[0].provider.provider_name}.png`}
              customButtonName={lang.returnBackBTN}
            />
          </Stack>
        )}

      {providerName !== undefined &&
        filteredGroupedGames !== null &&
        filteredGroupedGames.length === 1 && (
          <FullGamesList
            games={filteredGroupedGames[0].games}
          />
        )}
    </Stack>
  );
};
