import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const deLang: LangPack = {
    lang: Lang.DE,
    langName: "German",
    langShort: "de",
    pack: {
        common: {
            noBTN: "Nein",
            yesBTN: "Ja",
            errorCodeTitle: "Fehlercode",
            pageSizeSwitcherLabel: "Reihen auf Seite",
            ageConfirmationMessage: "Bestätigen Sie, dass Sie 18 Jahre alt sind?",
            iConfirmBTN: "Ich bestätige",
            welcome: "Willkommen",
            userProfile: "Benutzerprofil",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Möchten Sie sich wirklich abmelden?",
            logOut: "Abmelden",
            depositMoney: "Geld einzahlen",
            gamesHistory: "Spielehistorie",
            transactions: "Transaktionen",
            paymentAccounts: "Zahlungskonten",
            userInfo: "Benutzerinformationen",
            withDrawMoney: "Geld abheben",
        },
        mobileMenu: {
            profile: "Profil",
            topUpBalance: "Guthaben auffüllen",
            logout: "Abmelden",
        },
        dashboard: {
            title: "Dashboard",
            youMustBeLoggedMessage: "Sie müssen angemeldet sein, um diesen Abschnitt anzuzeigen!",
        },
        internalErrorMessages: {
            networkError:
                "Ein Netzwerkfehler ist aufgetreten, während die Anfrage ausgeführt wurde.",
            error502: "Ein Serverfehler 502 ist bei der Ausführung der Anfrage aufgetreten.",
        },
        errorMessages: {
            2: "Keine Einträge",
            3: "E-Mail-Adresse des Benutzers bereits vorhanden",
            4: "Telefonnummer des Benutzers bereits vorhanden",
            5: "Benutzer bereits angemeldet",
            6: "Falsches Passwort",
            7: "Leeres Spielergebnis",
            8: "Die ID des Ergebnisses stimmt nicht mit der Anforderungs-ID überein",
            9: "Fehler bei der Ergebnisanforderung",
            10: "Keine Sitzungs-ID in der Antwort",
            11: "Benutzer bereits vorhanden",
            12: "Ihr Token ist ungültig. Melden Sie sich erneut an.",
            13: "Sie haben nicht genügend Rechte, um diese Aktion auszuführen",
            14: "Benutzer-ID für diese Aktion fehlt",
            15: "Fehlender Geldbetrag",
            16: "Aktion existiert nicht",
            17: "Fehlendes Token",
            18: "Nicht genügend Geld",
            19: "Sie müssen der Elternteil dieses Benutzers sein, um diese Aktion auszuführen",
            20: "Benutzer existiert nicht",
            21: "Der Werttyp für dieses Feld ist falsch",
            22: "Passwort fehlt",
            23: "Benutzername fehlt",
            24: "Spiel-ID fehlt",
            25: "Neues Passwort fehlt",
            26: "Token konnte nicht eingerichtet werden",
            27: "Token konnte nicht gelöscht werden",
            28: "Benutzer-ID fehlt",
            29: "Falsches Datums- und Uhrzeitformat",
            30: "Konnte Benutzer-Sperrung nicht aktualisieren",
            31: "Dieser Benutzer muss in Ihrem Baum sein, um diese Aktion auszuführen",
            32: "Dieser Benutzer ist gesperrt und kann keine Aktionen durchführen",
            33: "Währung fehlt",
            34: "Maximaler Wert fehlt",
            35: "Limit-Typ muss einmalig, täglich, wöchentlich oder monatlich sein",
            36: "Limit-Typ fehlt",
            37: "Anbieter existiert nicht",
            38: "Bedingungsbetrag für Stufe fehlt",
            39: "Bedingungstyp für Stufe fehlt",
            40: "Stufentitel fehlt",
            41: "Cashback für diese Stufe existiert bereits",
            42: "Cashback für diese Stufe existiert nicht",
            43: "Bonus für diesen Promocode existiert bereits",
            44: "Bonus existiert nicht",
            45: "Promocode fehlt",
            46: "Startzeit fehlt",
            47: "Bedingungsbetrag für Einzahlung fehlt",
            48: "Wette fehlt",
            49: "Gültigkeitsdauer fehlt",
            50: "ID fehlt",
            51: "Cashback-Typ fehlt, muss 'Wette' oder 'Einzahlung' sein",
            52: "Cashback-Rate fehlt",
            53: "Diese Währung ist nicht verfügbar",
            54: "Ban-Parameter fehlt - true oder false",
            55: "Die Bankengruppe für diese Währung ist ungültig",
            56: "Name der Zahlungsmethode fehlt",
            57: "Obligatorische Parameter für diese Zahlungsmethode fehlen",
            58: "Diese Zahlungsmethode existiert nicht",
            59: "Diese Zahlungsmethode unterstützt die Währung dieses Benutzers nicht",
            60: "Dieser Benutzer hat kein vorhandenes Konto für dieses Zahlungssystem",
            61: "Der Elternteil dieses Benutzers hat kein vorhandenes Konto für dieses Zahlungssystem",
            62: "Transfer-ID fehlt",
            63: "Transfer mit dieser ID existiert nicht",
            64: "Dieser Transfer wird von einem anderen Elternteil angefordert",
            65: "Sie haben immer noch einen Bonus, der nicht abgelaufen ist",
        },
        loginForm: {
            usernameFieldLabel: "Spitzname",
            passwordFieldLabel: "Passwort",
            loginBTN: "Anmelden",
            authorization: "Autorisierung",
        },
        searchGameForm: {
            searchGame: "Spiele suchen",
            notFound:
                "Wir haben keine passenden Ergebnisse für Ihre Anfrage gefunden, versuchen Sie etwas anderes.",
            results: "Ergebnisse:",
            seeAll: "Alle anzeigen",
            searchResults: "Suchergebnisse",
        },
        userInfoForm: {
            emailIsRequiredMess: "E-Mail ist erforderlich",
            notValidEmail: "Geben Sie eine gültige E-Mail-Adresse ein",
            currentPassFieldLabel: "Aktuelles Passwort",
            emailFieldLabel: "E-Mail",
            emptyFieldWarningMessage: "Dieses Feld darf nicht leer sein",
            firstnameFieldLabel: "Vorname",
            lastnameFieldLabel: "Nachname",
            newPassFieldLabel: "Neues Passwort",
            passNotMatchWarningMessage:
                "Neues Passwort und wiederholtes Passwort stimmen nicht überein",
            phoneFieldLabel: "Telefon",
            confirmPassFieldLabel: "Passwort bestätigen",
            saveInfoBTN: "Info speichern",
            updatePassBTN: "Passwort aktualisieren",
            usernameLabel: "Spitzname",
            password: "Passwort",
            balance: "Guthaben",
        },
        transactionsTable: {
            amountTableHeader: "Betrag",
            methodTableHeader: "Methode",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Einzahlung/Auszahlung",
            dateTimeTableHeader: "Datum/Uhrzeit",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Anbieter",
            gameTableHeader: "Spiel",
            betAmountTableHeader: "Einsatzbetrag",
            winAmountTableHeader: "Gewinnbetrag",
            dateTimeTableHeader: "Datum/Uhrzeit",
            userTableHeader: "Benutzer",
            multiplierTableHeader: "Multiplikator",
            payoutTableHeader: "Auszahlung",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage:
                "Es stehen keine weiteren Zahlungsmethoden zur Verfügung",
            paymentMethodsDropdownLabel: "Zahlungsmethoden",
            addBTN: "Hinzufügen",
            yourPaymentAccountsTitle: "Ihre Zahlungskonten",
            addPaymentMethodBTN: "Zahlungsmethode hinzufügen",
        },
        moneyInput: {
            maxAmountMessage: "Höchstwert",
            exceedAmountError: "Höchstwert überschritten.",
            moreThanOneDotError: "Sie haben einen zusätzlichen Punkt gemacht.",
            moreThanTwoDecimals: "Sie haben mehr als zwei Dezimalstellen eingegeben.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Von Benutzer abheben",
            moneySuccessfulRequestedMessage: "Sie haben %money% für die Auszahlung angefordert.",
            requestBtn: "Geld anfordern",
        },
        topWinModal: {
            aboutBet: "Über die Wette",
            dateLabel: "Datum",
            betIdLable: "Wette-ID",
            betAmount: "Wetteinsatz",
            multiplier: "Multiplikator",
            payout: "Auszahlung",
            play: "Spielen",
        },
        argentina: {
            common: {
                linkToAllTitle: "Alle",
                topGames: "Top-Spiele",
                topLiveCasino: "Top Live Casino",
                topProviders: "Top-Anbieter",
                topCrashGames: "Top-Crash-Spiele",
                topTableGames: "Top-Tischspiele",
                liveFeed: "Live-Feed",
                seeAllBTN: "Alle anzeigen",
                returnBackBTN: "Zurückkehren",
                playGame: "Spielen",
            },
            termsAndConditions: {
                content: [
                    "Nutzungsbedingungen",
                    "Willkommen bei Salta La Blanca, Ihrem vertrauenswürdigen Online-Casino-Ziel. Durch den Zugriff auf unsere Website oder die Nutzung unserer Dienste erkennen Sie diese Allgemeinen Geschäftsbedingungen an und erklären sich damit einverstanden. Bitte nehmen Sie sich einen Moment Zeit, um sie sorgfältig zu lesen, da sie die Rechte und Pflichten beider Parteien festlegen, um ein faires und transparentes Spielerlebnis zu gewährleisten.",
                    "Annahme der Bedingungen",
                    "Durch die Registrierung eines Kontos oder die Nutzung unserer Plattform bestätigen Sie, dass Sie diese Allgemeinen Geschäftsbedingungen gelesen, vollständig verstanden und akzeptiert haben. Wenn Sie mit einer der hier aufgeführten Bestimmungen nicht einverstanden sind, bitten wir Sie, unsere Dienste nicht zu nutzen. Ihre fortgesetzte Nutzung der Plattform gilt als Bestätigung Ihrer Annahme.",
                    "Nutzungsberechtigung",
                    "Der Zugang zu Salta La Blanca ist streng auf Personen beschränkt, die das gesetzliche Mindestalter für Glücksspiele in ihrer Gerichtsbarkeit erreicht haben, in der Regel 18 Jahre oder älter. Es liegt in Ihrer alleinigen Verantwortung, festzustellen, ob die Teilnahme an Online-Glücksspielen nach den Gesetzen Ihres Landes oder Ihrer Region zulässig ist. Wir behalten uns das Recht vor, jederzeit eine Altersüberprüfung zu verlangen und können Konten sperren, wenn die Anforderungen nicht erfüllt sind.",
                    "Benutzerkonto",
                    "Um auf unsere Spiele und Dienste zugreifen zu können, müssen Sie ein Konto erstellen, indem Sie genaue und aktuelle Informationen angeben, einschließlich Ihres Namens, Ihrer E-Mail-Adresse und anderer relevanter Details. Sie sind dafür verantwortlich, die Vertraulichkeit Ihrer Kontodaten zu wahren und sicherzustellen, dass keine unbefugten Personen Zugriff haben. Sollten Sie unbefugte Aktivitäten vermuten, müssen Sie uns umgehend benachrichtigen, um weiteren Missbrauch zu verhindern.",
                    "Einzahlungen und Auszahlungen",
                    "Einzahlungen auf Ihr Spielkonto müssen mit den offiziell von Salta La Blanca genehmigten Zahlungsmethoden getätigt werden. Alle Transaktionen werden sicher verarbeitet, und wir treffen die notwendigen Vorkehrungen, um Ihre finanziellen Informationen zu schützen. Auszahlungen können Identitätsüberprüfungen unterliegen, um gesetzliche und regulatorische Anforderungen zu erfüllen. Bitte beachten Sie, dass die Bearbeitungszeiten für Auszahlungen je nach gewählter Zahlungsmethode variieren können.",
                    "Boni und Promotionen",
                    "Wir bieten eine Vielzahl von spannenden Boni und Promotionen an, um Ihr Spielerlebnis zu verbessern. Jeder Bonus unterliegt spezifischen Bedingungen, wie Umsatzanforderungen und Ablaufdaten, die in den Promotionsdetails klar angegeben sind. Missbrauch oder Betrug von Werbeaktionen, einschließlich Versuchen, die Regeln zu umgehen, kann zum Verlust der Boni und der damit verbundenen Gewinne führen.",
                    "Verantwortungsbewusstes Spielen",
                    "Bei Salta La Blanca setzen wir uns für verantwortungsbewusstes Spielen ein und sorgen dafür, dass unsere Plattform ein sicheres Umfeld für alle Nutzer ist. Wir bieten Tools an, die Ihnen helfen, Ihr Spielverhalten zu verwalten, wie die Festlegung von Einzahlungslimits, Wettlimits und Selbstausschlussoptionen. Wenn Sie das Gefühl haben, dass Ihre Spielgewohnheiten problematisch werden, empfehlen wir Ihnen dringend, Hilfe über verfügbare Ressourcen wie [Support für verantwortungsbewusstes Spielen] in Anspruch zu nehmen.",
                    "Verbotene Aktivitäten",
                    "Um ein faires und sicheres Spielumfeld zu gewährleisten, sind betrügerische, manipulative oder unfaire Praktiken strengstens untersagt. Dazu gehören unter anderem die Verwendung automatisierter Bots, Absprachen mit anderen Spielern oder der Versuch, Systemschwachstellen auszunutzen. Die Teilnahme an solchen Aktivitäten führt zur sofortigen Sperrung oder Schließung Ihres Kontos und zum Verlust aller Gewinne.",
                    "Geistiges Eigentum",
                    "Alle Inhalte auf der Salta La Blanca-Plattform, einschließlich, aber nicht beschränkt auf Logos, Designs, Texte, Software und Multimedia, sind das ausschließliche Eigentum unseres Unternehmens. Diese Materialien sind durch internationale Urheberrechts- und Eigentumsgesetze geschützt. Jede unbefugte Nutzung, Vervielfältigung oder Verbreitung unserer Inhalte ist strengstens untersagt und kann rechtliche Schritte nach sich ziehen.",
                    "Haftungsbeschränkung",
                    "Obwohl wir uns bemühen, ein nahtloses und angenehmes Spielerlebnis zu bieten, garantiert Salta La Blanca nicht, dass unsere Plattform frei von Fehlern, Unterbrechungen oder technischen Problemen ist. Wir haften nicht für direkte, indirekte oder zufällige Schäden, die sich aus der Nutzung der Plattform ergeben, einschließlich Schäden durch fehlgeschlagene Transaktionen, technische Störungen oder unbefugten Zugriff auf Ihr Konto.",
                    "Änderungen",
                    "Salta La Blanca behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern, um Änderungen in gesetzlichen Anforderungen, Geschäftspraktiken oder Benutzerfeedback zu berücksichtigen. Aktualisierungen werden auf unserer Website veröffentlicht, und wir empfehlen Ihnen, diese Bedingungen regelmäßig zu überprüfen. Ihre fortgesetzte Nutzung der Plattform nach Änderungen stellt Ihre Annahme der aktualisierten Bedingungen dar.",
                    "Anwendbares Recht und Streitigkeiten",
                    "Diese Allgemeinen Geschäftsbedingungen unterliegen den Gesetzen von [Gerichtsbarkeit angeben]. Alle Streitigkeiten, die sich aus der Nutzung unserer Dienste ergeben, werden nach diesen Gesetzen beigelegt. Im Falle einer Meinungsverschiedenheit empfehlen wir den Nutzern, sich an unser Support-Team zu wenden, um eine einvernehmliche Lösung zu finden, bevor rechtliche Schritte eingeleitet werden.",
                    "Kontaktieren Sie uns",
                ],
                email: `Bei Fragen, Bedenken oder Feedback zu diesen Allgemeinen Geschäftsbedingungen können Sie sich gerne an unser dediziertes Support-Team unter <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a> wenden. Wir sind hier, um Ihnen zu helfen und sicherzustellen, dass Ihre Erfahrung auf unserer Plattform angenehm und sicher ist.`,
            },
            aboutUs: {
                content: [
                    "Über uns",
                    "Willkommen bei Salta La Blanca, Ihrem erstklassigen Online-Ziel für ein aufregendes und vielfältiges Spielerlebnis. Egal, ob Sie ein erfahrener Spieler oder neu im Online-Gaming sind – unsere Plattform wurde entwickelt, um Ihnen die perfekte Mischung aus Spannung, Vielfalt und hochwertiger Unterhaltung zu bieten.",
                    "Was wir bieten",
                    "Bei Salta La Blanca sind wir stolz darauf, eine breite Palette von Spieloptionen anzubieten, die auf die Vorlieben jedes Spielers zugeschnitten sind. Hier ist, was Sie auf unserer Plattform genießen können:",
                    "Casino-Klassiker: Erleben Sie den zeitlosen Charme traditioneller Casinospiele wie Blackjack, Roulette und Baccarat. Mit modernen Verbesserungen und elegantem Design bleiben diese Klassiker so spannend wie eh und je und bieten sowohl Strategie als auch Glück für ein fesselndes Erlebnis.",
                    "Crash-Spiele: Suchen Sie einen rasanten Adrenalinschub? Unsere Crash-Spiele sind perfekt für Spieler, die actionreiches Gameplay lieben, bei dem jede Sekunde zählt. Testen Sie Ihre Reflexe und Intuition, um in dieser einzigartigen und aufregenden Spielkategorie groß abzuräumen.",
                    "Live-Casino: Tauchen Sie in die authentische Casino-Atmosphäre ein, ohne Ihr Zuhause zu verlassen. Mit echten Dealern, die in hochauflösender Qualität streamen, bringt unser Live-Casino die Aufregung von landbasierten Casinos direkt auf Ihren Bildschirm. Interagieren Sie mit Dealern und anderen Spielern für ein wirklich soziales und fesselndes Erlebnis.",
                    "Sportwetten: Für Sportbegeisterte bieten wir einen umfassenden Sportwettenbereich, in dem Sie auf Ihre Lieblingsmannschaften, Spiele und Events wetten können. Mit wettbewerbsfähigen Quoten und einer Vielzahl von Sportarten war es noch nie so einfach, Ihre Leidenschaft für Sport in spannende Wetten zu verwandeln.",
                    "Tischspiele: Lieben Sie Strategiespiele? Entdecken Sie eine große Auswahl an Tischspielen, die Ihren Verstand herausfordern und gleichzeitig endlosen Spaß bieten. Von Poker bis Craps – wir haben für jeden Tischspiel-Enthusiasten etwas zu bieten.",
                    "Eine breite Palette von Anbietern: Um höchste Qualität zu gewährleisten, arbeiten wir mit den besten Casino-Spielanbietern der Branche zusammen. Das bedeutet, dass Sie Zugang zu einer beeindruckenden Bibliothek von Spielen mit atemberaubenden Grafiken, innovativen Funktionen und zuverlässiger Leistung haben.",
                    "Warum uns wählen?",
                    "Bei Salta La Blanca gehen wir über die bloße Bereitstellung von Spielen hinaus – wir streben danach, eine Spielumgebung zu schaffen, die unterhaltsam, sicher und spielerorientiert ist. Hier ist, warum wir uns abheben:",
                    "Innovation: Wir aktualisieren unsere Plattform ständig mit den neuesten Trends und Technologien, um ein modernstes Spielerlebnis zu bieten. Von hochwertigen Grafiken bis hin zu einzigartigen Spielmechaniken – wir sind bestrebt, das Erlebnis für unsere Spieler frisch und aufregend zu halten.",
                    "Vielfalt: Wir verstehen, dass jeder Spieler einzigartig ist, weshalb wir eine vielfältige Auswahl an Spielen und Wettoptionen anbieten. Egal, was Ihre Vorlieben sind – Sie werden etwas finden, das zu Ihrem Stil passt und Sie begeistert.",
                    "Faires Spiel: Integrität steht im Mittelpunkt unseres Handelns. Unsere Spiele basieren auf vertrauenswürdiger Random Number Generator (RNG)-Technologie, die sicherstellt, dass alle Ergebnisse fair und unvoreingenommen sind. Die Zusammenarbeit mit seriösen Anbietern garantiert zusätzlich Transparenz und Zuverlässigkeit.",
                    "Spielerorientierter Ansatz: Ihre Zufriedenheit ist unsere Priorität. Wir haben unsere Plattform benutzerfreundlich und intuitiv gestaltet, mit reibungsloser Navigation und Funktionen, die das Spielen angenehm und problemlos machen. Darüber hinaus steht unser engagiertes Kundensupport-Team Ihnen jederzeit für Fragen oder Anliegen zur Verfügung.",
                    "Schließen Sie sich uns bei Salta La Blanca an, wo jeder Spin, Wurf und Einsatz Möglichkeiten für unvergessliche Momente schafft. Bereit, ins Geschehen einzutauchen? Lassen Sie uns jedes Spiel zu einem gewinnbringenden Erlebnis machen!",
                    "Live-Unterstützung",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Werden Sie ein Agent",
                    "Sind Sie leidenschaftlich an Online-Spielen interessiert und suchen eine Möglichkeit, diese Leidenschaft in eine lohnende Partnerschaft zu verwandeln? Werden Sie Teil des Salta La Blanca Agentenprogramms und ein wichtiger Bestandteil unserer wachsenden Community. Dies ist Ihre Chance, zu verdienen, sich zu vernetzen und in der dynamischen Welt der Online-Unterhaltung zu wachsen, während Sie eine Plattform fördern, hinter der Sie wirklich stehen können.",
                    "Warum mit Salta La Blanca zusammenarbeiten?",
                    "Wenn Sie unser Agentenprogramm beitreten, melden Sie sich nicht nur an – Sie werden Teil eines Netzwerks, das Ihre Beiträge schätzt und Ihre Bemühungen belohnt. Hier ist, was uns auszeichnet:",
                    "Hohes Verdienstpotenzial: Wir bieten eine der wettbewerbsfähigsten Provisionsstrukturen der Branche, die Ihnen die Möglichkeit gibt, Ihr Einkommen basierend auf der Aktivität und Loyalität der Spieler, die Sie auf unsere Plattform bringen, zu maximieren.",
                    "Fortgeschrittene Marketing-Tools: Erhalten Sie Zugang zu einer Reihe von professionellen Tools, darunter personalisierte Tracking-Links, detaillierte Leistungsanalysen und eine Vielzahl von hochwertigen Werbematerialien, die Ihnen helfen sollen, erfolgreich zu sein.",
                    "Umfassende Unterstützung: Unser engagiertes Affiliate-Team steht Ihnen bei jedem Schritt zur Seite. Egal, ob Sie technische Fragen haben oder Marketingberatung benötigen, wir sind nur eine Nachricht entfernt.",
                    "Vielfältige Spielepalette: Fördern Sie eine Plattform, die für jeden etwas bietet, von Casino-Klassikern über Live-Dealer-Spiele, Crash-Spiele, Sportwetten und mehr – alles von erstklassigen Spieleanbietern bereitgestellt.",
                    "Was macht ein Agent?",
                    "Als Salta La Blanca-Agent ist es Ihre Aufgabe, unsere Plattform bekannt zu machen und neue Spieler anzuziehen. Sie nutzen Ihr Netzwerk, Ihre Kreativität und Ihre Marketingfähigkeiten, um Ihr Publikum zu vergrößern, während Sie gleichzeitig beträchtliche Belohnungen verdienen. Es ist die perfekte Lösung, egal, ob Sie ein erfahrener Vermarkter, ein Content-Ersteller oder einfach jemand mit einer starken Leidenschaft für Spiele sind.",
                    "Wie funktioniert das Programm?",
                    "Registrieren Sie sich: Schließen Sie einen schnellen und einfachen Registrierungsprozess ab, um offizieller Agent zu werden.",
                    "Holen Sie sich Ihre Tools: Greifen Sie auf Ihr personalisiertes Dashboard mit Tracking-Links, Werbebannern und anderen Marketingressourcen zu.",
                    "Fördern Sie unsere Plattform: Nutzen Sie Ihre Tools, um Salta La Blanca über Websites, soziale Medien, Foren oder jede andere Plattform, die Ihnen gefällt, mit potenziellen Spielern zu teilen.",
                    "Verdienen Sie Provisionen: Für jeden Spieler, den Sie werben, erhalten Sie Provisionen basierend auf deren Aktivität, was einen stetigen Einkommensstrom sichert.",
                    "Wer kann mitmachen?",
                    "Unser Programm steht allen offen, die den Wunsch haben, erfolgreich zu sein, und eine Verbindung zur Spielewelt haben. Egal, ob Sie ein Influencer mit großem Publikum, ein Social-Media-Enthusiast oder jemand mit einem Netzwerk von Freunden sind, die Spiele lieben – wir bieten alles, was Sie brauchen, um Ihre Bemühungen in greifbare Ergebnisse umzuwandeln.",
                    "Ihr Erfolg ist unser Erfolg",
                    "Bei Salta La Blanca glauben wir an den Aufbau starker, gegenseitig vorteilhafter Beziehungen mit unseren Agenten. Deshalb investieren wir in Ihren Erfolg, indem wir transparente Zahlungssysteme, kontinuierliche Unterstützung und eine Plattform anbieten, die Spieler lieben. Wenn Sie erfolgreich sind, sind wir es auch.",
                    "Bereit, mitzumachen?",
                    "Ein Agent von Salta La Blanca zu werden, ist einfach und lohnend. Machen Sie den ersten Schritt in eine aufregendere Zukunft, indem Sie sich noch heute anmelden. Klicken Sie auf [Werden Sie ein Agent] und beginnen Sie zu verdienen, während Sie Teil eines der vertrauenswürdigsten Namen im Online-Gaming sind.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Startseite",
                    lobby: "Lobby",
                    casino: "Casino",
                    sport: "Sport",
                    liveBetting: "Live-Wetten",
                    liveCasino: "Live-Casino",
                    crashGames: "Crash-Spiele",
                    tableGames: "Tischspiele",
                    legends: "Legenden",
                    becomeAnAgent: "Werden Sie ein Agent",
                    privacyPolicy: "Datenschutzrichtlinie",
                    responsibleGaming: "Verantwortungsvolles Spielen",
                    termsAndConditions: "Geschäftsbedingungen",
                    aboutUs: "Über Uns",
                    userInfo: "Benutzerinfo",
                    gameHistory: "Spielverlauf",
                    transactions: "Transaktionen",
                },
                loggedInUserMenu: {
                    userInfo: "Benutzerinformationen",
                    userTransactions: "Transaktionen",
                    userGameHistory: "Spielverlauf",
                    userLogOut: "Abmelden",
                },
                userInfo: {
                    userInfoSection: "Benutzerinfo",
                    changePasswordSection: "Passwort ändern",
                },
            },
            carousel: {
                slide1: {
                    header: "Erobere das Totem<br/> und Siege",
                    tagline: "Würfeln und Gewinnen mit Galaxsys!",
                },
                slide2: {
                    header: "Entdecken Sie den Nervenkitzel<br/>nur bei Salta la Banca",
                    tagline: "Treten Sie noch heute dem Spiel bei!",
                },
                slide3: {
                    header: "Stell dir mehr vor, Gewinne Mehr <br/>bei Imagine Live",
                    tagline: "Die Einsätze sind real",
                },
                slide4: {
                    header: "Spüre den Nervenkitzel des Spiels!<br/>Lebe den Moment des Sieges!",
                    tagline: "Jetzt beitreten!",
                },
                slide5: {
                    header: "Ezugi kommt",
                    tagline: "Ende dieser Woche!",
                },
            },
            menuLinkTitles: {
                home: "Startseite",
                casino: "Casino",
                liveCasino: "Live-Casino",
                virtualGames: "Virtuelle Spiele",
            },
            sideBarSections: {
                games: "Spiele",
                topCrashGames: "Top Crash-Spiele",
                other: "Andere",
                sportsRules: "Sportregeln",
            },
            headerMenu: {
                home: "Startseite",
                casino: "Casino",
                slots: "Slots",
                shooters: "Shooterspiele",
                tableGames: "Tischspiele",
                liveCasino: "Live-Casino",
                crash: "Crash-Spiele",
                sport: "Sport",
                menu: "Menü",
                lobby: "Lobby",
            },
            categoriesDescription: {
                casino: "Tauchen Sie ein in die Spannung des Casinos",
                slots: "Slots!",
                shooters: "Zielen und Schießen!",
                tableGames: "Klassische Tischspiele, moderner Nervenkitzel – Spielen und Gewinnen!",
                liveCasino: "Erleben Sie die Spannung des Live-Casinos",
                crash: "Jagen Sie den Multiplikator und brechen Sie die Grenzen!",
                sport: "Setzen Sie Ihre Wetten auf die größten Spiele",
            },
            footer: {
                content: [
                    "Willkommen bei Salta la Banca",
                    `Ihr ultimatives Ziel für Online-Spielspaß! Tauchen Sie ein in eine umfangreiche Welt des Sportwettens, erkunden Sie unsere große Auswahl an Casino-Spielen und genießen Sie Live-Dealer-Aktionen jederzeit und überall.\n\nMit sicheren Transaktionen und einem nahtlosen Erlebnis sind wir hier, um Ihre Spielreise zu bereichern.\n\nWerden Sie Teil unserer Community und lassen Sie die Spannung beginnen!\n\nAlle Rechte vorbehalten © Salta la Banca 2024-2025`,
                    `Sie müssen mindestens 18 Jahre alt sein, um sich bei uns zu registrieren und ein Konto zu eröffnen. Das Unternehmen behält sich das Recht vor, von jedem Kunden einen Altersnachweis zu verlangen, und Konten können bis zur Vorlage eines zufriedenstellenden Altersnachweises gesperrt werden. Die Verwaltung der Website haftet nicht für Handlungen oder Entscheidungen der Benutzer. Diese Website bietet Unterhaltung und Spielmöglichkeiten, und alle Aktivitäten dienen ausschließlich zu Freizeitzwecken. Alle Rechte vorbehalten. Die unbefugte Verwendung oder Vervielfältigung der Inhalte der Website ist strengstens untersagt und gesetzlich geschützt.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Startseite",
                    "Sport",
                    "Casino",
                    "Live Casino",
                    "Crash-Spiele",
                    "Tischspiele",
                ],
                columnAboutUs: [
                    "Über Uns",
                    "Partner",
                    "Bonusbedingungen",
                    "Identitätsüberprüfung",
                    "Datenschutzrichtlinie",
                    "Verantwortungsvolles Spielen",
                    "Zahlungsoptionen",
                ],
                contactUsBTN: "Kontaktieren Sie uns",
            },
        },
    },
};
