import React, { forwardRef } from "react";
import { Box, InputAdornment } from "@mui/material";
import SearchIcon from "../../../assets/svg/SearchIconRD";

// Define the props interface
interface CustomTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  smallScreen: boolean;
  textColor?: string; // Optional prop for input text color
  placeholderColor?: string; // Optional prop for placeholder color
  inputRef?: React.Ref<HTMLInputElement>; // Add inputRef prop
  onFocus?: () => void; // Add onFocus prop
}

const CustomTextField = forwardRef<HTMLInputElement, CustomTextFieldProps>(
  (
    {
      value,
      onChange,
      label,
      textColor = "rgba(174, 194, 255, 0.3)",
      placeholderColor = "rgba(174, 194, 255, 0.3)",
      inputRef,
      onFocus,
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          background:
            "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 41.978%, rgba(40, 47, 79, 0.7) 99.305%)",
          borderRadius: 4,
          // width: smallScreen ? "48px" : "283px",
          width: "283px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          transition: "width 0.3s ease",
        }}
      >
        <InputAdornment
          position="start"
          sx={{
            position: "absolute",
            // left: smallScreen ? "50%" : "8px",
            left: "8px",
            // transform: smallScreen ? "translateX(-50%)" : "none",
            transform: "none",
          }}
        >
          <SearchIcon />
        </InputAdornment>

        <Box
          component="input"
          type="text"
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          ref={inputRef || ref} // Use the forwarded ref
          placeholder={label}
          sx={{
            width: "100%",
            height: "100%",
            background: "transparent",
            border: "none",
            outline: "none",
            paddingLeft: "45px",
            color: textColor,
            fontSize: "14px",
            "&::placeholder": {
              color: placeholderColor,
              opacity: 1,
            },
          }}
        />
      </Box>
    );
  }
);

CustomTextField.displayName = "CustomTextField";

export default CustomTextField;