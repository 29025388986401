import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const enLang: LangPack = {
    lang: Lang.EN,
    langName: "English",
    langShort: "en",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Yes",
            errorCodeTitle: "Error code",
            pageSizeSwitcherLabel: "Rows on page",
            ageConfirmationMessage: "Do you confirm that you are 18 years old?",
            iConfirmBTN: "I confirm",
            welcome: "Welcome",
            userProfile: "User Profile",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Are you sure you want to log out?",
            logOut: "Log Out",
            depositMoney: "Deposit Money",
            gamesHistory: "Games History",
            transactions: "Transactions",
            paymentAccounts: "Payment accounts",
            userInfo: "User Info",
            withDrawMoney: "Withdraw Money",
        },
        mobileMenu: {
            profile: "Profile",
            topUpBalance: "Top Up balance",
            logout: "Log Out",
        },
        dashboard: {
            title: "Dashboard",
            youMustBeLoggedMessage: "You must be logged in to view this section!",
        },
        internalErrorMessages: {
            networkError: "A network error occurred while executing the request.",
            error502: "A 502 server error occurred while executing the request.",
        },
        errorMessages: {
            2: "No entries",
            3: "User email address already existing",
            4: "User phone number already existing",
            5: "User already logged in",
            6: "Wrong password",
            7: "Empty games result",
            8: "Result ID does not match the request ID",
            9: "Outcome request error",
            10: "Outcome no session ID in the response",
            11: "User already existing",
            12: "Your token is not valid. Login again.",
            13: "You have not enough rights to perform this action",
            14: "User ID for this action missing",
            15: "Money amount missing",
            16: "Action does not exist",
            17: "Token missing",
            18: "Not enough money",
            19: "You must be the parent of this user to perform this action",
            20: "User does not exist",
            21: "The value type for this field is wrong",
            22: "Password missing",
            23: "User name missing",
            24: "Game ID missing",
            25: "New password missing",
            26: "Token could not be set up",
            27: "Token could not be deleted",
            28: "User ID missing",
            29: "Wrong datetime format",
            30: "Could not update user ban",
            31: "This user must be in your tree to perform this action",
            32: "This user is banned and cannot perform any actions",
            33: "Currency missing",
            34: "Maximum value missing",
            35: "Limit type must be once, daily, weekly, or monthly",
            36: "Limit type missing",
            37: "Provider does not exist",
            38: "Tier condition amount missing",
            39: "Tier condition type missing",
            40: "Tier title missing",
            41: "Cashback for this tier is already existing",
            42: "Cashback for this tier not existing",
            43: "Bonus for this promocode is already existing",
            44: "Bonus does not exist",
            45: "Promocode missing",
            46: "Start time missing",
            47: "Deposit amount condition missing",
            48: "Wager missing",
            49: "Amount validity days missing",
            50: "ID missing",
            51: "Cashback type missing, must be 'wager' or 'deposit'",
            52: "Cashback rate missing",
            53: "This currency is not available",
            54: "Ban parameter missing - true or false",
            55: "The bank group for this currency is not valid",
            56: "Payment method name missing",
            57: "Mandatory parameters for this payment method missing",
            58: "This payment method does not exist",
            59: "This payment method does not support the currency of this user",
            60: "This user does not have an existing account for this payment system",
            61: "The parent of this user does not have an existing account for this payment system",
            62: "Transfer ID missing",
            63: "Transfer with this ID does not exist",
            64: "This transfer is requested by a different parent",
            65: "You still have a bonus which is not expired",
        },
        loginForm: {
            usernameFieldLabel: "Username",
            passwordFieldLabel: "Password",
            loginBTN: "Login",
            authorization: "Authorization",
        },
        searchGameForm: {
            searchGame: "Search games",
            notFound: "We didn't find any matching results for your request, try something else.",
            results: "Results:",
            seeAll: "See all",
            searchResults: "Search results",
        },
        userInfoForm: {
            emailIsRequiredMess: "Email is required",
            notValidEmail: "Enter a valid email address",
            currentPassFieldLabel: "Current password",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "This field must not be empty",
            firstnameFieldLabel: "Firstname",
            lastnameFieldLabel: "Lastname",
            newPassFieldLabel: "New password",
            passNotMatchWarningMessage: "New password and retype password do not match",
            phoneFieldLabel: "Phone",
            confirmPassFieldLabel: "Confirm password",
            saveInfoBTN: "Save info",
            updatePassBTN: "Update Password",
            usernameLabel: "Login",
            password: "Password",
            balance: "Balance",
        },
        transactionsTable: {
            amountTableHeader: "Amount",
            methodTableHeader: "Method",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Deposit/Withdraw",
            dateTimeTableHeader: "Date/Time",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Provider",
            gameTableHeader: "Game",
            betAmountTableHeader: "Bet amount",
            winAmountTableHeader: "Win amount",
            dateTimeTableHeader: "Date/Time",
            userTableHeader: "User",
            multiplierTableHeader: "Multiplier",
            payoutTableHeader: "Payout",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "There are no more payment methods available",
            paymentMethodsDropdownLabel: "Payment methods",
            addBTN: "Add",
            yourPaymentAccountsTitle: "Your payment accounts",
            addPaymentMethodBTN: "Add payment method",
        },
        moneyInput: {
            maxAmountMessage: "Maximum value",
            exceedAmountError: "Maximum value exceeded.",
            moreThanOneDotError: "You made an extra point.",
            moreThanTwoDecimals: "You entered more than two decimal places.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Withdraw from user",
            moneySuccessfulRequestedMessage: "You have requested %money% for payout.",
            requestBtn: "Request money",
        },
        topWinModal: {
            aboutBet: "About Bet",
            dateLabel: "Date",
            betIdLable: "Bet ID",
            betAmount: "Bet Amount",
            multiplier: "Multiplier",
            payout: "Payout",
            play: "Play",
        },
        argentina: {
            common: {
                linkToAllTitle: "All",
                topGames: "Top Games",
                topLiveCasino: "Top Live Casino",
                topProviders: "Top Providers",
                topCrashGames: "Top Crash Games",
                topTableGames: "Top Table Games",
                liveFeed: "Live Feed",
                seeAllBTN: "Show all",
                returnBackBTN: "Return back",
                playGame: "Play game",
            },
            termsAndConditions: {
                content: [
                    "Terms and Conditions",
                    "Welcome to Salta La Blanca, your trusted online casino destination. By accessing our website or engaging with any of our services, you acknowledge and agree to be bound by these Terms and Conditions. Please take a moment to read them carefully, as they outline the rights and obligations of both parties to ensure a fair and transparent gaming experience.",
                    "Acceptance of Terms",
                    "By registering an account or using any features of our platform, you confirm that you have read, fully understood, and agreed to these Terms and Conditions. If you do not agree with any of the provisions outlined here, we kindly ask you to refrain from using our services. Your continued use of the platform serves as a confirmation of your acceptance.",
                    "Eligibility to Use",
                    "Access to Salta La Blanca is strictly limited to individuals who meet the legal gambling age requirements in their jurisdiction, typically 18 years or older. It is your sole responsibility to determine whether participation in online gambling is permitted under the laws of your country or region. We reserve the right to request age verification at any time and may suspend accounts if eligibility requirements are not met.",
                    "User Account",
                    "To access our games and services, you are required to create an account by providing accurate and up-to-date information, including your name, email address, and other relevant details. You are responsible for maintaining the confidentiality of your account credentials and ensuring that no unauthorized individuals gain access. Should you suspect any unauthorized activity, you must notify us immediately to prevent further misuse.",
                    "Deposits and Withdrawals",
                    "Deposits to your gaming account must be made using payment methods officially approved by Salta La Blanca. All transactions are processed securely, and we take necessary precautions to protect your financial information. Withdrawals may be subject to identity verification procedures to comply with legal and regulatory requirements. Please note that processing times for withdrawals may vary depending on your chosen payment method.",
                    "Bonuses and Promotions",
                    "We offer a variety of exciting bonuses and promotions to enhance your gaming experience. Each bonus is subject to specific terms and conditions, such as wagering requirements and expiration periods, which will be clearly outlined in the promotion details. Misuse or abuse of promotional offers, including attempts to circumvent rules, may result in the forfeiture of bonuses and associated winnings.",
                    "Responsible Gambling",
                    "At Salta La Blanca, we are committed to promoting responsible gambling and ensuring that our platform is a safe environment for all users. We provide tools to help you manage your gaming activity, such as setting deposit limits, wagering limits, and self-exclusion options. If you feel that your gambling habits are becoming problematic, we strongly encourage you to seek assistance through available resources like [Responsible Gambling Support].",
                    "Prohibited Activities",
                    "To maintain a fair and secure gaming environment, any fraudulent, manipulative, or unfair practices are strictly prohibited. This includes, but is not limited to, the use of automated bots, collusion with other players, or any attempt to exploit system vulnerabilities. Engaging in such activities will result in the immediate suspension or closure of your account and the forfeiture of any winnings.",
                    "Intellectual Property",
                    "All content on the Salta La Blanca platform, including but not limited to logos, designs, text, software, and multimedia, is the exclusive property of our company. These materials are protected by international copyright and intellectual property laws. Any unauthorized use, reproduction, or distribution of our content is strictly prohibited and may result in legal action.",
                    "Limitation of Liability",
                    "While we strive to provide a seamless and enjoyable gaming experience, Salta La Blanca does not guarantee that our platform will be free from errors, interruptions, or technical issues. We shall not be held liable for any direct, indirect, or incidental losses arising from your use of the platform, including losses resulting from failed transactions, technical malfunctions, or unauthorized account access.",
                    "Amendments",
                    "Salta La Blanca reserves the right to amend these Terms and Conditions at any time to reflect changes in legal requirements, business practices, or user feedback. Updates will be posted on our website, and we encourage you to review these Terms periodically. Your continued use of the platform after changes have been made constitutes your acceptance of the revised Terms.",
                    "Governing Law and Disputes",
                    "These Terms and Conditions are governed by the laws of [указать юрисдикцию]. Any disputes arising from the use of our services will be resolved in accordance with these laws. In the event of a disagreement, we encourage users to contact our support team to seek an amicable resolution before pursuing legal action.",
                    "Contact Us",
                ],
                email: `For any questions, concerns, or feedback regarding these Terms and Conditions, please do not hesitate to reach out to our dedicated support team at <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a>. We are here to assist you and ensure your experience on our platform is both enjoyable and secure.`,
            },
            aboutUs: {
                content: [
                    "About Us",
                    "Welcome to Salta La Blanca, your premier online destination for an exhilarating and diverse gaming experience. Whether you’re a seasoned player or new to online gaming, our platform is designed to bring you the perfect blend of excitement, variety, and quality entertainment.",
                    "What We Offer",
                    "At Salta La Blanca, we pride ourselves on providing a wide range of gaming options that cater to every player’s preferences. Here’s what you can enjoy on our platform:",
                    "Casino Classics: Relive the timeless charm of traditional casino games like blackjack, roulette, and baccarat. With modern enhancements and sleek designs, these classics remain as thrilling as ever, offering both strategy and luck for an engaging experience.",
                    "Crash Games: Looking for a fast-paced adrenaline rush? Our crash games are perfect for players who love action-packed gameplay where every second counts. Test your reflexes and intuition to win big in this unique and exhilarating game category.",
                    "Live Casino: Immerse yourself in the authentic casino atmosphere without leaving your home. With real dealers streaming in high definition, our live casino brings the excitement of land-based casinos straight to your screen. Interact with dealers and other players for a truly social and engaging experience.",
                    "Sports Betting: For sports enthusiasts, we offer a comprehensive sports betting section where you can wager on your favorite teams, matches, and events. With competitive odds and a variety of sports to choose from, it’s never been easier to turn your passion for sports into thrilling bets.",
                    "Table Games: Love games of strategy and skill? Explore a wide selection of table games that challenge your mind while delivering endless entertainment. From poker to craps, we’ve got something for every table game enthusiast.",
                    "A Wide Range of Providers: To ensure the highest quality, we partner with the best casino game providers in the industry. This means you’ll have access to an impressive library of games with stunning visuals, innovative features, and reliable performance.",
                    "Why Choose Us?",
                    "At Salta La Blanca, we go beyond just offering games—we strive to create a gaming environment that is entertaining, secure, and player-focused. Here’s why we stand out:",
                    "Innovation: We constantly update our platform with the latest trends and technologies to provide a cutting-edge gaming experience. From high-quality graphics to unique game mechanics, we’re committed to keeping things fresh and exciting for our players.",
                    "Variety: We understand that every player is unique, which is why we offer a diverse range of games and betting options. No matter your preferences, you’ll find something that suits your style and keeps you engaged.",
                    "Fair Play: Integrity is at the core of what we do. Our games are built on trusted Random Number Generator (RNG) technology, ensuring all outcomes are fair and unbiased. Partnering with reputable providers further guarantees transparency and reliability.",
                    "Player-Centric Approach: Your satisfaction is our priority. We’ve designed our platform to be user-friendly and intuitive, with smooth navigation and features that make gaming enjoyable and hassle-free. Plus, our dedicated customer support team is always ready to assist you with any questions or concerns.",
                    "Join us at Salta La Blanca, where every spin, roll, and bet creates opportunities for unforgettable moments. Ready to dive into the action? Let’s make every game a winning experience!",
                    "Live support",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Become an Agent",
                    "Are you passionate about online gaming and looking for an opportunity to turn that passion into a rewarding partnership? Join the Salta La Blanca Agent Program and become a vital part of our growing community. This is your chance to earn, connect, and thrive in the dynamic world of online entertainment while promoting a platform you can truly stand behind.",
                    "Why Partner with Salta La Blanca?",
                    "When you join our Agent Program, you’re not just signing up—you’re joining a network that values your contributions and rewards your efforts. Here’s what sets us apart:",
                    "High Earning Potential: We offer one of the most competitive commission structures in the industry, giving you the opportunity to maximize your income based on the activity and loyalty of the players you bring to our platform.",
                    "Advanced Marketing Tools: Gain access to a suite of professional tools, including personalized tracking links, detailed performance analytics, and a variety of high-quality promotional materials designed to help you succeed.",
                    "Comprehensive Support: Our dedicated affiliate team is here to support you at every step. Whether you have technical questions or need marketing advice, we’re just a message away.",
                    "Diverse Gaming Portfolio: Promote a platform that offers something for everyone, from casino classics to live dealer games, crash games, sports betting, and more—all provided by top-tier gaming providers.",
                    "What Does an Agent Do?",
                    "As a Salta La Blanca agent, your role is to spread the word about our platform and attract new players. You’ll use your network, creativity, and marketing skills to grow your audience, all while earning substantial rewards. It’s a perfect fit whether you’re a seasoned marketer, a content creator, or simply someone with a strong passion for gaming.",
                    "How the Program Works",
                    "Sign Up: Complete a quick and easy registration process to become an official agent.",
                    "Get Your Tools: Access your personalized dashboard with tracking links, promotional banners, and other marketing resources.",
                    "Promote Our Platform: Use your tools to share Salta La Blanca with potential players through websites, social media, forums, or any platform that suits you.",
                    "Earn Commissions: For every player you refer, you’ll earn commissions based on their activity, ensuring a steady stream of income.",
                    "Who Can Join?",
                    "Our program is open to everyone with a desire to succeed and a connection to the gaming world. Whether you’re an influencer with a large audience, a social media enthusiast, or someone with a network of friends who love gaming, we provide everything you need to turn your efforts into tangible results.",
                    "Your Success Is Our Success",
                    "At Salta La Blanca, we believe in building strong, mutually beneficial relationships with our agents. That’s why we invest in your success by offering transparent payment systems, ongoing support, and a platform that players love. When you succeed, we succeed.",
                    "Ready to Join?",
                    "Becoming an agent with Salta La Blanca is simple and rewarding. Take the first step toward a more exciting future by signing up today. Click [Become an Agent] and start earning while being part of one of the most trusted names in online gaming.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Home",
                    lobby: "Lobby",
                    casino: "Casino",
                    sport: "Sport",
                    liveBetting: "Live Betting",
                    liveCasino: "Live Casino",
                    crashGames: "Crash Games",
                    tableGames: "Table Games",
                    legends: "Legends",
                    becomeAnAgent: "Become an Agent",
                    privacyPolicy: "Privacy Policy",
                    responsibleGaming: "Responsible Gaming",
                    termsAndConditions: "Terms and Conditions",
                    aboutUs: "About Us",
                    userInfo: "User Info",
                    gameHistory: "Game History",
                    transactions: "Transactions",
                },
                loggedInUserMenu: {
                    userInfo: "User Information",
                    userTransactions: "Transactions",
                    userGameHistory: "Game History",
                    userLogOut: "Log Out",
                },
                userInfo: {
                    userInfoSection: "User info",
                    changePasswordSection: "Change password",
                },
            },
            carousel: {
                slide1: {
                    header: "Master the Totem<br/> and Seize Victory",
                    tagline: "Roll and Win with Galaxsys!",
                },
                slide2: {
                    header: "Discover the Thrill<br/>Only at Salta la Banca",
                    tagline: "Join the Game Today!",
                },
                slide3: {
                    header: "Imagine More, Win More <br/>at Imagine Live",
                    tagline: "The stakes are real",
                },
                slide4: {
                    header: "Feel the thrill of the game!<br/>Live the moment of victory!",
                    tagline: "Join now!",
                },
                slide5: {
                    header: "Ezugi is comming",
                    tagline: "End of this week!",
                },
            },
            menuLinkTitles: {
                home: "Home",
                casino: "Casino",
                liveCasino: "Live casino",
                virtualGames: "Virtual games",
            },
            sideBarSections: {
                games: "Games",
                topCrashGames: "Top crash games",
                other: "Other",
                sportsRules: "Sports rules",
            },
            headerMenu: {
                home: "Home",
                casino: "Casino",
                slots: "Slots",
                shooters: "Shooters",
                tableGames: "Table Games",
                liveCasino: "Live Casino",
                crash: "Crash Games",
                sport: "Sport",
                menu: "Menu",
                lobby: "Lobby",
            },
            categoriesDescription: {
                casino: "Step Into the Excitement of the Casino",
                slots: "Slots!",
                shooters: "Aim and Shoot!",
                tableGames: "Classic Table Games, Modern Thrills – Play and Win!",
                liveCasino: "Join the Thrill of Live Casino Action",
                crash: "Chase the Multiplier, Crash the Limits!",
                sport: "Place Your Bets on the Biggest Games",
            },
            footer: {
                content: [
                    "Welcome to Salta la Banca",
                    `Your ultimate destination for online gaming thrills! Dive into an expansive world of sports betting, explore our vast collection of casino games, and enjoy live dealer action anytime, anywhere.\n\nWith secure transactions and a seamless experience, we're here to elevate your gaming journey.\n\nJoin us and let the excitement begin!\n\nAll rights reserved © Salta la Banca 2024-2025`,
                    `You must be 18 years of age to register and open an account with us. The company reserves the right to request proof of age from any customer, and accounts may be suspended until satisfactory proof of age is provided. The administration of the site holds no liability for any actions or decisions made by users. This site provides an opportunity for entertainment and gaming, and all activities are intended solely for recreational purposes. All rights reserved. Unauthorized use or reproduction of the site's content is strictly prohibited and protected by law.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Home",
                    "Sport",
                    "Casino",
                    "Live Casino",
                    "Crash Games",
                    "Table Games",
                ],
                columnAboutUs: [
                    "About Us",
                    "Affiliates",
                    "Bonus Terms",
                    "Identity Verification",
                    "Privacy Policy",
                    "Responsible Gaming",
                    "Payment Options",
                ],
                contactUsBTN: "Contact Us",
            },
        },
    },
};
