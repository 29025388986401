import { useState, ChangeEvent } from "react";
import { GameObj } from "../../features/play_games/playGamesModels";

export const minSearchTextLength = 3;

export const useGameSearch = (games: GameObj[] | null) => {
    const [searchText, setSearchText] = useState<string>("");
    const [foundGames, setFoundGames] = useState<GameObj[]>([]);

    const handleSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);

        if (games !== null) {
            if (value.trim().length >= minSearchTextLength) {
                const filteredGames = games.filter((game) =>
                    game.Name.toLowerCase().includes(value.toLowerCase())
                );
                setFoundGames(filteredGames);
            } else {
                setFoundGames([]);
            }
        }
    };

    return {
        searchText,
        foundGames,
        handleSearchTextChanged,
        setSearchText,
    };
};
