import Stack from "@mui/material/Stack";
import { FC } from "react";
import { Box } from "@mui/material";

interface ProviderCardProps {
    providerName: string
    providerLogo: string
    gamesCount: number
    onClick?: () => void
}

export const ProviderCard: FC<ProviderCardProps> = ({ providerLogo, onClick }) => {
    return <Stack
        onClick={onClick}
        sx={{
            backgroundColor: 'background: linear-gradient(180deg, #141931 0%, #1C223F 100%)',
            userSelect: 'none',
            cursor: onClick ? 'pointer' : 'default',
            border: '3px solid var(--Stroke-Stroke-5, #E4E9F80D)'
        }}
        minWidth='180px'
        width='180px'
        height='60px'
        borderRadius={'16px'}
        alignItems={'center'} justifyContent={'center'}
        gap={'6px'}
    >
        <Box
            width='100px'
            height='40px'
            sx={{
                backgroundImage: `url(${providerLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',

                filter: 'contrast(50%)',
                userSelect: 'none'
            }}
        />
    </Stack >
}