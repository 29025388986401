import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const ruLang: LangPack = {
    lang: Lang.RU,
    langName: "Russian",
    langShort: "ru",
    pack: {
        common: {
            noBTN: "Нет",
            yesBTN: "Да",
            errorCodeTitle: "Ошибка",
            pageSizeSwitcherLabel: "Строк на страницу",
            ageConfirmationMessage: "Подтверждаете, что вам исполнилось 18 лет?",
            iConfirmBTN: "Подтверждаю",
            welcome: "Добро пожаловать",
            userProfile: "Профиль пользователя",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Вы уверены, что хотите выйти из аккаунта?",
            logOut: "Выйти из аккаунта",
            depositMoney: "Пополнение счета",
            gamesHistory: "История игр",
            transactions: "Транзакции",
            paymentAccounts: "Платежные аккаунты",
            userInfo: "Информация пользователя",
            withDrawMoney: "Вывод денег",
        },
        mobileMenu: {
            profile: "Профиль",
            topUpBalance: "Пополнить баланс",
            logout: "Выйти",
        },
        dashboard: {
            title: "Панель управления",
            youMustBeLoggedMessage: "Вам нужно войти, чтобы видеть данную секцию!",
        },
        internalErrorMessages: {
            networkError: "Произошла сетевая ошибка при выполнении запроса.",
            error502: "Произошла ошибка сервера 502 при выполнении запроса.",
        },
        errorMessages: {
            2: "Нет записей",
            3: "Адрес электронной почты пользователя уже существует",
            4: "Номер телефона пользователя уже существует",
            5: "Пользователь уже вошел в систему",
            6: "Неверный пароль",
            7: "Пустой результат игр",
            8: "ID результата не совпадает с ID запроса",
            9: "Ошибка запроса результата",
            10: "ID сеанса отсутствует в ответе",
            11: "Пользователь уже существует",
            12: "Токен недействителен. Войдите повторно.",
            13: "У вас недостаточно прав для выполнения этого действия",
            14: "Отсутствует идентификатор дочернего пользователя для этого действия",
            15: "Отсутствует сумма денег",
            16: "Действие не существует",
            17: "Отсутствует токен",
            18: "Недостаточно средств",
            19: "Должны быть родителем этого пользователя для выполнения этого действия",
            20: "Пользователь не существует",
            21: "Неверный тип значения для этого поля",
            22: "Отсутствует пароль",
            23: "Отсутствует имя пользователя",
            24: "Отсутствует идентификатор игры",
            25: "Отсутствует новый пароль",
            26: "Не удалось установить токен",
            27: "Не удалось удалить токен",
            28: "Отсутствует идентификатор пользователя",
            29: "Неверный формат даты и времени",
            30: "Не удалось обновить блокировку пользователя",
            31: "Этот пользователь должен быть в вашем дереве для выполнения этого действия",
            32: "Этот пользователь заблокирован и не может выполнять никаких действий",
            33: "Отсутствует валюта",
            34: "Отсутствует максимальное значение",
            35: "Тип лимита должен быть единичным, ежедневным, еженедельным или ежемесячным",
            36: "Отсутствует тип лимита",
            37: "Поставщик не существует",
            38: "Отсутствует сумма условия уровня",
            39: "Отсутствует тип условия уровня",
            40: "Отсутствует заголовок уровня",
            41: "Cashback для этого уровня уже существует",
            42: "Нет cashback для этого уровня",
            43: "Бонус для этого промокода уже существует",
            44: "Бонус не существует",
            45: "Отсутствует промокод",
            46: "Отсутствует время начала",
            47: "Отсутствует сумма условия для депозита",
            48: "Отсутствует ставка",
            49: "Отсутствует срок действия в днях",
            50: "Отсутствует идентификатор",
            51: "Отсутствует тип cashback, должен быть 'ставка' или 'депозит'",
            52: "Отсутствует ставка cashback",
            53: "Эта валюта недоступна",
            54: "Отсутствует параметр блокировки - true или false",
            55: "Группа банка для этой валюты не является допустимой",
            56: "Отсутствует название способа оплаты",
            57: "Отсутствуют обязательные параметры для этого способа оплаты",
            58: "Этот способ оплаты не существует",
            59: "Этот способ оплаты не поддерживает валюту этого пользователя",
            60: "У этого пользователя нет существующего счета для этой системы оплаты",
            61: "Родитель этого пользователя не имеет существующего счета для этой системы оплаты",
            62: "Отсутствует идентификатор трансфера",
            63: "Трансфер с этим идентификатором не существует",
            64: "Этот трансфер запрошен другим родителем",
            65: "У вас все еще есть бонус, который не истек",
        },
        loginForm: {
            usernameFieldLabel: "Никнейм",
            passwordFieldLabel: "Пароль",
            loginBTN: "Логин",
            authorization: "Авторизация",
        },
        searchGameForm: {
            searchGame: "Искать игры",
            notFound:
                "Мы не нашли подходящих результатов по вашему запросу, попробуйте что-нибудь другое.",
            results: "Результаты:",
            seeAll: "Смотреть всё",
            searchResults: "Результаты поиска",
        },
        userInfoForm: {
            emailIsRequiredMess: "Email обязательный",
            notValidEmail: "Введите корректный адрес email",
            currentPassFieldLabel: "Текущий пароль",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "Это поле не должно быть пустым",
            firstnameFieldLabel: "Имя",
            lastnameFieldLabel: "Фамилия",
            newPassFieldLabel: "Новый пароль",
            passNotMatchWarningMessage: "Новый пароль и повтор пароля не совпадают",
            phoneFieldLabel: "Телефон",
            confirmPassFieldLabel: "Подтвердите пароль",
            saveInfoBTN: "Сохранить инфо",
            updatePassBTN: "Обновить пароль",
            usernameLabel: "Никнейм",
            password: "Пароль",
            balance: "Баланс",
        },
        transactionsTable: {
            amountTableHeader: "Сумма",
            methodTableHeader: "Метод",
            statusTableHeader: "Статус",
            depositWithdrawTableHeader: "Пополнение/Вывод",
            dateTimeTableHeader: "Дата и время",
            operationTableHeader: "Operation",
        },
        gamesHistory: {
            providerTableHeader: "Провайдер",
            gameTableHeader: "Игра",
            betAmountTableHeader: "Сумма ставки",
            winAmountTableHeader: "Сумма выигрыша",
            dateTimeTableHeader: "Дата и время",
            userTableHeader: "Пользователь",
            multiplierTableHeader: "Множитель",
            payoutTableHeader: "Выплата",
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Нет доступных способов оплаты",
            paymentMethodsDropdownLabel: "Способы оплаты",
            addBTN: "Добавить",
            yourPaymentAccountsTitle: "Ваши платежные счета",
            addPaymentMethodBTN: "Добавить способ оплаты",
        },
        moneyInput: {
            maxAmountMessage: "Максимальное значение",
            exceedAmountError: "Превышено максимальное значение.",
            moreThanOneDotError: "Вы ввели лишнюю точку.",
            moreThanTwoDecimals: "Вы ввели более двух знаков после запятой.",
        },
        withdrawMoney: {
            requestPayoutLabel: "Запрос вывода средств",
            moneySuccessfulRequestedMessage: "Вы успешно запросили %money% для выплаты.",
            requestBtn: "Запросить деньги",
        },
        topWinModal: {
            aboutBet: "О ставке",
            dateLabel: "Дата",
            betIdLable: "ID ставки",
            betAmount: "Сумма ставки",
            multiplier: "Множитель",
            payout: "Выплата",
            play: "Играть",
        },
        argentina: {
            common: {
                linkToAllTitle: "Все",
                topGames: "Лучшие игры",
                topLiveCasino: "Лучшие Live Casino",
                topProviders: "Лучшие провайдеры",
                topCrashGames: "Лучшие краш-игры",
                topTableGames: "Лучшие настольные игры",
                liveFeed: "Лента в реальном времени",
                seeAllBTN: "Показать все",
                returnBackBTN: "Вернуться назад",
                playGame: "Играть",
            },
            termsAndConditions: {
                content: [
                    "Условия и положения",
                    "Добро пожаловать в Salta La Blanca, ваш надежный онлайн-казино. Получая доступ к нашему сайту или используя наши услуги, вы признаете и соглашаетесь с этими Условиями и положениями. Пожалуйста, уделите время, чтобы внимательно их прочитать, так как они описывают права и обязанности обеих сторон для обеспечения честного и прозрачного игрового опыта.",
                    "Принятие Условий",
                    "Регистрируя аккаунт или используя любые функции нашей платформы, вы подтверждаете, что прочитали, полностью поняли и согласились с этими Условиями и положениями. Если вы не согласны с какими-либо положениями, изложенными здесь, мы просим вас воздержаться от использования наших услуг. Ваше дальнейшее использование платформы считается подтверждением вашего согласия.",
                    "Право на Использование",
                    "Доступ к Salta La Blanca строго ограничен лицами, достигшими установленного законом возраста для азартных игр в их юрисдикции, как правило, 18 лет и старше. Вы несете единоличную ответственность за определение, разрешено ли участие в онлайн-азартных играх в соответствии с законами вашей страны или региона. Мы оставляем за собой право запросить подтверждение возраста в любое время и можем приостановить аккаунты, если требования не соблюдены.",
                    "Аккаунт Пользователя",
                    "Для доступа к нашим играм и услугам вам необходимо создать аккаунт, предоставив точную и актуальную информацию, включая ваше имя, адрес электронной почты и другие соответствующие данные. Вы несете ответственность за сохранение конфиденциальности данных вашего аккаунта и за то, чтобы к нему не получили доступ посторонние лица. Если вы подозреваете несанкционированную активность, вы должны немедленно уведомить нас, чтобы предотвратить дальнейшее злоупотребление.",
                    "Депозиты и Выводы",
                    "Депозиты на ваш игровой счет должны осуществляться с использованием официально одобренных Salta La Blanca методов оплаты. Все транзакции обрабатываются безопасно, и мы принимаем необходимые меры для защиты вашей финансовой информации. Выводы могут быть подвергнуты процедурам проверки личности для соблюдения юридических и регуляторных требований. Обратите внимание, что сроки обработки выводов могут варьироваться в зависимости от выбранного метода оплаты.",
                    "Бонусы и Акции",
                    "Мы предлагаем различные захватывающие бонусы и акции, чтобы улучшить ваш игровой опыт. Каждый бонус подчиняется определенным условиям, таким как требования по отыгрышу и сроки действия, которые будут четко указаны в деталях акции. Неправильное использование или злоупотребление акционными предложениями, включая попытки обойти правила, может привести к аннулированию бонусов и связанных с ними выигрышей.",
                    "Ответственная Игра",
                    "В Salta La Blanca мы стремимся продвигать ответственное отношение к азартным играм и обеспечивать безопасную среду для всех пользователей. Мы предоставляем инструменты, которые помогут вам управлять своей игровой активностью, такие как установка лимитов на депозиты, ставки и опции самоисключения. Если вы считаете, что ваши игровые привычки становятся проблематичными, мы настоятельно рекомендуем вам обратиться за помощью через доступные ресурсы, такие как [Поддержка Ответственной Игры].",
                    "Запрещенные Действия",
                    "Для поддержания честной и безопасной игровой среды любые мошеннические, манипулятивные или нечестные действия строго запрещены. Это включает, но не ограничивается, использование автоматизированных ботов, сговор с другими игроками или попытки эксплуатации уязвимостей системы. Участие в таких действиях приведет к немедленной блокировке или закрытию вашего аккаунта и аннулированию всех выигрышей.",
                    "Интеллектуальная Собственность",
                    "Весь контент на платформе Salta La Blanca, включая, но не ограничиваясь, логотипы, дизайны, тексты, программное обеспечение и мультимедиа, является исключительной собственностью нашей компании. Эти материалы защищены международными законами об авторском праве и интеллектуальной собственности. Любое несанкционированное использование, воспроизведение или распространение нашего контента строго запрещено и может повлечь за собой юридические последствия.",
                    "Ограничение Ответственности",
                    "Хотя мы стремимся обеспечить бесперебойное и приятное игровое обслуживание, Salta La Blanca не гарантирует, что наша платформа будет свободна от ошибок, перебоев или технических проблем. Мы не несем ответственности за любые прямые, косвенные или случайные убытки, возникшие в результате использования платформы, включая убытки, вызванные неудачными транзакциями, техническими сбоями или несанкционированным доступом к вашему аккаунту.",
                    "Изменения",
                    "Salta La Blanca оставляет за собой право вносить изменения в эти Условия и положения в любое время, чтобы отразить изменения в законодательных требованиях, деловой практике или отзывах пользователей. Обновления будут опубликованы на нашем сайте, и мы рекомендуем вам периодически пересматривать эти Условия. Ваше дальнейшее использование платформы после внесения изменений означает ваше согласие с обновленными Условиями.",
                    "Применимое Право и Споры",
                    "Эти Условия и положения регулируются законами [указать юрисдикцию]. Любые споры, возникающие в связи с использованием наших услуг, будут разрешаться в соответствии с этими законами. В случае разногласий мы рекомендуем пользователям обратиться в нашу службу поддержки для поиска мирного решения до начала судебного разбирательства.",
                    "Свяжитесь с Нами",
                ],
                email: `По любым вопросам, замечаниям или отзывам, касающимся этих Условий и положений, пожалуйста, не стесняйтесь обращаться в нашу службу поддержки по адресу <a href="mailto:support@saltalablanca.com" style="color: blue !important; text-decoration: underline !important; font-weight: 700;">support@saltalablanca.com</a>. Мы здесь, чтобы помочь вам и обеспечить ваш опыт на нашей платформе приятным и безопасным.`,
            },
            aboutUs: {
                content: [
                    "О Нас",
                    "Добро пожаловать в Salta La Blanca, ваш главный онлайн-ресурс для захватывающего и разнообразного игрового опыта. Независимо от того, являетесь ли вы опытным игроком или новичком в онлайн-играх, наша платформа создана, чтобы предложить вам идеальное сочетание азарта, разнообразия и качественного развлечения.",
                    "Что Мы Предлагаем",
                    "В Salta La Blanca мы гордимся тем, что предлагаем широкий выбор игровых опций, которые удовлетворят предпочтения каждого игрока. Вот что вы можете найти на нашей платформе:",
                    "Классические Казино: Окунитесь в атмосферу традиционных казино с такими играми, как блэкджек, рулетка и баккара. Благодаря современным улучшениям и элегантному дизайну, эти классические игры остаются такими же захватывающими, как и прежде, предлагая как стратегию, так и удачу для увлекательного опыта.",
                    "Игры на Выносливость: Ищете быстрый выброс адреналина? Наши игры на выносливость идеально подходят для тех, кто любит динамичный геймплей, где каждая секунда на счету. Проверьте свои рефлексы и интуицию, чтобы выиграть крупно в этой уникальной и захватывающей категории игр.",
                    "Живое Казино: Погрузитесь в атмосферу настоящего казино, не выходя из дома. С реальными дилерами, транслирующими в высоком разрешении, наше живое казино приносит азарт наземных казино прямо на ваш экран. Общайтесь с дилерами и другими игроками для по-настоящему социального и увлекательного опыта.",
                    "Спортивные Ставки: Для любителей спорта мы предлагаем раздел с широким выбором спортивных ставок, где вы можете делать ставки на свои любимые команды, матчи и события. С конкурентоспособными коэффициентами и разнообразием видов спорта, превратить вашу страсть к спорту в захватывающие ставки стало проще, чем когда-либо.",
                    "Настольные Игры: Любите игры на стратегию и навыки? Исследуйте широкий выбор настольных игр, которые бросают вызов вашему уму, предлагая бесконечное развлечение. От покера до крэпса — у нас есть что-то для каждого любителя настольных игр.",
                    "Широкий Выбор Провайдеров: Чтобы обеспечить высочайшее качество, мы сотрудничаем с лучшими поставщиками игр для казино в индустрии. Это означает, что у вас будет доступ к впечатляющей библиотеке игр с потрясающей графикой, инновационными функциями и надежной производительностью.",
                    "Почему Мы?",
                    "В Salta La Blanca мы не просто предлагаем игры — мы стремимся создать игровую среду, которая будет развлекательной, безопасной и ориентированной на игрока. Вот что делает нас особенными:",
                    "Инновации: Мы постоянно обновляем нашу платформу с учетом последних тенденций и технологий, чтобы предложить передовой игровой опыт. От высококачественной графики до уникальных игровых механик — мы стремимся сохранять свежесть и азарт для наших игроков.",
                    "Разнообразие: Мы понимаем, что каждый игрок уникален, поэтому предлагаем широкий выбор игр и вариантов ставок. Независимо от ваших предпочтений, вы найдете что-то, что подходит именно вам и увлечет вас.",
                    "Честная Игра: Честность лежит в основе всего, что мы делаем. Наши игры основаны на надежной технологии генератора случайных чисел (RNG), что гарантирует честные и непредвзятые результаты. Сотрудничество с проверенными поставщиками дополнительно обеспечивает прозрачность и надежность.",
                    "Ориентация на Игрока: Ваше удовлетворение — наш приоритет. Мы разработали нашу платформу так, чтобы она была удобной и интуитивно понятной, с плавной навигацией и функциями, которые делают игру приятной и беззаботной. Кроме того, наша команда поддержки всегда готова помочь вам с любыми вопросами или проблемами.",
                    "Присоединяйтесь к Salta La Blanca, где каждый спин, бросок и ставка создают возможности для незабываемых моментов. Готовы окунуться в азарт? Давайте сделаем каждую игру победной!",
                    "Онлайн-поддержка",
                ],
            },
            becomeAnAgent: {
                content: [
                    "Станьте агентом",
                    "Вы увлечены онлайн-играми и ищете возможность превратить эту страсть в прибыльное партнерство? Присоединяйтесь к Агентской программе Salta La Blanca и станьте важной частью нашего растущего сообщества. Это ваш шанс зарабатывать, общаться и процветать в динамичном мире онлайн-развлечений, продвигая платформу, которой вы можете гордиться.",
                    "Почему стоит стать партнером Salta La Blanca?",
                    "Когда вы присоединяетесь к нашей Агентской программе, вы не просто регистрируетесь — вы становитесь частью сети, которая ценит ваш вклад и вознаграждает ваши усилия. Вот что отличает нас:",
                    "Высокий потенциал заработка: Мы предлагаем одну из самых конкурентоспособных комиссионных структур в отрасли, давая вам возможность максимизировать доход на основе активности и лояльности игроков, которых вы привлекаете на нашу платформу.",
                    "Продвинутые маркетинговые инструменты: Получите доступ к набору профессиональных инструментов, включая персонализированные отслеживаемые ссылки, детальную аналитику производительности и разнообразные высококачественные промоматериалы, разработанные для вашего успеха.",
                    "Всесторонняя поддержка: Наша команда аффилиатов всегда готова поддержать вас на каждом этапе. Независимо от того, есть ли у вас технические вопросы или вам нужен маркетинговый совет, мы всегда на связи.",
                    "Разнообразный игровой портфель: Продвигайте платформу, которая предлагает что-то для каждого: от классических казино до игр с живыми дилерами, краш-игр, спортивных ставок и многого другого — все это предоставлено ведущими игровыми провайдерами.",
                    "Чем занимается агент?",
                    "Как агент Salta La Blanca, ваша задача — распространять информацию о нашей платформе и привлекать новых игроков. Вы будете использовать свою сеть, креативность и маркетинговые навыки для расширения аудитории, одновременно зарабатывая значительные вознаграждения. Это идеальное решение, независимо от того, являетесь ли вы опытным маркетологом, создателем контента или просто человеком с сильной страстью к играм.",
                    "Как работает программа?",
                    "Зарегистрируйтесь: Пройдите быстрый и простой процесс регистрации, чтобы стать официальным агентом.",
                    "Получите инструменты: Получите доступ к персонализированной панели управления с отслеживаемыми ссылками, промо-баннерами и другими маркетинговыми ресурсами.",
                    "Продвигайте нашу платформу: Используйте свои инструменты, чтобы делиться Salta La Blanca с потенциальными игроками через веб-сайты, социальные сети, форумы или любую другую подходящую платформу.",
                    "Зарабатывайте комиссионные: За каждого привлеченного игрока вы будете получать комиссионные в зависимости от их активности, обеспечивая стабильный поток дохода.",
                    "Кто может присоединиться?",
                    "Наша программа открыта для всех, кто стремится к успеху и имеет связь с миром игр. Независимо от того, являетесь ли вы влиятельным лицом с большой аудиторией, энтузиастом социальных сетей или человеком с сетью друзей, которые любят игры, мы предоставляем все необходимое для превращения ваших усилий в ощутимые результаты.",
                    "Ваш успех — это наш успех",
                    "В Salta La Blanca мы верим в построение сильных, взаимовыгодных отношений с нашими агентами. Именно поэтому мы инвестируем в ваш успех, предлагая прозрачные системы выплат, постоянную поддержку и платформу, которую любят игроки. Когда вы добиваетесь успеха, мы тоже добиваемся успеха.",
                    "Готовы присоединиться?",
                    "Стать агентом Salta La Blanca просто и выгодно. Сделайте первый шаг к более захватывающему будущему, зарегистрировавшись сегодня. Нажмите [Стать агентом] и начните зарабатывать, став частью одного из самых надежных имен в мире онлайн-игр.",
                ],
            },
            dashboard: {
                sideMenu: {
                    home: "Главная",
                    lobby: "Лобби",
                    casino: "Казино",
                    sport: "Спорт",
                    liveBetting: "Лайв-ставки",
                    liveCasino: "Лайв-казино",
                    crashGames: "Краш-игры",
                    tableGames: "Настольные игры",
                    legends: "Легенды",
                    becomeAnAgent: "Стать агентом",
                    privacyPolicy: "Политика конфиденциальности",
                    responsibleGaming: "Ответственная игра",
                    termsAndConditions: "Условия и положения",
                    aboutUs: "О нас",
                    userInfo: "Информация о пользователе",
                    gameHistory: "История игр",
                    transactions: "Транзакции",
                },
                loggedInUserMenu: {
                    userInfo: "Информация о пользователе",
                    userTransactions: "Транзакции",
                    userGameHistory: "История игр",
                    userLogOut: "Выйти",
                },
                userInfo: {
                    userInfoSection: "Информация о пользователе",
                    changePasswordSection: "Изменить пароль",
                },
            },
            carousel: {
                slide1: {
                    header: "Покори Тотем<br/> и Завоюй Победу",
                    tagline: "Бросай и Побеждай с Galaxsys!",
                },
                slide2: {
                    header: "Ощутите Азарт<br/>только в Salta la Banca",
                    tagline: "Присоединяйтесь к игре сегодня!",
                },
                slide3: {
                    header: "Представь больше, Выигрывай больше <br/>в Imagine Live",
                    tagline: "Ставки реальны",
                },
                slide4: {
                    header: "Чувствуй азарт игры!<br/>Живи моментом победы!",
                    tagline: "Присоединяйся сейчас!",
                },
                slide5: {
                    header: "Ezugi скоро появится",
                    tagline: "В конце этой недели!",
                },
            },
            menuLinkTitles: {
                home: "Главная",
                casino: "Казино",
                liveCasino: "Казино Live",
                virtualGames: "Виртуальные игры",
            },
            sideBarSections: {
                games: "Игры",
                topCrashGames: "Топ краш-игры",
                other: "Прочие",
                sportsRules: "Правила спорта",
            },
            headerMenu: {
                home: "Главная",
                casino: "Казино",
                slots: "Слоты",
                shooters: "Шутеры",
                tableGames: "Настольные игры",
                liveCasino: "Лайв-казино",
                crash: "Краш-игры",
                sport: "Спорт",
                menu: "Меню",
                lobby: "Лобби",
            },
            categoriesDescription: {
                casino: "Окунитесь в Азарт Казино",
                slots: "Слоты!",
                shooters: "Целься и Стреляй!",
                tableGames:
                    "Классические Настольные Игры, Современные Ощущения – Играйте и Выигрывайте!",
                liveCasino: "Присоединяйтесь к Азарту Лайв-Казино",
                crash: "Гонитесь за Множителем и Ломайте Лимиты!",
                sport: "Сделайте Ставки на Самые Крупные Игры",
            },
            footer: {
                content: [
                    "Добро пожаловать в Salta la Banca",
                    `Ваш лучший выбор для азартных игр онлайн! Погрузитесь в мир спортивных ставок, изучите нашу обширную коллекцию казино-игр и наслаждайтесь играми с живыми дилерами в любое время и в любом месте.\n\nБлагодаря безопасным транзакциям и бесперебойной работе мы здесь, чтобы сделать вашу игровую практику еще лучше.\n\nПрисоединяйтесь к нам, и пусть начнется азарт!\n\nВсе права защищены © Salta la Banca 2024-2025`,
                    `Вам должно быть не менее 18 лет, чтобы зарегистрироваться и открыть учетную запись у нас. Компания оставляет за собой право запросить подтверждение возраста у любого клиента, и учетные записи могут быть приостановлены до предоставления удовлетворительного подтверждения возраста. Администрация сайта не несет ответственности за действия или решения, принятые пользователями. Этот сайт предоставляет возможность для развлечения и игр, и все действия предназначены исключительно для рекреационных целей. Все права защищены. Несанкционированное использование или воспроизведение содержимого сайта строго запрещено и охраняется законом.`,
                ],
                columnSaltaLaBanca: [
                    "Salta la Banca",
                    "Главная",
                    "Спорт",
                    "Казино",
                    "Live Казино",
                    "Игры на Crash",
                    "Настольные игры",
                ],
                columnAboutUs: [
                    "О Нас",
                    "Партнеры",
                    "Условия бонусов",
                    "Проверка личности",
                    "Политика конфиденциальности",
                    "Ответственная игра",
                    "Варианты оплаты",
                ],
                contactUsBTN: "Свяжитесь с нами",
            },
        },
    },
};
