import { FC } from "react";
import { Button, Box, Typography, Stack } from "@mui/material";
import { FooterProps } from "./Footer";
import { Link } from "react-router-dom";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../features/localisation/useLanguage";
import { Logo } from "../logo/Logo";
import HeadPhonesIcon from "../../../assets/svg/HeadPhonesIcon";
import { PATHS } from "../../features/navigation/paths";

export const DesktopFooter: FC<FooterProps> = ({ scrollContainerRef }) => {
  const { xLargeScreen, largeScreen, mediumScreen } = useCommonMediaQueries();

  const {
    languagePack: {
      pack: {
        argentina: {
          footer: { content, columnSaltaLaBanca, contactUsBTN },
        },
      },
    },
  } = useLanguage();

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "20px 30px",
        padding: "40px 50px 20px 50px",
        borderRadius: "20px",
        background:
          "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 5.946%,rgba(40, 47, 79, 0.7) 95.5%)",
        width: "100%",
        boxSizing: "border-box",
      }}
      mt={3}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            flex: 1,
            maxWidth: "500px",
          }}
        >
          <Link
            to={PATHS.home}
            onClick={scrollToTop}
            style={{ textDecoration: "none" }}
          >
            <Logo />
          </Link>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <Typography
              sx={{
                color: "rgb(228, 233, 248)",
                fontSize: "24px",
                fontWeight: 800,
                lineHeight: "29px",
                textAlign: "justify",
              }}
            >
              {content[0]}
            </Typography>
            <Typography
              sx={{
                color: "rgb(228, 233, 248)",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "17px",
                width: xLargeScreen
                  ? "35vw"
                  : mediumScreen || largeScreen
                    ? "50vw"
                    : "50vw",
              }}
              whiteSpace='pre-line'
            >
              {content[1]}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              padding: "10px 20px 10px 10px",
              border: "2px solid rgba(228, 233, 248, 0.1) !important",
              borderRadius: "10px",
              alignSelf: "flex-start",
            }}
            onClick={() => window.Tawk_API?.maximize?.()}
          >
            <HeadPhonesIcon /> {contactUsBTN}
          </Button>
        </Box>

        <Stack gap='14px' mr={10}>
          <Typography
            sx={{
              color: "rgba(174, 194, 255, 0.3)",
              fontSize: "14px",
              fontWeight: 800,
              lineHeight: "17px",
              textAlign: "left",
            }}
          >
            {columnSaltaLaBanca[0]}
          </Typography>
          <Link
            to={PATHS.home}
            style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[1]}
          </Link>
          <Link
            to={PATHS.sport}
            style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[2]}
          </Link>
          <Link
            to={PATHS.casino}
            style={{ color: "hsl(225, 58.80%, 93.30%)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[3]}
          </Link>
          <Link
            to={PATHS.liveCasino}
            style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[4]}
          </Link>
          <Link
            to={PATHS.crash}
            style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[5]}
          </Link>
          <Link
            to={PATHS.tableGames}
            style={{ color: "rgb(228, 233, 248)", textDecoration: "none" }}
          >
            {columnSaltaLaBanca[6]}
          </Link>
        </Stack>
      </Box>

      <Typography
        sx={{
          color: "rgba(228, 233, 248, 0.5)",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "15px",
          textAlign: "justify",
          width: "100%",
          marginTop: "20px",
        }}
      >
        {content[2]}
      </Typography>
    </Box>
  );
};
