import { iUseTopWins } from "./models/hooks";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import axios, { AxiosError } from "axios";
import { useLanguage } from "../localisation/useLanguage";
import { setTopWins, setTopWinsLoader } from "./topWinsSlice";
import { TopWinsLoader } from "./models/loaders";
import { setPlayGamesLoader } from "../play_games/playGamesSlice";
import { convertUnixToCompactLocaleDate } from "../common_funcs";
import { v4 as uuidv4 } from "uuid";
import { ShowTopWinsResponse } from "./models/response";
import { TopWinGame } from "./models/model";
import LoaderService, { ApiRequest } from "../../components/games/hooks/loaderService";
import { PlayGamesLoader } from "../play_games/playGamesModels";
import { getApiHost } from "../../config";

export const useTopWins = (): iUseTopWins => {
    const dispatch = useAppDispatch();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();
    const token = useAppSelector((state) => state.user.token ?? null);
    const apiURL = getApiHost();

    const topWinsState = useAppSelector((state) => state.topWins);
    const playGames = useAppSelector((state) => state.playGames.games); // Получаем список игр

    const hasTopWinsLoaded = useRef(false);
    const isPicUpdated = useRef(false);

    const mapPicUrls = useCallback(
        (topWins: TopWinGame[] | null): TopWinGame[] => {
            if (!topWins) return [];
            return topWins.map((topWin) => ({
                ...topWin,
                picUrl: playGames?.find((game) => game.Name === topWin.game_name)?.Pic || undefined,
            }));
        },
        [playGames]
    );

    const getTopWins = useCallback(() => {
        if (!token) return;

        if (!LoaderService.isLoading(ApiRequest.ShowTopWins)) {
            LoaderService.setLoading(ApiRequest.ShowTopWins, true);
            dispatch(
                setTopWinsLoader({
                    loader: TopWinsLoader.isTopWinsLoading,
                    isLoading: true,
                })
            );

            const data = new FormData();
            data.append("action", ApiRequest.ShowTopWins);
            data.append("token", token);

            axios
                .post<ShowTopWinsResponse>(apiURL!, data)
                .then((response) => {
                    const { success, error, data: topWins } = response.data;
                    if (success && topWins) {
                        const processedTopWins = topWins.map((topWin) => ({
                            ...topWin,
                            time: convertUnixToCompactLocaleDate(topWin.time_unix),
                            uuid: uuidv4(),
                            multiplier: parseFloat(
                                (topWin.deposit_sum / topWin.withdraw_sum).toFixed(2)
                            ),
                        }));

                        dispatch(setTopWins(processedTopWins));
                        hasTopWinsLoaded.current = true;
                        isPicUpdated.current = false;
                    }

                    if (error) {
                        if (error.code === 2) {
                            dispatch(setTopWins([]));
                        } else {
                            setLocalizedError(error);
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    handleNetworkErrors(error);
                })
                .finally(() => {
                    LoaderService.finishLoading(ApiRequest.ShowTopWins);
                    dispatch(
                        setPlayGamesLoader({
                            loader: PlayGamesLoader.isProvidersOutcomeLoading,
                            isLoading: false,
                        })
                    );
                });
        }
    }, [apiURL, token, dispatch, handleNetworkErrors, setLocalizedError]);

    useEffect(() => {
        if (hasTopWinsLoaded.current && playGames && !isPicUpdated.current) {
            const updatedTopWins = mapPicUrls(topWinsState.topWins);
            dispatch(setTopWins(updatedTopWins));
            isPicUpdated.current = true;
        }
    }, [playGames, topWinsState.topWins, dispatch, mapPicUrls]);

    return {
        ...topWinsState,
        getTopWins,
    };
};
