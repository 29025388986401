import { FC, useEffect, useState } from "react";
import { useLanguage } from "../../features/localisation/useLanguage";
import { CircularProgress } from "@mui/material";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import Stack from "@mui/material/Stack";
import { PageLayout } from "../../components/pageLayout/PageLayout";
import { TopWins } from "../../components/topWins/TopWins";

export const SportPage: FC = () => {
  const {
    languagePack: {
      pack: {
        argentina: { headerMenu: menuLang },
      },
    },
  } = useLanguage();
  const { openSportsBook, isSportsBookLoading, sportUrl } = usePlayGames();
  const [justMounted, setJustMounted] = useState(true);

  useEffect(() => {
    if (justMounted) {
      setJustMounted(false);
      openSportsBook();
    }
  }, [justMounted, openSportsBook]);

  return (
    <PageLayout
      pageName={menuLang.sport}
      carouselDisabled
      flexGrowContentDisabled
      mobileBottomMenuDisabled
    >
      <Stack height={"100%"} width={"100%"} flexGrow={1} gap={3}>
        {isSportsBookLoading ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            height={"100%"}
            width={"100%"}
            flexGrow={1}
          >
            <CircularProgress />
          </Stack>
        ) : sportUrl !== null ? (
          <iframe
            title={"sport"}
            style={{
              flexGrow: 1,
              border: "none",
              minHeight: 'calc(100vh - 120px)',
              height: 'calc(100vh - 120px)' /* 120 is header height */
            }}
            src={sportUrl}
          />
        ) : (
          <></>
        )}

        <TopWins />

      </Stack>

    </PageLayout>
  );
};
