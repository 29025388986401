import { FC, useEffect, useLayoutEffect, useRef } from "react";
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

interface MessengerProps {
    sessionUrl: string | null;
}

export const Messenger: FC<MessengerProps> = ({ sessionUrl }) => {
    const tawkMessengerRef = useRef<typeof window.Tawk_API>(null);
    const prevSessionUrl = useRef<string | null | undefined>();

    useLayoutEffect(() => {
        let attempts = 0;
        const maxAttempts = 100;
        const interval = setInterval(() => {
            try {
                tawkMessengerRef.current!.hideWidget!();
                tawkMessengerRef.current!.minimize!();
                clearInterval(interval);
            } catch (e) {
                attempts++;
                if (attempts >= maxAttempts) {
                    clearInterval(interval);
                    console.warn("Max attempts to hide chat reached. Stopping.");
                }
            }
        }, 20);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (sessionUrl !== prevSessionUrl.current) {
            if (sessionUrl !== null) {
                tawkMessengerRef.current?.minimize?.();
            }
        }
        prevSessionUrl.current = sessionUrl;
    }, [sessionUrl, tawkMessengerRef])


    return (
        <TawkMessengerReact
            propertyId="66ddfa5eea492f34bc0f93ec"
            widgetId="1i79hk1eb"
            customStyle={{
                visibility: {
                    desktop: {
                        xOffset: '15',
                        yOffset: '65',
                        position: 'br'
                    },

                    mobile: {
                        xOffset: 15,
                        yOffset: 65,
                        position: 'br'
                    },
                }
            }}
            ref={tawkMessengerRef}
        />
    )
}