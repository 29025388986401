import { FC } from "react";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import CloseIcon from '@mui/icons-material/Close';
import { VioletButton } from "../violetButton/VioletButton";
import { useLanguage } from "../../features/localisation/useLanguage";
import { TopWinGame } from "../../features/top_wins/models/model";
import { getMediaCDN } from "../../config";
import { useSnackbar } from "notistack";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../features/navigation/paths";

interface InfoPopupProps {
    game: TopWinGame | null;
    close: VoidFunction;
}

export const InfoPopup: FC<InfoPopupProps> = ({ game, close }) => {
    const { xxSmallScreen, smallScreen } = useCommonMediaQueries();
    const cdn = getMediaCDN();
    const naviagte = useNavigate();
    const {
        languagePack: {
            pack: {
                topWinModal
            },
        },
    } = useLanguage();
    const { enqueueSnackbar } = useSnackbar();
    const { isGameSessionLoading, startGameSession } = usePlayGames();
    const gameInternalIdsByName = useAppSelector((state) => {
        const res: { [key: string]: number } = {};
        if (!state.playGames.games) return res;
        for (let game of state.playGames.games) {
            res[game.Name] = game.internal_id;
        }
        return res;
    })

    if (!game) return null;

    const handleGameClick = () => {
        if (!isGameSessionLoading) {
            startGameSession(gameInternalIdsByName[game.game_name]);

            naviagte(`${PATHS.lounchGame}?internalId=${gameInternalIdsByName[game.game_name]}`)
            return;
        }

        enqueueSnackbar("Login required", { variant: "warning" });
    };

    return (
        <Dialog
            open={!!game}
            onClose={close}
            maxWidth={false}
            sx={xxSmallScreen ? {
                ".MuiPaper-root": {
                    margin: 0,
                    maxWidth: '95%'
                }
            } : {}}
        >
            <DialogContent
                sx={{
                    background: "radial-gradient(117.03% 100% at 51.3% 100%, #141931 0%, #0F1328 56.5%, #05091E 100%)",
                    minWidth: '500px',
                    ...(smallScreen && {
                        minWidth: '310px'
                    }),
                    padding: 0,
                }}
            >

                <Stack
                    width='100%'
                    p={xxSmallScreen ? '16px' : '30px'}
                    gap='20px'
                >
                    <CloseIcon onClick={close} sx={{ position: 'absolute', top: 20, right: 20, height: 20, width: 20, cursor: 'pointer', color: '#AEC2FF4D' }} />

                    <Typography
                        fontWeight='bold'
                    >
                        {topWinModal.aboutBet}
                    </Typography>

                    <Stack>
                        <Stack direction='row' gap='30px'>
                            <img src={`${cdn}/game_img/sqr_mid/${game.picUrl}`} width='100' height='130' style={{ borderRadius: 6 }} alt={game.game_name} />

                            <Stack>
                                <Typography
                                    sx={{ background: 'linear-gradient(180deg, #E4E9F8 6.9%, #8B98BE 98.28%)', '-webkit-background-clip': 'text' }}
                                    color="transparent"
                                    fontWeight='bold'
                                    variant={smallScreen ? 'body1' : 'h4'}
                                >
                                    {game.game_name}
                                </Typography>


                                <Stack direction={smallScreen ? 'column' : 'row'} gap='10px' mt={2.5} mb={1}>
                                    <Stack direction='row' gap='4px'>
                                        <Typography fontWeight='bold' color='#AEC2FF4D' variant={smallScreen ? 'caption' : 'body2'}>{topWinModal.dateLabel}:</Typography>
                                        <Typography fontWeight='bold' variant={smallScreen ? 'caption' : 'body2'}>{game.time.slice(0, 10)}</Typography>
                                    </Stack>

                                    <Stack direction='row' gap='4px'>
                                        <Typography fontWeight='bold' color='#AEC2FF4D' variant={smallScreen ? 'caption' : 'body2'}>{topWinModal.betIdLable}:</Typography>
                                        <Typography fontWeight='bold' variant={smallScreen ? 'caption' : 'body2'}>{game.time_unix}</Typography>
                                    </Stack>
                                </Stack>

                                <Typography color='#B431AD' fontWeight='bold' variant={smallScreen ? 'caption' : 'body1'}>{game.user_name}</Typography>

                            </Stack>

                        </Stack>

                        <Stack direction='row' gap='20px' mt={4} justifyContent='space-between' alignItems='flex-end'>
                            <Stack width='33%'>
                                <Typography color='#AEC2FF4D' gutterBottom variant={smallScreen ? 'caption' : 'body1'}>{topWinModal.betAmount}</Typography>
                                <Box sx={{ backgroundColor: '#1C223F', borderRadius: '10px', p: '10px' }} justifyContent='center' display='flex'>
                                    <Typography>{game.withdraw_sum}</Typography>
                                </Box>
                            </Stack>
                            <Stack width='33%'>
                                <Typography color='#AEC2FF4D' gutterBottom variant={smallScreen ? 'caption' : 'body1'}>{topWinModal.multiplier}</Typography>
                                <Box sx={{ backgroundColor: '#1C223F', borderRadius: '10px', p: '10px' }} justifyContent='center' display='flex'>
                                    <Typography>{game.multiplier}</Typography>
                                </Box>
                            </Stack>
                            <Stack width='33%'>
                                <Typography color='#AEC2FF4D' gutterBottom variant={smallScreen ? 'caption' : 'body1'}>{topWinModal.payout}</Typography>
                                <Box sx={{ backgroundColor: '#1C223F', borderRadius: '10px', p: '10px' }} justifyContent='center' display='flex'>
                                    <Typography>{game.deposit_sum}</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    <VioletButton
                        sx={{ mt: 'auto' }}
                        onClick={() => {
                            close();
                            handleGameClick();
                        }}
                    >
                        {topWinModal.play}
                    </VioletButton>
                </Stack>

            </DialogContent>
        </Dialog >
    );
};
