import { useMemo, useState } from "react";
import { GameType } from "../../../features/play_games/playGamesModels";
import { usePlayGames } from "../../../features/play_games/usePlayGames";

export const useGameSectionLogic = (gameType?: GameType) => {
  const { games } = usePlayGames();
  const [page, setPage] = useState<number>(1);

  const filteredGames = gameType
    ? games?.filter((game) => game.game_type === gameType)
    : games;

  const totalPages = useMemo(() => {
    return filteredGames ? Math.ceil(filteredGames?.length / 8) : 0;
  }, [filteredGames]);

  const offset = useMemo(() => {
    return page > 0 ? (page - 1) * 8 : 0;
  }, [page]);

  const switchPage = (direction: "back" | "forward") => {
    if (direction === "back" && page > 0) setPage((prevState) => prevState - 1);
    if (direction === "forward" && page < totalPages)
      setPage((prevState) => prevState + 1);
  };

  return { filteredGames, page, switchPage, totalPages, offset };
};
