import { ElementType, FC, useState } from "react";
import { Box, Stack, SvgIconProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

type IconComponentType = ElementType<SvgIconProps & { hovered?: boolean }>;

interface BottomMenuItemProps {
    link?: string;
    title: string;
    icon?: IconComponentType;
    onClick?: () => void;
    titleColor?: string;
    hoverTitleColor?: string;
    active?: boolean
}

export const BottomMenuItem: FC<BottomMenuItemProps> = ({
    link,
    title,
    icon: Icon,
    onClick,
    titleColor = "rgba(174, 194, 255, 0.3)",
    hoverTitleColor = "rgb(255, 255, 255)",
    active = false
}) => {
    const [hovered, setHovered] = useState(false);

    const showActiveStyles = hovered || active;

    return (
        <Stack
            {...(link !== undefined ? { component: Link, to: link } : {})}
            onClick={onClick}
            flex={1}
            height={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box
                height={'24px'}
                sx={{
                    aspectRatio: '1/1',
                }}
            >
                {Icon && <Icon hovered={showActiveStyles} />}
            </Box>
            <Typography
                sx={{
                    position: "static",
                    width: "33px",
                    height: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "5px",
                    padding: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    margin: "5px 0px",
                    transform: "translateY(-5px)",
                    color: showActiveStyles ? hoverTitleColor : titleColor,
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "100%",
                    letterSpacing: "0%",
                    textAlign: "left",
                    textTransform: "capitalize",
                }}
            >
                {title}
            </Typography>
        </Stack>
    );
};