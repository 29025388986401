import { ButtonProps } from "@mui/material";

export const titleTextStyle = {
  color: "rgb(228, 233, 248)",
  fontSize: "29px",
  fontWeight: 700,
  lineHeight: "100%",
  textAlign: "justify",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: "21px",
  flex: "none",
  order: 0,
  flexGrow: 0,
  background:
    "linear-gradient(180.00deg, rgb(228, 233, 248), rgb(139, 152, 190))",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
  letterSpacing: "0%",
};

export const subtitleTextStyle = {
  color: "rgb(228, 233, 248)",
  fontSize: "18px",
  fontWeight: 800,
  lineHeight: "29px",
  textAlign: "justify",
};

export const contentTextStyle = {
  color: "rgba(228, 233, 248, 0.5)",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17px",
  textAlign: "justify",
};

export const myCommonButtonProps: ButtonProps = {
    variant: "outlined",
    sx: {
      position: "static",
      height: "44px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "10px 0px",
      flex: "none",
      order: 0,
      flexGrow: 0,
      margin: "0px 7px",
      boxSizing: "border-box",
      border: "2px solid rgb(40, 47, 79)",
      borderRadius: "10px",
      backgroundColor: "transparent",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        border: "2px solid rgb(40, 47, 79)",
      },
    },
  };

// Adding export {} to make this file a module
export {};
