export const PATHS = {
    home: "/",
    lobby: "/lobby",
    casino: "/casino",
    sport: "/sport",
    liveCasino: "/live_casino",
    crash: "/crash",
    tableGames: "/table_games",
    legends: "/legends",
    becomeAnAgent: "/become_an_agent",
    policy: "/policy",
    terms: "/terms",
    aboutUs: "/about_us",
    games: "/games",
    slots: "/slots",
    shooters: "/shooters",
    lounchGame: "/lounch_game",
    dashboard: "/dashboard",
    userInfo: "user_info",
    gameHistory: "game_history",
    transactions: "transactions",
    searchResults: "/search_results",
};
