import React, { FC, ElementType, JSX } from "react";
import {
  Box,
  Button,
  Stack,
  SvgIconProps,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useLanguage } from "../../features/localisation/useLanguage";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { DecoratedTitle } from "./DecoratedTitle";
import { myCommonButtonProps } from "../../pages/siteInfo/general_styles";
import DirectionalIconButton from "../directionalIconButton/DirectionalIconButton";


interface SectionDividerProps {
  title?: string;
  icon?: ElementType<SvgIconProps> | string | JSX.Element; // SVG icon, URL, or JSX.Element
  link?: string;
  onClick?: () => void;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  showLeftRightButtons?: boolean;
  customButtonName?: string;
}

export const SectionDivider: FC<SectionDividerProps> = (props) => {
  const {
    languagePack: {
      pack: {
        argentina: { common: lang },
      },
    },
  } = useLanguage();
  const {
    title,
    onLeftButtonClick,
    onRightButtonClick,
    leftButtonDisabled,
    rightButtonDisabled,
    link,
    onClick,
    icon,
    showLeftRightButtons = true,
    customButtonName,
  } = props;
  // Handle different types of icons
  const isIconComponent =
    icon &&
    (typeof icon === "function" || typeof icon === "object") &&
    !React.isValidElement(icon); // Ensure it's not a JSX.Element
  const Icon = isIconComponent
    ? (icon as React.ElementType<SvgIconProps>)
    : undefined; // Cast to ElementType<SvgIconProps>
  const iconUrl =
    icon !== undefined && typeof icon === "string" ? icon : undefined;
  const iconElement =
    icon !== undefined && React.isValidElement(icon) ? icon : undefined;
  const { smallScreen, xxSmallScreen } = useCommonMediaQueries();
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box display={"flex"} alignItems={"center"} gap={0}>
        {Icon && (
          <Icon
            sx={{
              fontSize: theme.typography.pxToRem(smallScreen ? 24 : 28),
              color: theme.palette.primary.main,
            }}
          />
        )}
        {iconUrl && (
          <Box
            sx={{
              backgroundImage: `url(${iconUrl})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: smallScreen ? "28px" : "38px",
              aspectRatio: "1/1"
            }}
          />
        )}
        {iconElement &&
          iconElement
        }
        <DecoratedTitle
          fontSize={theme.typography.pxToRem(xxSmallScreen ? 16 : smallScreen ? 20 : 28)}
          xxSmallScreen={xxSmallScreen}
          smallScreen={smallScreen}
        >
          {title}
        </DecoratedTitle>
      </Box>

      <Box display={"flex"} alignItems={"center"} gap={0.3}>
        {(link || onClick) && (
          <Button
            {...myCommonButtonProps}
            {...(link !== undefined ? { component: Link, to: link } : {})}
            onClick={onClick}
            sx={{
              ...myCommonButtonProps.sx,
              px: theme.spacing(2),
              height: xxSmallScreen ? '36px' : '44px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "rgb(228, 233, 248)",
                fontWeight: 600,
                lineHeight: "17px",
                whiteSpace: "nowrap",
                fontSize: xxSmallScreen ? '12px' : undefined
              }}
            >
              {customButtonName ?? lang.seeAllBTN}
            </Typography>
          </Button>
        )}
        {showLeftRightButtons && (
          <Stack direction="row" gap={xxSmallScreen ? 1 : 0.5}>
            <DirectionalIconButton
              direction="left"
              disabled={leftButtonDisabled}
              onClick={onLeftButtonClick}
            />
            <DirectionalIconButton
              direction="right"
              disabled={rightButtonDisabled}
              onClick={onRightButtonClick}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};
