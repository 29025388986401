import { SvgIcon, SvgIconProps } from "@mui/material";
import { FC } from "react";

interface HeadMicrophoneIconProps extends SvgIconProps {
  fill?: string;
}

const HeadMicrophoneIcon: FC<HeadMicrophoneIconProps> = ({ fill = "", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        fill={fill}
        fillOpacity="1.000000"
        fillRule="nonzero"
        d="M10 0C4.48 0 0 4.48 0 10L0 14.14C0 15.16 0.89 16 2 16L3 16C3.26 16 3.51 15.89 3.7 15.7C3.89 15.51 4 15.26 4 15L4 9.85C4 9.59 3.89 9.33 3.7 9.14C3.51 8.96 3.26 8.85 3 8.85L2.09 8.85C2.64 4.98 5.97 2 10 2C14.02 2 17.35 4.98 17.9 8.85L17 8.85C16.73 8.85 16.48 8.96 16.29 9.14C16.1 9.33 16 9.59 16 9.85L16 16C16 17.1 15.1 18 14 18L12 18L12 17L8 17L8 20L14 20C16.2 20 18 18.2 18 16C19.1 16 20 15.16 20 14.14L20 10C20 4.48 15.51 0 10 0Z"
      />
    </SvgIcon>
  );
};

export default HeadMicrophoneIcon;