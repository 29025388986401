import { Box, Button, SvgIconProps } from "@mui/material";
import { ElementType, FC, useState } from "react";
import { Link } from "react-router-dom";

export const MenuItem: FC<{
    text: string;
    icon?: ElementType<SvgIconProps>;
    path?: string;
    onClick?: () => void;
    color?: string; 
    active: boolean;
}> = ({ text, icon: Icon, path, onClick, color, active }) => {
    const [hovered, setHovered] = useState(false);

    const showActiveStyles = hovered || active;

    return (
      <Button
        variant={"text"}
        {...(path !== undefined ? { component: Link, to: path } : {})}
        onClick={onClick}
        onMouseEnter={() => setHovered(true)} 
        onMouseLeave={() => setHovered(false)} 
        sx={{
          px: 1,
          justifyContent: "start",
          gap: 1,
          textTransform: "capitalize",
          "&:hover": {
            color: "rgb(255, 255, 255)", 
          },
        }}
      >
        {Icon && (
          <Icon
            sx={{
              color: showActiveStyles ? "rgba(180, 49, 173, 1)" : color || "rgba(174, 194, 255, 0.3)", // Icon color: red on hover, otherwise use the provided color or default
            }}
          />
        )}
        <Box
          component="span"
          sx={{
            color: showActiveStyles ? "rgb(255, 255, 255)" : color || "rgba(174, 194, 255, 0.3)",
          }}
        >
          {text}
        </Box>
      </Button>
    );
  };