import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { ApiError, ApiWarning } from "./apiResponseModels";

interface ErrorState {
  apiError: ApiError | null;
  warningsChannel: ApiWarning[];
}

const initialState: ErrorState = {
  apiError: null,
  warningsChannel: [],
};

const warningTypeErrorCodes: number[] = [
  // Add all error codes which has warning only type
];

export const ErrorSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    addNewError: (state, action: PayloadAction<ApiError>) => {
      const errorObj = action.payload;
      if (warningTypeErrorCodes.includes(errorObj.code)) {
        const warningObj = {
          ...errorObj,
          fired: false,
          localId: uuidv4(),
        };
        state.warningsChannel = [warningObj, ...state.warningsChannel];
      } else {
        state.apiError = errorObj;
      }
    },
    removeError: (state) => {
      state.apiError = null;
    },
    deleteOneWarning: (state, action: PayloadAction<ApiWarning>) => {
      const { localId } = action.payload;

      const indexToRemove = state.warningsChannel.findIndex(
        (warning) => warning.localId === localId
      );

      if (indexToRemove !== -1) {
        state.warningsChannel = [
          ...state.warningsChannel.slice(0, indexToRemove),
          ...state.warningsChannel.slice(indexToRemove + 1),
        ];
      }
    },
    clearAllWarnings: (state) => {
      state.warningsChannel = [];
    },
  },
});

export default ErrorSlice.reducer;

export const { addNewError, removeError, deleteOneWarning, clearAllWarnings } =
  ErrorSlice.actions;
