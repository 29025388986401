import { Box, Typography } from "@mui/material";

interface UserNameProps {
  text1: string;
  text2: string;
  dark?: boolean;
}

export const UserName = ({
  text1,
  text2,
  dark = true,
}: UserNameProps) => {
  const fieldTitleStyle = {
    color: "rgba(174, 194, 255, 0.3)",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "100%",
    textAlign: "left",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-end",
        alignItems: "left",
        border: "1px solid rgba(228, 233, 248, 0.1)",
        borderRadius: "10px",
        padding: "5px 10px",
        background: dark
          ? "radial-gradient(117% 100% at 51% 100%, rgb(20, 25, 49), rgb(15, 19, 40) 56.5%, rgb(5, 9, 30) 100%)"
          : "linear-gradient(-61.00deg, rgba(28, 34, 63, 0.7) 41.978%, rgba(40, 47, 79, 0.7) 99.305%)",
        width: "fit-content",
        minWidth: "100px",
      }}
    >
      <Typography sx={fieldTitleStyle}>{text1}</Typography>
      <Typography
        textTransform={"uppercase"}
        fontWeight={500}
        fontSize={"14px"}
        sx={{ userSelect: "none" }}
      >
        {text2}
      </Typography>
    </Box>
  );
};
