import { FC } from "react";
import { Box, Stack } from "@mui/material";
import MenuImage from "../../../../../src/assets/bottom_menu_icon.png";
import { BottomMenuItem } from "./BottomMenuItem";
import { useLanguage } from "../../../features/localisation/useLanguage";
import LobbyIcon from "../../../../assets/svg/LobbyIcon";
import SportIcon from "../../../../assets/svg/SportIcon";
import LegendsIcon from "../../../../assets/svg/LegendsIcon";
import MenuLines from "../../../../assets/svg/MenuLines";
import { PATHS } from "../../../features/navigation/paths";
import { useQuery } from "../../../features/common_funcs/query_hook/useQuery";

interface BottomMenuProps {
  requestSwitchDrawer?: () => void;
}

export const BottomMenu: FC<BottomMenuProps> = ({
  requestSwitchDrawer,
}) => {
  const {
    languagePack: {
      pack: {
        argentina: {
          dashboard: { sideMenu: landDashboardMenu },
          headerMenu: menuLang
        },
      },
    },
  } = useLanguage();

  const { currentPath } = useQuery()

  return (
    <Box
      position="relative"
      zIndex={Number.MAX_SAFE_INTEGER}
      minHeight={80}
      display="flex"
      sx={{
        background: `rgb(5, 9, 30)`,
        borderTop: "1px solid #D43DCC",
      }}
    >
      <BottomMenuItem
        link={PATHS.sport}
        title={landDashboardMenu.sport}
        active={currentPath.endsWith(PATHS.sport)}
        icon={({ hovered }: { hovered?: boolean }) => (
          <SportIcon
            fill={
              hovered
                ? "rgba(180, 49, 173, 1)"
                : "rgba(174, 194, 255, 0.3)"
            }
          />
        )}
      />

      <BottomMenuItem
        link={PATHS.lobby}
        title={landDashboardMenu.lobby}
        active={currentPath.endsWith(PATHS.lobby)}
        icon={({ hovered }: { hovered?: boolean }) => (
          <LobbyIcon
            fill={
              hovered
                ? "rgba(180, 49, 173, 1)"
                : "rgba(174, 194, 255, 0.3)"
            }
          />
        )}
      />

      <Stack width='20%' alignItems='center'>
        <Box
          sx={{
            pointerEvents: "none",
            width: 50,
            height: 50,
            aspectRatio: "1/1",
            transform: 'translateY(-50%) rotate(45deg)',
            mb: '-50px',
            border: "1px solid #D43DCC",
            backgroundColor: 'rgb(5, 9, 30)'
          }}
          className='asdadasdas'
        >
          <img
            src={MenuImage}
            height='80'
            width='80'
            style={{ transform: 'rotate(-45deg) translateY(-36px)' }} />
        </Box>
        <BottomMenuItem
          link={PATHS.home}
          title={landDashboardMenu.home}
          active={currentPath.endsWith(PATHS.home)}
          hoverTitleColor="rgba(180, 49, 173, 1)"
        />
      </Stack>

      <BottomMenuItem
        link={PATHS.legends}
        title={landDashboardMenu.legends}
        active={currentPath.endsWith(PATHS.legends)}
        icon={({ hovered }: { hovered?: boolean }) => (
          <LegendsIcon
            fill={
              hovered
                ? "rgba(180, 49, 173, 1)"
                : "rgba(174, 194, 255, 0.3)"
            }
          />
        )}
      />

      <BottomMenuItem
        title={menuLang.menu}
        icon={({ hovered }: { hovered?: boolean }) => (
          <MenuLines
            fill={
              hovered
                ? "rgba(180, 49, 173, 1)"
                : "rgba(174, 194, 255, 0.3)"
            }
          />
        )}
        onClick={requestSwitchDrawer}
      />
    </Box>
  );
};
